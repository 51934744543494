import React, { useEffect, useState } from "react";
import { Constants, MeetingProvider ,useParticipant  } from "@videosdk.live/react-sdk";
import { LeaveScreen } from "components/calling/screens/LeaveScreen";
import { JoiningScreen } from "components/calling/screens/JoiningScreen";
import { MeetingContainer } from "components/meeting/MeetingContainer";
import { MeetingAppProvider } from "contexts/meeting/MeetingAppContextDef";
import Pusher from 'pusher-js';
import {useLocation,useNavigate } from 'react-router-dom';
import {Links} from 'routes/PathLink'
import {API_ENDPOINT, toast_anim, toast_anim_error,amount_format} from 'components/config/Common'
import SessionReviewFrm from "components/reviews/SessionReviewFrm";
import ModalView from '_utils/ModalView'



//export const API_ENDPOINT = 'http://localhost:3306/VOIP/VideoSdk';
const CallNow = () => {
   //let participantNameId = Math.floor(Math.random() * 1000000);
  const navigate = useNavigate();
  const location = useLocation();

  // localStorage.setItem('token',token)
  const datainfo = location?.state;
  const [access_token, setAccess_token] = useState(localStorage.getItem('access_token'))
  const [user_id, setUser_id] = useState(localStorage.getItem('user_id'));
  const [user_name, setUserName] = useState(localStorage.getItem('user_name'));
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [meetingId, setMeetingId] = useState(datainfo?.meetingId);
  const [participantName, setParticipantName] = useState(localStorage.getItem('user_name'));
  const [micOn, setMicOn] = useState(true);
  const [webcamOn, setWebcamOn] = useState(false);

  const [selectedMic, setSelectedMic] = useState({ id: null });
  const [selectedWebcam, setSelectedWebcam] = useState({ id: null });
  const [selectWebcamDeviceId, setSelectWebcamDeviceId] = useState(
    selectedWebcam.id
  );
  const [meetingMode, setMeetingMode] = useState(Constants.modes.CONFERENCE);
  const [selectMicDeviceId, setSelectMicDeviceId] = useState(selectedMic.id);
  const [isMeetingStarted, setMeetingStarted] = useState(true);
  const [isMeetingLeft, setIsMeetingLeft] = useState(false);

  const [openwithdraw ,setOpenwithdraw] = useState(false);
  const [sessioninfo,setSessioninfo] = useState(null);

  const isMobile = window.matchMedia(
    "only screen and (max-width: 768px)"
  ).matches;

  useEffect(() => {
     

    if (isMobile) {
      window.onbeforeunload = () => {
        return "Are you sure you want to exit?";
      };
    }
  }, [isMobile]);

  // prevent back
/*useEffect(() => {
    history.pushState(null, null, location.href);
     window.onpopstate = function(event) {
      history.go(1);
    };
  }, []);
  */
  const markcompleteOnLeave = () =>{
    let values = {
            'meetingId': meetingId,
            'user_id':user_id,
           } ; // declaring array
        fetch(`${API_ENDPOINT}/mark_session_end`,{
          method: 'POST',
          headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json', 
            'Authorization': 'Bearer '+access_token, 
          },
             body: JSON.stringify(values)
          }).then(function (response) {
              return response.json();
          }).then( (result)=> {
            if(result.status == 1){
              setSessioninfo(result.data);
              openWithdrawModal(true);
            }else{
              navigate(Links.MYDASHBOARD);
            }
            
             //console.log('mail====== pushNotification',result);
          })
       setToken("");
       setMeetingId("");
       setParticipantName("");
       setWebcamOn(false);
       setMicOn(false);
       setMeetingStarted(false);
       //navigate(Links.DOCTORS);
  }


  const closeWithdraw = () =>{
    setOpenwithdraw(false);
    navigate(Links.MYDASHBOARD);
  }

  const openWithdrawModal = (data) =>{
    setOpenwithdraw(true);
    
  }

  return (
    <>
      {isMeetingStarted ? (
        <MeetingAppProvider
          selectedMic={selectedMic}
          selectedWebcam={selectedWebcam}
          initialMicOn={micOn}
          initialWebcamOn={webcamOn}
        >
          <MeetingProvider
            config={{
              meetingId,
              micEnabled: micOn,
              webcamEnabled: webcamOn,
              name: participantName ? participantName : user_name,
              mode: meetingMode,
              multiStream: true,
            }}
            token={token}
            reinitialiseMeetingOnConfigChange={false}
            joinWithoutUserInteraction={true}
          >
            <MeetingContainer
              onMeetingLeave={() => {
                console.log("MEETING LEFT");
                markcompleteOnLeave();
               
              }}
              setIsMeetingLeft={setIsMeetingLeft}
              selectedMic={selectedMic}
              selectedWebcam={selectedWebcam}
              selectWebcamDeviceId={selectWebcamDeviceId}
              setSelectWebcamDeviceId={setSelectWebcamDeviceId}
              selectMicDeviceId={selectMicDeviceId}
              setSelectMicDeviceId={setSelectMicDeviceId}
              micEnabled={micOn}
              webcamEnabled={webcamOn}
            />
          </MeetingProvider>
        </MeetingAppProvider>
      ) : isMeetingLeft ? (
        <LeaveScreen setIsMeetingLeft={setIsMeetingLeft} />
      ) : (
        null
      )}

      <ModalView open={openwithdraw} onClose={closeWithdraw}>
        <SessionReviewFrm closeWithdraw={closeWithdraw} sessioninfo={sessioninfo}  />
        </ModalView>
    </>
  );
};

export default CallNow;
