import React , {useEffect,useState} from 'react';
import { useTimer } from 'react-timer-hook';
import { Constants, useMeeting, usePubSub } from "@videosdk.live/react-sdk";
import {API_ENDPOINT, toast_anim, toast_anim_error} from 'components/config/Common'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Pusher from 'pusher-js';
import {useCallingAudio} from "contexts/callingaudio/callingaudio";


 const MyTimer = ({ expiryTimestamp , onMeetingLeft }) => {
  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({ expiryTimestamp, onExpire: () =>{
    console.warn('onExpire called') 
    LeaveBTN();
  } });

  const [access_token, setAccess_token] = useState(localStorage.getItem('access_token'))
  const [user_id, setUser_id] = useState(localStorage.getItem('user_id'));
  const [user_name, setUserName] = useState(localStorage.getItem('user_name'));
  const [user_type, setUser_type] = useState(localStorage.getItem('user_type'));
    const {callingaudio,updateCallingAudio} = useCallingAudio();


  useEffect(() => {
  const timer = setInterval(() => {
    //console.log('setTimeout called!');
    //callMessageBox();
  }, 10000);

    return () => clearInterval(timer);
  }, []);

  const callMessageBox = () =>{
    //alert(seconds);
    toast("Your session !"+seconds+" seconds remaning");
  }
  const { leave } = useMeeting();

   const LeaveBTN = () => {
     leave();
     onMeetingLeft();
     updateCallingAudio('yes');
  };


   useEffect(() => {
     var pusher = new Pusher('60a17a016b93f0924a12', {
      cluster: 'ap2'
    });
     
     var channel = pusher.subscribe('my-channel');

      channel.bind('user_calling_end', function(data) {
        console.log("RESPOSNE user_calling_end",data);
        if(data.meetingId != '' && data.expert_id == user_id && user_type == 'expert'){
          //console.log("IN IF Call DEclined");
          LeaveBTN();
          channel.unbind('user_calling_end');
           //navigate(Links.EXPERTCALLSESSION,{state:data});
          }
      }); 

      channel.bind('expert_calling_end', function(data) {
        console.log("RESPOSNE expert_calling_end",data);
        if(data.meetingId != '' && data.user_id == user_id && user_type == 'user'){
          //console.log("IN IF Call DEclined");
          LeaveBTN();
          channel.unbind('expert_calling_end');
           //navigate(Links.EXPERTCALLSESSION,{state:data});
          }
      }); 
    },[user_id]);

  return (
    <div style={{textAlign: 'center'}}>
      <h1 className={` text-lg font-semibold ${minutes >= 5 ? ' text-white ' : ' animate-bounce text-red-600' }`}>Call Time remaining</h1>
      <div className={`text-lg font-semibold ${minutes >= 5 ? ' text-white ' : ' animate-bounce text-red-600' }`}>
        <span>{days}</span>:<span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
      </div>
      <ToastContainer />
    </div>
  );
}
export default MyTimer;