import React, { createContext, useContext, useState, useEffect ,useMemo } from "react";
import {API_ENDPOINT} from 'components/config/Common'

const CallingAudioContext = createContext('CallingAudioContext');

export const CallingAudioProvider = (props) => {

   const [callingaudio, setCallingaudio] = useState('no')
   

   useEffect(() => {
      
   },[])

   const updateCallingAudio = (audio) => {
      setCallingaudio(audio)
  }

   const value = useMemo(
    () => ({
      callingaudio,
      updateCallingAudio,
    }),
    [callingaudio]
  );

   return <CallingAudioContext.Provider value={value} {...props} />

}

export const useCallingAudio = () => {
   const context = React.useContext(CallingAudioContext);
   if (context === undefined) {
      throw new Error(`useSiteSettings must be used within a SiteSettingContext`);
   }
   return React.useMemo(() => context, [context]);
   //return context;
};