import React from "react";
import { motion } from 'framer-motion';
import {NavbarIcon, UserIcon, HomeIcon, ShoppingBagIcon, CloseIcon} from '../components/custom-icons'
import {useAuth} from '../contexts/provider/AuthProvider'
import { Drawer } from "flowbite";
import MobileMenuDrawer from './mobile-menu-drawer'
import Logo from './logo';
import { useNavigate } from "react-router-dom";
import {Links} from "../routes/PathLink"
import {API_ENDPOINT} from '../components/config/Common'
import Collapse from 'rc-collapse';
import 'rc-collapse/assets/index.css';
import { BiDownArrow } from "react-icons/bi";
import classNames from 'classnames';

const MobileNav = (props) => {
   const { isUser } = useAuth();
   const [category, setCategory] = React.useState(null)
   const [isActive, setIsActive] = React.useState(null)
   const [user_type,setUser_type] =React.useState(localStorage.getItem('user_type'));

   const navigate = useNavigate();

   React.useEffect(() => {      
      //getAllCategory()
   },[])

  /* const getAllCategory = () => {
      fetch(`${API_ENDPOINT}/get_all_category`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			console.log('side bar categories======',result)
			setCategory(result.data)
			
		})
   }
   */
   const openAuthMenu = () => {
      //console.log('login------')
      const options = {
         placement: 'right',
         backdrop: true,
         bodyScrolling: false,
         edge: false,
         edgeOffset: '',
         backdropClasses: 'cart-drawer bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-30',
         onHide: () => {
             //console.log('drawer is hidden');
             //setDrawerVisible(false)
         },
         onShow: () => {
             //console.log('drawer is shown');
             //setDrawerVisible(true)
         },
         onToggle: () => {
             console.log('drawer has been toggled');
         }
       };
     const $targetEl = document.getElementById('mobile-menu-drawer');
     const drawer: DrawerInterface = new Drawer($targetEl, options);
     drawer.show();
   }

   const openLeftMenu = () => {
      const options = {
         placement: 'left',
         backdrop: true,
         bodyScrolling: false,
         edge: false,
         edgeOffset: '',
         backdropClasses: 'cart-drawer bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-30',
       };
     const $targetEl = document.getElementById('mobile-menu-left-drawer');
     const drawer: DrawerInterface = new Drawer($targetEl, options);
     drawer.show();
   }

   const closeLeftDrawer = () => {
      //console.log('close drawer')
      const options1 = {
          placement: 'left',
          backdrop: false,
          bodyScrolling: true,
          edge: false,
          edgeOffset: '',
          backdropClasses: 'bg-gray-900 bg-opacity-0 dark:bg-opacity-0 fixed',
        };
      const $targetEl = document.getElementById('mobile-menu-left-drawer');
      const drawer: DrawerInterface = new Drawer($targetEl, options1);
      drawer.hide();
      let cartDrawer = document.querySelector(".cart-drawer");
      let body = document.querySelector("body");
      cartDrawer.remove();
      body.classList.remove("overflow-hidden");
   }

   const closeRightMenuDrawer = () => {
      //console.log('close drawer')
      const options1 = {
          placement: 'right',
          backdrop: false,
          bodyScrolling: true,
          edge: false,
          edgeOffset: '',
          backdropClasses: 'bg-gray-900 bg-opacity-0 dark:bg-opacity-0 fixed',
        };
      const $targetEl = document.getElementById('mobile-menu-drawer');
      const drawer: DrawerInterface = new Drawer($targetEl, options1);
      drawer.hide();
      let cartDrawer = document.querySelector(".cart-drawer");
      let body = document.querySelector("body");
      cartDrawer.remove();
      body.classList.remove("overflow-hidden");
   }

   const go_to_link = (link) => {
      //console.log(link)
      let cartDrawer = document.querySelector(".cart-drawer");
      let body = document.querySelector("body");
      cartDrawer.remove();
      body.classList.remove("overflow-hidden");
      navigate(link);
   }

   const getActiveKey = (key) => {
      //console.log('getActiveKey======',key[0])
      if(key[0]){
         setIsActive(true)
      }else{
         setIsActive(false)
      }
   }

   return (
      <>
      <div className="visible lg:hidden">
          <div className={`sticky top-0 z-30 flex h-14 w-full transform-gpu items-center justify-between border-b border-border-200 bg-light px-4 py-5 shadow-sm transition-transform duration-300 md:h-16 lg:h-22 lg:px-8 dark:bg-gray-800 dark:border-gray-600`}>
            <div
            className="cursor-pointer flex h-full items-center justify-center p-2 focus:text-accent focus:outline-none"
            onClick={openLeftMenu}>
               <NavbarIcon  />
            </div>
           
               <a className="inline-flex " href="/">
               <span className="relative h-10 ">
                  <Logo classNametext="text-2xl text-accent" />
               </span>
               </a>
            
            {
            isUser 
            ?
            <div
              
               className="cursor-pointer flex h-full items-center justify-center p-2 focus:text-accent focus:outline-none"
               onClick={openAuthMenu}
            >
               <UserIcon />
            </div>
            :
            <div
               className="cursor-pointer flex h-full items-center justify-center p-2 focus:text-accent focus:outline-none"
               onClick={()=>{navigate(Links.USER_LOGIN)}}
            >
               <UserIcon />
            </div>
            }
         </div>   
      </div>
      <MobileMenuDrawer closeRightMenuDrawer={closeRightMenuDrawer} />

      <div id="mobile-menu-left-drawer" className="fixed top-0 left-0 z-40 h-screen overflow-y-auto transition-transform -translate-x-full bg-white w-80 dark:bg-gray-800" tabIndex="-1" aria-labelledby="drawer-right-label">
         
         <div className="fixed top-0 z-10 w-80 max-w-md flex items-center justify-between border-b border-border-200 border-opacity-75 bg-light py-4 px-6">
            <a className="inline-flex " href="/">
               <span className="relative h-10 ">
                  <Logo classNametext="text-2xl text-accent" />
               </span>
            </a>
            <button className="flex h-7 w-7 items-center justify-center rounded-full bg-gray-100 text-muted transition-all duration-200 hover:bg-accent hover:text-light focus:bg-accent focus:text-light focus:outline-none ltr:ml-3 ltr:-mr-2 rtl:mr-3 rtl:-ml-2" onClick={closeLeftDrawer}>
               <span className="sr-only"></span>
               <CloseIcon className="h-3 w-3" />
            </button>
         </div>
         
         <div className="pt-20">
            <ul className="flex-grow">
               {/*<li>
                  <Collapse
                     accordion={true}
                     defaultActiveKey={null}
                     expandIcon={() => null}
                     className="p-5 bg-light"
                     openMotion={motion}
                     onChange={getActiveKey}
                  >
                     <Collapse.Panel
                        key={`shop-menu`}
                        header={
                        <button className="block cursor-pointer text-sm font-semibold capitalize text-heading transition duration-200 hover:text-accent md:px-8 flex items-center justify-between w-full">
                           <span>Category</span>
                           <span className={classNames("ltr:ml-auto ltr:mr-4 rtl:ml-4 rtl:mr-auto transition-all",{
                              'text-accent rotate-180':isActive
                           })}>
                              <BiDownArrow />
                           </span>
                        </button>
                        }
                     >
                        <ul>
                        {
                           category?.map((val, key) => (
                           <li>
                              <button className="block cursor-pointer px-5 py-3 text-sm font-semibold capitalize text-heading transition duration-200 hover:text-accent md:px-8" onClick={() => go_to_link(`${Links.CATEGORY}/${val.slug}`)}>{val.cat_name}</button>
                           </li>
                        ))

                        }
                        </ul>
                     </Collapse.Panel>
                  </Collapse>
               </li>*/}
               
               {!localStorage.getItem('access_token') && 
                  <li>
                     <button className="block cursor-pointer px-5 py-3 text-sm font-semibold capitalize text-heading transition duration-200 hover:text-accent md:px-8" onClick={() => go_to_link(Links.USER_LOGIN)}>login</button>
                  </li>
               }
               {!localStorage.getItem('access_token') && 
                  <li>
                     <button className="block cursor-pointer px-5 py-3 text-sm font-semibold capitalize text-heading transition duration-200 hover:text-accent md:px-8" onClick={() => go_to_link(Links.BECOMEANEXPERT)}>Become An Expert</button>
                  </li>
               }
               {user_type == 'user' &&
                   <li>
                     <button className="block cursor-pointer px-5 py-3 text-sm font-semibold capitalize text-heading transition duration-200 hover:text-accent md:px-8" onClick={() => go_to_link(Links.EXPERTS)}>Find an Expert</button>
                  </li>
               }
               <li>
                  <button className="block cursor-pointer px-5 py-3 text-sm font-semibold capitalize text-heading transition duration-200 hover:text-accent md:px-8" onClick={() => go_to_link(Links.HOWITWORK)}>How It Works</button>
               </li>
               <li>
                  <button className="block cursor-pointer px-5 py-3 text-sm font-semibold capitalize text-heading transition duration-200 hover:text-accent md:px-8" onClick={() => go_to_link(Links.CONTACT)}>Contact</button>
               </li>
            </ul>
         </div>
      </div>
      </>
   )
}

export default MobileNav;