import React, {useState, useEffect, useRef} from "react";

import { Link , useParams, useNavigate } from "react-router-dom";

import { BiArrowBack } from "react-icons/bi";

import {
	API_ENDPOINT, 
	BACKEND_URL,
	replaceWithBr, 
	toast_anim, 
	toast_anim_error, 
	APP_URL, 
	APP_NAME,
	IMG_URL, 
	amount_format,
	discount,
	machine_condition,
	showRating,
	amount_with_current
} from 'components/config/Common'
import classNames from 'classnames';
import Header from "layouts/Header";
import Footer from "layouts/Footer";

import { Popover , Transition} from '@headlessui/react'


// import Swiper core and required modules
import { Navigation, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/thumbs';

import {HeartOutlineIcon, HeartFillIcon, ChevronLeft, ChevronRight, Loading} from "components/custom-icons";

import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'

import ScrollPageTop from 'components/ui/ScrollPageTop'
import {Links} from 'routes/PathLink'
import {useAuth} from 'contexts/provider/AuthProvider'
import Button from 'components/ui/Button.tsx'
import Seo from 'components/seo/seo'
import Badge from "components/ui/badge/badge";
import RelatedDoctors from "./RelatedDoctors";
import CallNowBtn from "components/calling/buttons/CallNowBtn";
import ScheduleAppointmentBtn from "components/appointment/buttons/ScheduleAppointmentBtn";
import * as moment from 'moment';
import ExpertWishlist from 'components/experts/ExpertWishlist';
import ProductRatings from 'components/ui/ProductRatings';

const DoctorDetails = () => {	
	const navigate = useNavigate();
	const [productsImg, setProductsImg] = useState([])	
	const [thumbsSwiper, setThumbsSwiper] = useState(null);
	const [openrefund , setOpenrefund] = useState(false);
	const [doctor, setDoctor] = useState(null)	

	const prevRef = useRef(null);
	const nextRef = useRef(null);
	const {slug} = useParams()
	const { isUser, loginToken } = useAuth();
	
	const [metaTitle, setMetaTitle] = useState('')
	const [metaDesc, setMetaDesc] = useState('')
	const [user_type, setUser_type] = useState('')

	const outOfStock = false

	useEffect(() => {
		getDoctorDetail(slug)
		
		setTimeout(() => {
			window.scrollTo({
			  top: 0,
			  left: 0,
			  behavior: "smooth"
			});
		 }, 10);
	},[slug, loginToken])

	useEffect(() => {
		const user_type = localStorage.getItem('user_type');
      setUser_type(user_type);

	},[])

	
	const getDoctorDetail = (slug) => {
		//console.log(user_id)

		fetch(`${API_ENDPOINT}/get_experts_slug_front/${slug}`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': `Bearer ${loginToken}`, 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			console.log('products======',result.data)
			if(result.status === 1){
				setDoctor(result.data)
				setMetaTitle(result.data.name ? result.data.name : result.data.name)
				setMetaDesc(result.data.intro ? result.data.intro : result.data.intro)
			}else{
				//navigate(Links.HOME);
			}
		})
	}


	 const TagsListsp = (list) =>{
      //console.log("TagsList FUNCTION ",list);
      if(list && list.length > 0){
          return( <>{list.map((val)=>(
                  <div className="px-1 "><Badge text={val.label} color="" className="px-1 py-1" /></div>
               ))
               }  
            </>
         );
      }
   }


   const TagsListLan = (list) =>{
      //console.log("TagsList FUNCTION ",list);
      if(list && list.length > 0){
          return( <>{list.map((val)=>(
                  <div className="px-1 "><Badge text={val.lan} color="bg-gray-500 " className="px-1 py-1" /></div>
               ))
               }  
            </>
         );
      }
   }


	/* 
	* call this fun from Header.js
	* when user login show wishlist icon
	*/
	const rerenderParentCallback = () => {		
		getDoctorDetail(slug)		
	}

	return (
		<>
		<Seo 
         title={`${metaTitle} - ${APP_NAME}`}
         desc={metaDesc && metaDesc.substring(0, 200)}
         url={`${APP_URL}${Links.PRODUCT}/${slug}`}
         image={productsImg && `${IMG_URL}${productsImg[0]}`}
      />
		<Header rerenderParentCallback={rerenderParentCallback} />
		<section className="pt-10 bg-blueGray-50">
			<div className="w-full lg:w-full px-4 mx-auto">
				<Link to={Links.EXPERTS} className="pb-4"><BiArrowBack size={40} /></Link>
	  		<div className=" flex divide-x-2 flex-col  break-words bg-white w-full mb-6 px-4 py-4 shadow-xl rounded-lg ">
	  			 <div className="w-full flex flex-col md:flex-row">
		  			 <div className="w-full md:w-3/5">
		  			 		<div className="flex flex-col md:flex-row">
		  			 		 <div className="w-full md:w-1/4	flex flex-col ml-4 px-2 py-2">
				    		  <div className="h-40 overflow-hidden shadow rounded-full w-40 h-auto align-middle border-none flex-wrap flex">
				          	{doctor?.profile_image ?
				            <img alt="..." src={`${BACKEND_URL}${doctor?.profile_image}`} className="shadow-xl rounded-full h-auto align-middle border-none  max-w-150-px" />
				            :
				            <img src={`${APP_URL}/img/Doctor-placeholder-female.jpeg`} className="shadow-xl rounded-full h-auto align-middle border-none max-w-150-px" />
				         		}
				          </div>
				          <div className="mt-2 flex items-center justify-start md:justify-center">
                    {doctor?.availability_status == 'online'?
                    (<span class="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400">Online</span>
)                                   :null

                    }
                    {doctor?.availability_status == 'offline'?
                    (<span class="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400">Offline</span>
)                                   :null

                    }
                    {doctor?.availability_status == 'busy'?
                    (<span class="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400">Busy</span>
)                                   :null

                    }
                    {doctor?.availability_status == 'on another call'?
                    (<span class="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400">On Another Call</span>
)                                   :null

                    }
                    </div>
                    <div className="capitalize text-sm mt-2">{doctor?.short_intro}</div>

				           </div>
			          <div className=" w-full md:w-3/4	flex flex-col ml-4">
			          	 <h3 className="mt-4 text-xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2 capitalize">
					          {doctor?.name}
					        </h3>
					        <div className="flex">{showRating(doctor?.rating)}
					        {user_type == 'user' && doctor ?
                        <ExpertWishlist item={doctor}  />
                      :
                      <></>
                    }
					        </div>
					          {doctor?.session_price ?
                       <div className="flex  mt-2 text-sm flex-wrap  "><span>Starts @ </span> {amount_with_current(doctor?.session_price)}&nbsp; for 30 mins</div>
                       :
                       null
                    }
					        
					          <p className="mb-2 whitespace-pre-wrap text-md mt-4 leading-relaxed text-blueGray-700">
            					{doctor?.intro}
            				</p>
            				 {doctor?.next_available_date ?
	                    <div className="flex  mt-2 text-sm mb-2  "><span className="font-semibold">Next Available Appointment: </span>&nbsp; {moment(doctor.next_available_date).format('DD MMM , YYYY hh:mm A')} </div>
	                    :
	                    null
	                  }
			           	<div>
				           	{doctor?.availability_status != 'online'?
		                   <ScheduleAppointmentBtn info={doctor} />
		                :
		                    <div className="flex">
		                    	<div className="mt-2"><CallNowBtn  info={doctor} /></div>
                         	<div className="ml-2 mt-2"><ScheduleAppointmentBtn info={doctor}  /></div>
                         </div>
		                }
		                </div>
	                </div>
	              </div>
		          </div>
		          <div className="w-full md:w-2/5	ml-4">
		          	 <div className="mt-4 flex flex-wrap  align-middle"><span className="uppercase text-gray-500">Total Conversations</span>: 4843</div>
		          	 <div className="mt-4 flex flex-wrap  align-middle">{TagsListsp(doctor?.specalizationdt)}</div>
	           		 <div className="mt-4 flex flex-wrap  align-middle">{TagsListLan(doctor?.languagesdt)}</div>
	           		 
		         	</div>
		        </div>
	    	</div>

	    	<div className=" flex flex-col md:flex-row w-full">
		    	<div className="w-full md:w-5/6 mr-8">
		    		<div className="flex divide-x-2 flex-col  break-words bg-white w-full mb-6 px-4 py-4 shadow-xl rounded">
		    		<h3 className="mt-4 text-xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2 capitalize">
					     Experience
					  </h3>
					  <p>{doctor?.experience}</p>
		    		</div>
		    		<div className="flex divide-x-2 flex-col  break-words bg-white w-full mb-6 px-4 py-4 shadow-xl rounded">
		    		<h3 className="mt-4 text-xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2 capitalize">
					     Education
					  </h3>
					  <p>{doctor?.qualification}</p>
		    		</div>

		    		<div className="flex divide-y-2 flex-col  break-words bg-white w-full mb-6 px-4 py-4 shadow-xl rounded">
		    		<h3 className="mt-4 text-xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2 capitalize">
					     Reviews
					  </h3>
					  <p><ProductRatings slug={slug} /></p>
		    		</div>

		    	</div>
		    	<div className="w-full md:w-2/6	flex justify-end">
				    	{doctor?.id > 0 && 
							<RelatedDoctors 
								slug={doctor?.slug} 
								specilization={doctor}	
							/>
						}
		    	</div>
	    	</div>
	    </div>
		</section>
		{/*
			
*/}
		<Footer />
		</>
		);
	
};

export default DoctorDetails