import React, {useState, useEffect,useRef} from "react";
import { Link } from "react-router-dom";

import LeftMenuLayout from 'layouts/LeftMenuLayout';

import classNames from 'classnames';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {API_ENDPOINT, toast_anim , pdfdoconly , languageOptions , specalizationOptions,amount_format} from 'components/config/Common'
import {Loading} from "components/custom-icons";
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";

import axios from 'axios';  

import {APP_URL, APP_NAME , IMG_URL} from 'components/config/Common'
import FileInput from 'components/ui/file-input.jsx';

import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import { toast } from 'react-toastify';
import ScrollPageTop from 'components/ui/ScrollPageTop'

import { yupResolver } from '@hookform/resolvers/yup';
import Input from 'components/ui/input.jsx'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import SelectInput from 'components/ui/select-input.jsx'
import Label from 'components/ui/label.tsx'
import Button from 'components/ui/Button.tsx'
import { Switch } from '@headlessui/react'
import TextArea from 'components/ui/text-area';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Tab } from '@headlessui/react'
import { BsPencilFill } from "react-icons/bs";
import SetSessionCharges from 'components/experts/SetSessionCharges';
import ModalView from '_utils/ModalView'
import ImageProfileDisplay from "components/ui/imageprofiledisplay";


import {
	Control,
	FieldErrors,
	useForm,
	useFormState,
	useWatch,
	Controller
 } from 'react-hook-form';

const SignupSchema = Yup.object().shape({
	name: Yup.string()
		.required('You must need to provide name.')
		.min(2, 'Name is too short!')
		.max(50, 'Name is too long!'),
	email: Yup.string()
		.required('You must need to provide email address.')
		.email('Invalid email.'),		
});

const SocialSchema = Yup.object().shape({
	facebook: Yup.string()
		.url('Invalid url')
		//.matches('/facebook/', 'Provide valid facebook profile url')
		.nullable(),
	twitter: Yup.string()
		.url('Invalid url')
		.nullable(),
	instagram: Yup.string()
		.url('Invalid url')
		.nullable(),
});

let defaultValues = {  
	name: '',
	email:'',
	phone_no:'',
	password:'',
};

const ProfileDoctor = () => {
	
	const [access_token, setAccess_token] = useState(localStorage.getItem('access_token'))
	const [user_type, setUser_type] = useState(localStorage.getItem('user_type'))
	const [user_me, setUser_me] = useState(null);
	const [loading, setLoading] = useState(false);
	const [loading_social, setLoading_social] = useState(false);
	const [states, setStates] = useState(null);
   const [cities, setCities] = useState(false);
   const [enabled, setEnabled] = useState(false);
   const [languages, setLanguages] = useState([]);
   const [opensessionchange ,setOpensessionchange] = useState(false);

	const { register, handleSubmit , control, reset, setValue, setError, formState: { errors },} = useForm({
		defaultValues: defaultValues,
  	});

  	const [startDate, setStartDate] = useState(new Date());

   let handleColor = (time) => {
    return time.getHours() > 12 ? "text-success" : "text-error";
   };


	const refphone = useRef(null);

	useEffect(() => {
		getMyProfile()
		getLanguages();
	},[])
	

  const handleSelect = (val,name) => {  
		console.log('cat val======',val,name.name)
		setError(name.name, null)
		setValue(name.name, val, true);
		
	}

	

	const closeSessionch = () =>{
		setOpensessionchange(false);
		getMyProfile();
	}

	const openSessionch = () =>{
		setOpensessionchange(true);
	}
	
	const getLanguages =() => {
		axios.get(`${API_ENDPOINT}/languages_list`,{
         headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer '+access_token, 
         }
      }).then( (result)=> {
         console.log('data=====',result.data)
         if(result.data?.data){
         	setLanguages(result.data?.data);
         }
      })
	}


	const getMyProfile = () => {
		console.log(access_token)
		fetch(`${API_ENDPOINT}/expert_me`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': 'Bearer '+access_token, 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			console.log(result)
			setUser_me(result.data)
			let data = result.data

			defaultValues = {  
				name: data.name,
				email:data.email,
				intro:data.intro,
				short_intro:data?.short_intro,
				phone_no:data.phone_no,
				session_price:data.session_price,
				session_price45:data.session_price45,
				session_price60:data.session_price60,
				avaliable_hour:data.avaliable_hour,
				experience:data.experience,
				education:data.qualification,
				gender:data.gender,
			
			};
			reset(defaultValues)
			//console.log("specializationdt",data?.specalizationdt);
			if(data?.specalizationdt){
				setValue('specialisation',data?.specalizationdt);
			}else{
				setValue('specialisation',[]);
			}
			if(data?.othspecalizationdt){
				setValue('other_specialisation',data?.othspecalizationdt);
			}else{
				setValue('other_specialisation',[]);
			}

			if(data?.languagesdt){
				setValue('language',data?.languagesdt);
			}else{
				setValue('language',[]);
			}
		})
	}	

	const siteMetadata = {
		url: `${APP_URL}/profile`,
		title:  `Profile - ${APP_NAME}`,
		description: `Profile ${APP_NAME}`,
	}

	const onSubmit = (values) => {
		console.log('value', values)
		setLoading(true)		
		let input = values;
      	setLoading(true)
      axios.post(`${API_ENDPOINT}/update_expert_profile`,input,{
         headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer '+access_token, 
         }
      }).then( (result)=> {
         console.log('data=====',result.data)
         setLoading(false)
         console.log('data=====',result.data.status)
			if(result.data.status === 1){
				//alert("IN IF");
				//localStorage.setItem('user_name',result.data.data.name);
				toast_anim(result.data.message)
			}
      })
	}

	return (
		<ScrollPageTop>
			<ReactSEOMetaTags
				render={(el: React.ReactNode) => <Helmet>{el}</Helmet>}
				website={{ ...siteMetadata }}
				breadcrumb={[
					{ title: 'Home', url: `${APP_URL}` },
					{ title: 'Profile', url: `${APP_URL}/profile` },
				]}
			/>
         <LeftMenuLayout>
				<div className="w-full">
					<div className={classNames('w-full p-5 md:p-8 bg-light dark:bg-gray-700 shadow rounded mb-8')}>
						<p className="text-lg lg:text-xl text-heading capitalize mb-5 dark:text-zinc-400">Profile</p>
						<div className=" flex w-full  px-2 py-2 ">

							<Tab.Group vertical >
					      <Tab.List className="flex  flex-col justify-start space-x-1 divide-y p-1">
					        <Tab className="text-left px-2 py-3">Profile</Tab>
					        <Tab className="text-left px-2 py-3">Experties</Tab>
					        <Tab className="text-left px-2 py-3">Communication Language </Tab>
					        <Tab className="text-left px-2 py-3">Session</Tab>
					      </Tab.List>
					      <Tab.Panels className="border-l-2 px-4 flex w-full">
					        <Tab.Panel className="flex w-full flex-col">
					        	<div className="flex w-full justify-center mb-2">
					        			<ImageProfileDisplay /> 
					        		</div>
					        	<div className="flex w-full">
					        	
						        	<div className="flex w-full">
										<Input                
			                            label={`Name`}
			                            {...register('name')}
			                            variant="outline"
			                            className="mb-5 w-full mr-4"
			                            error={errors.name?.message}
			                            required
			                        />
		                        </div>
		                        <div className="flex w-full align-center">
										<Input                
				                            label={`Email`}
				                            {...register('email')}
				                            variant="outline"
				                            className="mb-5 w-full"
				                            type="email"
				                            error={errors.email?.message}
				                           disabled={true}
				                        />
										</div>
									</div>
									<div className="flex w-full">
										<div className="mb-5 w-1/2 flex flex-col mr-4">
				                     <label htmlFor="phone_no" className="block mb-3 text-sm font-semibold leading-none text-body-dark">Phone number </label>
				                     <Controller
				                        name="phone_no"
				                        control={control}
				                        ref={refphone}
				                        disabled={true}
				                        render={({ field }) => (
				                           <PhoneInput
				                              country="in"
				                              disableDropdown
				                              countryCodeEditable={false}
				                              inputClass="!p-0 ltr:!pr-4 rtl:!pl-4 ltr:!pl-14 rtl:!pr-14 !flex !items-center !w-full !appearance-none !transition !duration-300 !ease-in-out !text-heading !text-sm focus:!outline-none focus:!ring-0 !border !border-border-base !rounded focus:!border-accent !h-12"
				                              dropdownClass="focus:!ring-0 !border !border-border-base !shadow-350"
				                              {...field}
				                           />
				                        )}
				                     />
				                     <p className="my-2 text-xs text-start text-red-500">{errors.phone_no?.message}</p>
			                  	</div>
				                  <div className="mb-5 w-1/2 ">
					                  <label for="name" class="mb-3 block text-sm font-semibold leading-none text-body-dark dark:text-zinc-400">Gender<span class="text-red-500"> *</span></label>
					                  <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
					                      <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
					                          <div class="flex items-center pl-3">
					                              <input id="horizontal-list-radio-license"   {...register('gender')}  type="radio" value="male" name="gender" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"  />
					                              <label for="horizontal-list-radio-license" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Male </label>
					                          </div>
					                      </li>
					                      <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
					                          <div class="flex items-center pl-3">
					                              <input id="horizontal-list-radio-id" type="radio" {...register('gender')}  value="female" name="gender" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
					                              <label for="horizontal-list-radio-id" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Female</label>
					                          </div>
					                      </li>
					                      <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
					                          <div class="flex items-center pl-3">
					                              <input id="horizontal-list-radio-millitary" type="radio" {...register('gender')}  value="non-binary" name="gender" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
					                              <label for="horizontal-list-radio-millitary" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Non Binary</label>
					                          </div>
					                      </li>
					                  </ul>
					                  </div>
					                </div>
					              <div className="flex w-full">
										<Input                
			                            label={`Short Bio`}
			                            {...register('short_intro')}
			                            variant="outline"
			                            className="mb-5 w-full mr-4"
			                            error={errors.short_intro?.message}
			                            required
			                        />
		                        </div>
			                  <div className="mb-5 w-full">
			                  	<Label>About Me<span className="text-red-500"> </span></Label>
		                      <TextArea
			                   name="intro"
			                   placeholder="Tell us about yourself"
			                   {...register('intro')}
			                    />
			                  </div>
			                  <div className="flex w-full">
			                   <div className="mb-5 w-1/2 mr-4">
			                   		<Label>Experience <span className="text-red-500"> </span></Label>

			                      <TextArea
				                   name="experience"
				                   placeholder="Tell us about Experience"
				                    {...register('experience')}
				                    />
				                  </div>
				                  <div className="mb-5 w-1/2">
			                   		<Label>Education <span className="text-red-500"> </span></Label>

			                      <TextArea
				                   name="education"
				                   placeholder="Tell us about Education"
				                   {...register('education')}
				                    />
				                  </div>
				               </div>
					        </Tab.Panel>
					        <Tab.Panel>
					        <div className="flex w-full flex-col">
					        	 <div className="mb-5 mr-4 w-full">
		                        <Label>I am Expert in  <span className="text-red-500"> </span></Label>
		                        <SelectInput
		                           name="specialisation"                            
		                           control={control}
		                           getOptionLabel={(option: any) => option.label}
		                           getOptionValue={(option: any) => option.id}                        
		                           options={specalizationOptions}
		                           onChange={handleSelect}   
		                           isMulti                        
		                        />
		                        <p className="my-2 text-xs text-start text-red-500">{errors.specialisation?.message}</p>
		                     </div>
		                 
		                   <div className="mb-5 w-full">
		                 	 <Label>Other Experties <span className="text-red-500"> </span></Label>
		                        <SelectInput
		                           name="other_specialisation"                            
		                           control={control}
		                           getOptionLabel={(option: any) => option.label}
		                           getOptionValue={(option: any) => option.id}                        
		                           options={specalizationOptions}
		                           onChange={handleSelect}   
		                           isMulti                        
		                        />
		                     <p className="my-2 text-xs text-start text-red-500">{errors.other_specialisation?.message}</p>
		                   </div>
		                  </div>
					        </Tab.Panel>
					        <Tab.Panel className="flex w-full">
			                   <div className="mb-5 w-full">
			                     <Label>Communication Language <span className="text-red-500"> </span></Label>
			                           <SelectInput
			                              name="language"                            
			                              control={control}
			                              getOptionLabel={(option: any) => option.lan}
			                              getOptionValue={(option: any) => option.id}                        
			                              options={languages}
			                              onChange={handleSelect}   
			                              isMulti                        
			                           />
			                   </div></Tab.Panel>
					      
					      {/*<Tab.Panel className="flex w-full flex-col">
					       	<div className="mb-5 w-full">
									<Controller
								    control={control}
								    name='next_available_date'
								    render={({ field }) => (
										<DatePicker
								      showTimeSelect
								      selected={startDate}
								      onChange={(date) => {field.onChange(date);
								      setStartDate(date); }}
								      timeClassName={handleColor}
								      minDate={(new Date())- 1}
								      dateFormat="yyyy/MM/dd hh:mm a"
								    />
								     )}
  									/>
								    </div>

								    <div className="mb-5 w-1/2">
			                  <label for="name" class="mb-3 block text-sm font-semibold leading-none text-body-dark dark:text-zinc-400">Avaliablity Status <span class="text-red-500"> *</span></label>
			                  <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
			                      <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
			                          <div class="flex items-center pl-3">
			                              <input id="horizontal-list-radio-license" type="radio" value="offline" {...register('avaliablity_status')} name="avaliablity_status" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
			                              <label for="horizontal-list-radio-license" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Offline </label>
			                          </div>
			                      </li>
			                      <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
			                          <div class="flex items-center pl-3">
			                              <input id="horizontal-list-radio-id" type="radio" value="online" name="avaliablity_status" {...register('avaliablity_status')} class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
			                              <label for="horizontal-list-radio-id" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Online</label>
			                          </div>
			                      </li>
			                      <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
			                          <div class="flex items-center pl-3">
			                              <input id="horizontal-list-radio-millitary" type="radio" value="busy" name="avaliablity_status" {...register('avaliablity_status')} class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
			                              <label for="horizontal-list-radio-millitary" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Busy</label>
			                          </div>
			                      </li>
			                      <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
			                          <div class="flex items-center pl-3">
			                              <input id="horizontal-list-radio-millitary" type="radio" value="busy" name="avaliablity_status" {...register('avaliablity_status')} class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
			                              <label for="horizontal-list-radio-millitary" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">On Another call</label>
			                          </div>
			                      </li>
			                  </ul>
			                  </div>
					      
					      </Tab.Panel>
					      */}
					       <Tab.Panel className="flex w-full flex-col">
					       		<><div className="mb-5 w-1/2">
					       			<div className="flex items-center">
					       				<div><h1 className="text-2xl leading-10	">Session Charges</h1></div>
					       				<div className="ml-4" >
					       				<Link to="#" onClick={()=>openSessionch()} >
					       					<BsPencilFill className="w-6 h-6 text-accent"/>
					       				</Link>
					       				</div>
					       			</div>
					       			{defaultValues?.session_price &&  defaultValues?.session_price > 0 ?
					       			<div className="flex mt-2 mb-2">30 min: {amount_format(defaultValues?.session_price)}</div>
					       			:
					       			null
					       			}

					       			{defaultValues?.session_price45 && defaultValues?.session_price45 > 0 ?
					       				<div className="flex mb-2">45 min: {amount_format(defaultValues?.session_price45)}</div>
					       				:
					       				null
					       			}
					       			{defaultValues?.session_price60 &&  defaultValues?.session_price60 > 0 ?
					       				<div className="flex mb-2">60 min: {amount_format(defaultValues?.session_price60)}</div>
					       				:
					       				null
					       			}
					       		</div>
					       		{/*<div className="mb-5 w-1/2">
		                        <Label>Price (for 30 minute for a single session) <span className="text-red-500"> </span></Label>
		                        <Input
		                           name="session_price"                            
		                           control={control}   
		                           {...register('session_price')}      
		                            step="0.01"
		                            type="number"  
		                        />
		                        <p className="my-2 text-xs text-start text-red-500">{errors.accessories?.message}</p>
		                     </div>*/}
		                     </>
					      </Tab.Panel>
					    </Tab.Panels>
					    </Tab.Group>
					    </div>
						{
						user_me &&	
						<form onSubmit={handleSubmit(onSubmit)}>
		                       {/*<div className="mb-5 w-1/2">
		                        <Label>Avaliablity Per Day in (hour)  <span className="text-red-500"> </span></Label>
		                        <Input
		                           name="session-price"                            
		                           control={control}   
		                           {...register('avaliable_hour')}      
		                            step="1"
		                            type="number"  
		                        />
		                        <p className="my-2 text-xs text-start text-red-500">{errors.accessories?.message}</p>
		                     </div>*/}
							<div className="flex">
								{
								!loading
								?	
								<button data-variant="normal" className="inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-5 py-0 h-12 ltr:ml-auto rtl:mr-auto">Save</button>
								:
								<Loading className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 ltr:ml-auto" />
								}
							</div>
							</form>
						}
					</div>			
				</div>
			</LeftMenuLayout>
			<ModalView open={opensessionchange} onClose={closeSessionch} >
				<SetSessionCharges closeSessionch={closeSessionch} />
			</ModalView>
      </ScrollPageTop>
   );
};

export default ProfileDoctor