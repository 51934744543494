import React, {useState, useEffect ,useRef } from "react";
import ScrollPageTop from 'components/ui/ScrollPageTop'
import { Link, useNavigate } from "react-router-dom";
import { DoctorRegProvider } from "contexts/doctorregistration/doctor-reg";
import { Tab } from '@headlessui/react'

import * as Yup from 'yup';
import {
   Control,
   FieldErrors,
   useForm,
   useFormState,
   useWatch,
   Controller
 } from 'react-hook-form';
 
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';  
import {Links} from 'routes/PathLink'
import {
   API_ENDPOINT,
   APP_URL, 
   random_str, 
   toast_anim_error, 
   RAZOR_KEY, 
   loadScript,
   register_valid,
   register_fee,
   renew_per,
   pdfdoconly,
} from 'components/config/Common'
import Input from 'components/ui/input.jsx'

import 'react-phone-input-2/lib/bootstrap.css'
import Button from 'components/ui/Button.tsx'
import {useAuth} from 'contexts/provider/AuthProvider'
import Label from 'components/ui/label.tsx'
import {deviceDetect} from "react-device-detect";
//import MultiStep from 'react-multistep'
import PatientRegisterMain from "components/auth/user/PatientRegisterMain";
import PatientRegisterBasic from "components/auth/user/PatientRegisterBasic";
import Logo from "layouts/logo";
import SigninwithPhone from "components/auth/user/SigninwithPhone";
import SignupwithEmail from "components/auth/user/SignupwithEmail";
import SocialLogin from "components/auth/SocialLogin";
const UserRegister = () => {
   const navigate = useNavigate();
   const [loading, setLoading] = useState(false);
   const [selectedIndex, setSelectedIndex] = useState(0)
   const [step , setStep] = useState(0);


   const { login } = useAuth();
   
    const setActivestepfun = (val) => {
      console.log("Parent FUNCtion",val);
      setStep(val);
   }

    const Pagedisplay = () =>{
      console.log("Step",step);
      switch (step) {
        case 1: 
          return (
            <PatientRegisterMain setActivestepfun={setActivestepfun} />
          )
        case 2: 
          return (
            <PatientRegisterBasic setActivestepfun={setActivestepfun} />
          )
        default: 
           // do nothing
      }
   }

   return(
      <ScrollPageTop>
         
         <DoctorRegProvider>
         <div className="flex bg-white align-center">
            <div className="w-1/2 bg-cover bg-center min-h-screen hidden md:block" style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${APP_URL}/img/userbg-reglog.jpg)`,backgroundSize:'cover',}} >
               <div className="px-4 py-3">
               <div className="flex w-full items-center lg:w-auto">
                    <Logo className={'ltr:ml-0 rtl:mr-0 text-white px-2 py-2 '} />
                </div>
                <h2 className="text-5xl text-white flex justify-center align-center mt-10">
                Talk to Top Therapists and Mental Health Experts
                </h2>
                <p className=" text-white flex justify-center align-center mt-10">
                  Sometimes all you need to feel better is someone who really understands. Connect with our experts for 24/7 professional help at an affordable price.
                </p>
                </div>
            </div>
            <div className="flex h-full min-h-screen w-full flex-col justify-center bg-light py-6 px-5  md:h-auto md:min-h-0 md:w-1/2 ">
                <div className="flex w-full items-center justify-center lg:w-auto block md:hidden">
                    <Logo className={'text-accent px-2 py-2 '} />
                </div>
               {step < 2 ?
               <>
                   <div className="flex justify-center mb-5">
                     <h1 className="text-heading">Sign Up</h1>
                  </div>  
                  <Tab.Group
                  selectedIndex={selectedIndex} onChange={setSelectedIndex}
                  >
                  <Tab.List className="flex justify-center">
                    <Tab className={`px-4 py-3 font-semibold ${(selectedIndex == 0 ? 'border-b-0 bg-accent text-white ': ' border-gray-500 border-b-2')} `}>With Email/OTP</Tab>
                    <Tab className={`px-4 py-3 font-semibold ${(selectedIndex == 1 ? 'border-b-0 bg-accent text-white': ' border-gray-500 border-b-2')} `}>With Phone No./OTP</Tab>
                  </Tab.List>
                  <Tab.Panels>
                    <Tab.Panel><SignupwithEmail setActivestepfun={setActivestepfun} /></Tab.Panel>
                    <Tab.Panel><SigninwithPhone setActivestepfun={setActivestepfun} /></Tab.Panel>
                  </Tab.Panels>
                </Tab.Group>
                 <SocialLogin mode='Register' user_type='user' />
                  <div className="relative mt-8 mb-6 flex flex-col items-center justify-center text-sm text-heading sm:mb-8"><hr className="w-full" /><span className="absolute -top-2.5 bg-light px-2 ltr:left-2/4 ltr:-ml-4 rtl:right-2/4 rtl:-mr-4">Or</span></div>
                  <div className="text-center text-sm text-body sm:text-base">Already have an account? <Link to={`${Links.USER_LOGIN}`} className="font-semibold text-accent underline transition-colors duration-200 hover:text-accent-hover hover:no-underline focus:text-accent-hover focus:no-underline focus:outline-none ltr:ml-1 rtl:mr-1">Login</Link></div>

               </>
               :
               <>
               {step ?
                  (<div className="formbody">
                  {Pagedisplay()}
                  </div>):
                 null
               }
               </>
               }
              
            </div>
         </div>
         <div id="recaptcha-container"></div>

       

         </DoctorRegProvider>     
      </ScrollPageTop>
   )
}

export default UserRegister