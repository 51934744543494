import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
  {/*<React.StrictMode> */}
    <GoogleReCaptchaProvider
    reCaptchaKey="6LcF7NoZAAAAALGM1XQnKFyMq19MB6-RPxLFA8XU"
    scriptProps={{
      async: false, // optional, default to false,
      defer: false, // optional, default to false
      appendTo: 'body', // optional, default to "head", can be "head" or "body",
      
    }}
    >
    <App />
    </GoogleReCaptchaProvider>
  {/*</React.StrictMode> */}
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
