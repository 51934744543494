import React, {useState, useEffect} from "react";
import {APP_URL, IMG_URL,BACKEND_URL, amount_format,discount , showRating, amount_with_current} from 'components/config/Common'

import { BiRupee } from "react-icons/bi";
import { motion as ms } from 'framer-motion';
import {Links} from 'routes/PathLink'
import { Link } from "react-router-dom";
import { LazyLoadImage, LazyLoadComponent } from 'react-lazy-load-image-component';
import Badge from "components/ui/badge/badge";
import CallNowBtn from "components/calling/buttons/CallNowBtn";
import ScheduleAppointmentBtn from "components/appointment/buttons/ScheduleAppointmentBtn";
import * as moment from 'moment'
import ExpertWishlist from 'components/experts/ExpertWishlist';

const RelatedExpertCard = ({
   item,
   upcoming
}:props) => {

   const [user_type, setUser_type] = useState('')

    useEffect(() => {
      
         const user_type = localStorage.getItem('user_type');
         setUser_type(user_type);
       
    }, [])

   const TagsListsp = (list) =>{
      //console.log("TagsList FUNCTION ",list);
      if(list && list.length > 0){
          return( <>{list.map((val)=>(
                  <div className="px-1"><Badge text={val.label} color="bg-gray-400 " /></div>
               ))
               }  
            </>
         );
      }
   }

   const TagsListLan = (list) =>{
      //console.log("TagsList FUNCTION ",list);
      if(list && list.length > 0){
          return( <>{list.map((val)=>(
                  <div className="px-1"><Badge text={val.lan} color="bg-gray-400 " /></div>
               ))
               }  
            </>
         );
      }
   }

   return(
     <Link to={`${Links.EXPERT}/${item.slug}`} key={`expert-${item.id}`}>
                           <article className="product-card cart-type-krypton h-full cursor-pointer overflow-hidden rounded border border-border-200 bg-light transition-shadow duration-200 hover:shadow-sm dark:bg-gray-700 dark:border-none">
                              <div className="relative flex h-40 w-auto items-start justify-center min-h-max    sm:h-44 px-4 py-4">
                                 
                                 <div className="flex w-full flex-wrap justify-center w-1/2">

                                   <div className="h-28 overflow-hidden shadow rounded-full w-28 h-auto align-top border-none flex-wrap flex">
                                    {item.profile_image
                                       ?
                                       <LazyLoadImage src={`${BACKEND_URL}${item.profile_image}`} className="object-cover w-full" alt={item.name}  />
                                       :
                                       <LazyLoadImage src={`${APP_URL}/img/Doctor-placeholder-female.jpeg`} alt={item.product_name} className="shadow rounded-full max-w-full h-auto align-middle border-none" sizes="100vw" />
                                       }
                                     {/*<img src="https://www.creative-tim.com/learning-lab/tailwind-starter-kit/img/team-2-800x800.jpg" alt="..." className="shadow rounded-full max-w-full h-auto align-middle border-none" /> */}
                                   </div>
                                 </div>      
                                 <div className="flex flex-col w-full flex-wrap justify-start align-top  w-1/2">
                                    <div className="capitalize text-xl">{item.name}</div>
                                    <div className="flex">{showRating(item.rating)}
                                    {user_type == 'user' ?
                                          <ExpertWishlist item={item} />
                                        :
                                        <></>
                                      }
                                    </div>
                                   

                                    {item.session_price ?
                                       <div className="flex  mt-2 text-sm flex-wrap  "><span>Starts @ </span> {amount_with_current(item.session_price)}&nbsp; for 30 mins</div>
                                       :
                                       null
                                    }
                                    
                                    <div className="mt-2">
                                    {item.availability_status == 'online'?
                                    (<span class="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400">Online</span>
)                                   :null

                                    }
                                    {item.availability_status == 'offline'?
                                    (<span class="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400">Offline</span>
)                                   :null

                                    }
                                    {item.availability_status == 'busy'?
                                    (<span class="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400">Busy</span>
)                                   :null

                                    }
                                    {item.availability_status == 'on another call'?
                                    (<span class="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400">On Another Call</span>
)                                   :null

                                    }
                                    </div>
                                    

                                 </div>

                              </div>
                              <div className="flex justify-center items-center flex-col">
                                  {item.next_available_date ?
                                       <div className="flex  mt-2 text-sm  "><span className="font-semibold">Available From: </span> {moment(item.next_available_date).format('DD MMM , YYYY hh:mm A')} </div>
                                       :
                                       null
                                    }
                                 <div className="mt-2 flex flex-wrap">{TagsListsp(item.specalizationdt)}</div>
                                 <div className="mt-2 flex flex-wrap">{TagsListLan(item.languagesdt)}</div>
                              </div>
                              <header className="relative p-3 md:p-5 md:py-6">
                                 <div className="flex justify-center">
                                    {item.availability_status != 'online'?
                                       <ScheduleAppointmentBtn info={item} />
                                    :
                                       <div className="flex flex-col"><CallNowBtn  info={item} />
                                       <div className="ml-2 mt-2"><ScheduleAppointmentBtn info={item}  /></div>
                                       </div>
                                    }
                                 </div>
                              </header>
                           </article>
                        </Link>
   )
}

export default RelatedExpertCard;