import React, { useState,createRef,useEffect } from 'react';
//import Cropper from 'cropperjs';
import Cropper, { ReactCropperElement } from "react-cropper";
import {API_ENDPOINT, toast_anim , pdfdoconly} from 'components/config/Common'
import axios from 'axios';  
import Button from 'components/ui/Button.tsx'

import 'cropperjs/dist/cropper.css';

const ImageUpload = ({image_data,closeImageupload}) => {
  const [image, setImage] = useState(image_data);
  console.log("ASASaS",image);
  const [cropper, setCropper] = useState(null);
  const cropperRef = createRef();
  const [access_token, setAccess_token] = useState(localStorage.getItem('access_token'))
  const [loading,setLoading] = useState(false);


  const handleCrop = () => {
    const canvas = cropper.getCroppedCanvas();
    //canvas.toBlob((blob) => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      setCropper(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
      const input = {
        image : cropperRef.current?.cropper.getCroppedCanvas().toDataURL(),
      }
       //console.log(blob);
         axios.post(`${API_ENDPOINT}/update_expert_avatar_web`,input,{
         headers: {
            'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer '+access_token, 
           }
        }).then( (result)=> {
           console.log('data=====',result.data)
           console.log('IN UPDATE',result?.data?.data);
           setLoading(false)
           console.log('data=====',result);
           console.log("NAME",result.data?.user);
        if(result.data.status === 1){
          //setSubmitting(false)
          localStorage.setItem('user_name',result.data?.user?.name);
          localStorage.setItem('profile_image',result.data?.data?.profile_image);
          closeImageupload();
          toast_anim(result.data.message)
        }
        })
    }
     
    //});
  };


  

  return (
    <div className="flex justify-center flex-col">
       <Button onClick={handleCrop} className="mt-3 mb-2 w-1/3 ml-2 bg-black">Crop And Save</Button>
      {image && (
        <Cropper
          ref={cropperRef}
          src={image}
          initialAspectRatio={1}
          guides={false}
          viewMode={1}
          minCropBoxWidth={100}
          minCropBoxHeight={100}
          background={false}
          responsive={true}
          autoCropArea={1}
          checkOrientation={false}
          onInitialized={(instance) => {
            setCropper(instance);
          }}
        />
      )}
     
    </div>
  );
};

export default ImageUpload;