import React ,{ useState, useEffect} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {patient_links, doctor_links, customer_reject_links , expert_links} from '../permissions/Permissions'
import {Links} from '../routes/PathLink'

export const Authentication = (props, {children}) => {
	
   const navigate = useNavigate();
	const {pathname} = useLocation();
	//const [access_token] = useState(localStorage.getItem(process.env.REACT_APP_CODE+'access_token'));
	//const [user_type] = useState(localStorage.getItem(process.env.REACT_APP_CODE+'user_type'));
	const [access_token] = useState(localStorage.getItem('access_token'));
	const [user_type] = useState(localStorage.getItem('user_type'));
	useEffect(() => {

		var promise = new Promise( (resolve, reject) => {
			
			//dealer try to access user links
			if(user_type === 'expert'){
				if(patient_links.includes(pathname)){
					reject("rejected patient");
				}
			}
			if(user_type === 'user'){
				if(expert_links.includes(pathname)){
					reject("rejected doctor");
				}
			}
			/*if(user_type === 'customer'){
				if(customer_reject_links.includes(pathname)){
					reject("rejected");
				}
			}
			*/
			console.log("access_token",'A-access_token'+access_token);
			console.log("user_type",user_type);
			if (access_token  && user_type) {
				console.log("IN IF ");
				resolve("success");
			}else {
				reject("rejected patient");
			}	
		});
			
		promise.then( result => {
			return result;
		})
		.catch(error => {
			console.log("CATECH PROMISE",error);
			if(error == 'rejected patient'){
				return navigate(Links.USER_LOGIN, { replace: true });
			}else if(error == 'rejected doctor'){
				return navigate(Links.BECOMEANEXPERT, { replace: true });
			}else{
				return navigate("/", { replace: true });
			}
			
			//return navigate("/", { replace: true });
			
		});
		
	},[user_type,access_token]);

	return (
    	<>{props.children}</>
  	)
}