import React, { useState, useRef, useEffect } from "react";
import Lottie from "lottie-react";
import { createPopper } from "@popperjs/core";
import { createMeeting, getToken, validateMeeting } from "api";
import { Link } from "react-router-dom";
import ModalView from '_utils/ModalView'
import Calloutgoing from "components/calling/screens/Calloutgoing";
import {API_ENDPOINT, toast_anim, toast_anim_error,amount_format , EXTERNAL_AUDIO} from 'components/config/Common'
import ConfirmBoxSessionPrice from "components/calling/ConfirmBoxSessionPrice";
import {useOutgoingModal} from "contexts/outgoingmodal/outgoingmodal";
import {useCallingAudio} from "contexts/callingaudio/callingaudio";

//import ringtone from "../audio/ringing-151670.mp3";
  const GetSessionAmountMessage = ({info}) =>{
    console.log("info data",info);
    return(<><div className="flex text-white"><span className="mr-1">{amount_format(info?.session_price)}</span> is going to be deducted from your account.</div></>)
  }

const CallNowBtn = ({info}) => {
  const [access_token, setAccess_token] = useState(localStorage.getItem('access_token'))
  const [user_id, setUser_id] = useState(localStorage.getItem('user_id'));
  const [user_type, setUser_type] = useState(localStorage.getItem('user_type'));
  
  const [token,setToken] = useState('');
  const [meetingId,setMeetingId] = useState('');
  const [opencalling,setOpencalling] = useState(false);
  const [doctorinfo,setDoctorinfo] = useState(info);
  const [confirmsessionprice ,setConfirmsessionprice] = useState(false);
  const {calldeclined, updateCallDeclined} = useOutgoingModal();
  const {callingaudio,updateCallingAudio} = useCallingAudio();
  let audio = useRef();

  const confirmSessionPrice = () =>{
    setConfirmsessionprice(true);
  }

   useEffect(() => {
    console.log("DECLINED VAL VALUE",calldeclined);
    if(calldeclined == 'yes'){
      setOpencalling(false);
      audio?.current?.pause();
    }
   },[calldeclined]); 

    useEffect(() => {
    if(callingaudio == 'yes'){
       audio?.current?.pause();
       setOpencalling(false);
       updateCallingAudio('no');
    }
   },[callingaudio]);   
    
  const Callfun = async() => {
    const token = await getToken();
    const _meetingId = await createMeeting({ token });
    const valid = await validateMeeting({
                        roomId: _meetingId,
                        token,
                      });

    if (valid) {
        setToken(token);
        localStorage.setItem('token',token);
        setMeetingId(_meetingId);
        console.log("MEETING ID",_meetingId);
          let values = {
            'meetingId': _meetingId,
            'participantName':info?.name,
            'expertId':info?.id,
            'user_id':user_id,
            'token':token,
           } ; // declaring array
        fetch(`${API_ENDPOINT}/calling_start_request`,{
          method: 'POST',
          headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json', 
            'Authorization': 'Bearer '+access_token, 
          },
             body: JSON.stringify(values)
          }).then(function (response) {
              return response.json();
          }).then( (result)=> {
             console.log('mail====== pushNotification',result);
             if(result?.status == 0){
               toast_anim_error(result?.message);
             }

             if(result?.status == 1){
               setOpencalling(true);
               audio.current = new Audio(EXTERNAL_AUDIO);
               audio.current.play();
               audio.current.loop = true;
             }
             
          }).catch((error) => {
            console.log(error)
          });

       
        //updateCallingAudio(audio);
        //updateCallingAudio('yes');
        
    }

    //setToken(token);
    //setMeetingId(_meetingId);
    //setParticipantName("");
  }

  const closeCalling = () =>{
     setOpencalling(false);
     audio.current.pause();
     SelfCallCanel();
  }


   const SelfCallCanel = () =>{
    console.log("data before Cancel",info);
     let values = {
        'meetingId': meetingId,
        'participantName':info?.name,
        'expertId':info?.id,
        'user_id':user_id,
       }; // declaring array
    fetch(`${API_ENDPOINT}/calling_request_cancel`,{
        method: 'POST',
        headers : {        
          'Content-Type': 'application/json',
          'Accept': 'application/json', 
          'Authorization': 'Bearer '+access_token, 
        },
           body: JSON.stringify(values)
        }).then(function (response) {
            return response.json();
        }).then( (result)=> {
           console.log('mail====== pushNotification',result);
           
        })
  }


 
  return (
    <>  
      {user_type == 'expert'?
        null
        :
         <Link to={"#_"} class="relative inline-flex items-center justify-center p-4 px-6 py-3 overflow-hidden font-medium text-accent transition duration-300 ease-out border-2 border-accent rounded-full shadow-md group" onClick={confirmSessionPrice}>
        <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-accent group-hover:translate-x-0 ease">
        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
        </span>
        <span class="absolute flex items-center justify-center w-full h-full text-accent transition-all duration-300 transform group-hover:translate-x-full ease">Call Now</span>
        <span class="relative invisible">Call Now</span>
        </Link>
      }
       
        <ModalView open={opencalling} onClose={closeCalling}>
          <Calloutgoing closeCalling={closeCalling} info={info}/> 
        </ModalView>

        <ConfirmBoxSessionPrice
        open={confirmsessionprice}
        title="Session Amount"
        subTitle={<GetSessionAmountMessage info={info} />}
        rejectText="Cancel"
        successText="OK"
        onSuccess={() => {
          setConfirmsessionprice(false);
          Callfun();
        }}
        onReject={()=>{
          setConfirmsessionprice(false);
        }}
      />

    </>
  );
};

export default CallNowBtn;
