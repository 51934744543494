import React, { useEffect, useState } from "react";
import ModalView from '_utils/ModalView'
import {Links} from 'routes/PathLink'
import Pusher from 'pusher-js';
import AcceptCall from "pages/calling/AcceptCall";
import { useNavigate } from 'react-router-dom';
import ConfirmBox from "components/calling/ConfirmBox";
import {useOutgoingModal} from "contexts/outgoingmodal/outgoingmodal";
import {useCallingAudio} from "contexts/callingaudio/callingaudio";


const PusherRequestExpert = ({}) => {
    const navigate = useNavigate();
        const [access_token,setAccess_token] = useState(localStorage.getItem('access_token'));

    const [user_id, setUser_id] = useState(localStorage.getItem('user_id'))
    const [user_type, setUser_type] = useState(localStorage.getItem('user_type'))
    const [meetingId, setMeetingId] = useState('')
    const [datainfo, setDatainfo] = useState('')
    const [reqcount, setReqcount] = useState(0);
    const [confirmdecline,setConfirmdecline] = useState(false);
    const {calldeclined, updateCallDeclined} = useOutgoingModal();
    const {callingaudio,updateCallingAudio} = useCallingAudio();
    const [acceptCallModal,setAcceptCallModal] = useState(true);
    const [openacceptcalling,setOpenacceptcalling] = useState(false);
  useEffect(() => {
     var pusher = new Pusher('60a17a016b93f0924a12', {
      cluster: 'ap2'
    });
     
     var channel = pusher.subscribe('my-channel');
     
      channel.bind('user_calling', function(data) {
        console.log("RESPOSNE patient_calling",data);
        if(data.meetingId != '' && data.expert_id == user_id && user_type == 'expert'){
          setDatainfo(data);
          setMeetingId(data.meetingId);
          //setOpenacceptcalling(true);
          navigate(Links.ACCEPTCALL,{state:data})
        }
      });
      

        channel.bind('calling_request_cancel', function(data) {
        console.log("RESPOSNE calling_request_cancel",data);
        if(data.meetingId != '' && data.expert_id == user_id && user_type == 'expert'){
          //console.log("IN IF Call DEclined");
           updateCallingAudio('yes');
           channel.unbind('calling_request_cancel');
            setTimeout(() => { 
            navigate(Links.EXPERTCALLSESSION,{state:data});
          }, 2000);
           
          }
      }); 
      

      channel.bind('user_calling_end', function(data) {
        console.log("RESPOSNE user_calling_end",data);
        if(data.meetingId != '' && data.expert_id == user_id && user_type == 'expert'){
          //console.log("IN IF Call DEclined");
          channel.unbind('user_calling_end');
           //navigate(Links.EXPERTCALLSESSION,{state:data});
          }
      }); 

      channel.bind('expert_calling_end', function(data) {
        console.log("RESPOSNE expert_calling_end",data);
        if(data.meetingId != '' && data.expert_id == user_id && user_type == 'expert'){
          //console.log("IN IF Call DEclined");
          channel.unbind('expert_calling_end');
           //navigate(Links.EXPERTCALLSESSION,{state:data});
          }
      }); 


      
      //
      /*let data = {
          'meetingId':'y4e2-8lv6-wbma',
          'docId':18,
        }
        setDatainfo(data);
        setMeetingId(meetingId);
        if(data.meetingId){
          navigate(Links.ACCEPTCALL,{state:data})
        }
        */
    },[access_token]);

 
  const closeLoginNav = () =>{

  }

  const closeAcceptCalling = () =>{

  }

  return (
      <>
       <ConfirmBox
        open={confirmdecline}
        title="Call Declined"
        subTitle={"Your Call Request Declined by doctor."}
        successText="DISMISS"
        onSuccess={() => {
          setConfirmdecline(false);
          updateCallDeclined('');
          navigate(Links.DOCTORS);
        }}
       
      />
        <ModalView open={openacceptcalling} onClose={closeAcceptCalling}>
          <AcceptCall closeCalling={closeAcceptCalling}   /> 
        </ModalView>

      </>
  );
};

export default PusherRequestExpert;
