import React from "react";
import { useLottie } from "lottie-react";
import animation_lo73sfyc from "./animation_lo73sfyc.json";

const LoadingLottie = () => {
  const options = {
    animationData: animation_lo73sfyc,
    loop: true
  };

  const { View } = useLottie(options);

  return <>{View}</>;
};

export default LoadingLottie;