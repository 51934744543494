import { CheckIcon, ClipboardIcon } from "@heroicons/react/outline";
import React, { useEffect, useState , useRef} from "react";
import Pusher from 'pusher-js';
import { useNavigate } from 'react-router-dom';
import {API_ENDPOINT, toast_anim, toast_anim_error , EXTERNAL_AUDIO} from 'components/config/Common'
import {Links} from 'routes/PathLink'
import {CallDecline , CallIcon} from 'components/custom-icons'; 
import { IoCall } from "react-icons/io5";
import {useCallingAudio} from "contexts/callingaudio/callingaudio";



export function MeetingDetailsAccScreen({
  onClickJoin,
  _handleOnCreateMeeting,
  participantName,
  setParticipantName,
  videoTrack,
  setVideoTrack,
  onClickStartMeeting,
  datainfo,
}) {

  const [access_token, setAccess_token] = useState(localStorage.getItem('access_token'))
  const [user_name, setUser_name] = useState(localStorage.getItem('user_name'))
   const {callingaudio,updateCallingAudio} = useCallingAudio();
  const [meetingId, setMeetingId] = useState(datainfo?.meetingId);
  const [meetingIdError, setMeetingIdError] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [iscreateMeetingClicked, setIscreateMeetingClicked] = useState(false);
  const [isJoinMeetingClicked, setIsJoinMeetingClicked] = useState(false);
  //const [participantName,setParticipantName] = useState(datainfo?.meetingId);
  const navigate = useNavigate();
  let audio = useRef();
  useEffect(() => {
    console.log("music audio",datainfo);
    if(datainfo?.meetingId){
      audio.current = new Audio(EXTERNAL_AUDIO);
      audio.current.play();
      audio.current.loop = true;
    }
    
  },[])

  useEffect(() => {
    if(callingaudio == 'yes'){
       audio?.current?.pause();
       updateCallingAudio('no');
    }
   },[callingaudio]);   
    

  /*useEffect(() => {

     /*var pusher = new Pusher('60a17a016b93f0924a12', {
      cluster: 'ap2'
    });
   
     var channel = pusher.subscribe('my-channel');
      channel.bind('my-event', function(data) {
        console.log("RESPOSNE FRO MEET ",data);
        //alert(JSON.stringify(data));
        if(data.meetingId != '' && data.participantName != participantName){
          setMeetingId(data.meetingId);
          //setParticipantName("Participtant ACCpeted");
          console.log("iscreateMeetingClicked CLICKED",iscreateMeetingClicked);
          console.log("iscreateMeetingClicked CLICKED",data.meetingId);
           if (iscreateMeetingClicked) {
                if (videoTrack) {
                  videoTrack.stop();
                  setVideoTrack(null);
                }
                onClickStartMeeting();
              } else {
                if (data.meetingId.match("\\w{4}\\-\\w{4}\\-\\w{4}")) {
                  onClickJoin(data.meetingId);
                } else setMeetingIdError(true);
              }
        }
      });
      */
   /*  setParticipantName(meetingId);
    console.log("MEETING ID",meetingId);
    },[]);
    */
  const AcceptCallPushnotificatio = () =>{
    console.log("data before Accept",datainfo);
     
     let values = {
        'meetingId': meetingId,
        'participantName':datainfo?.participantName,
        'expertId':datainfo?.expert_id,
        'user_id':datainfo?.user_id,
       } ; // declaring array
    fetch(`${API_ENDPOINT}/calling_request_accept`,{
        method: 'POST',
        headers : {        
          'Content-Type': 'application/json',
          'Accept': 'application/json', 
          'Authorization': 'Bearer '+access_token, 
        },
           body: JSON.stringify(values)
        }).then(function (response) {
            return response.json();
        }).then( (result)=> {
           console.log('mail====== pushNotification',result);
           
        })
  }

  const DeclineCallPushnotificatio = () =>{
     audio?.current?.pause();
    console.log("data before Accept",datainfo);
     let values = {
        'meetingId': meetingId,
        'participantName':datainfo?.participantName,
        'expertId':datainfo?.expert_id,
        'user_id':datainfo?.user_id,
       } ; // declaring array
    fetch(`${API_ENDPOINT}/calling_request_decline`,{
        method: 'POST',
        headers : {        
          'Content-Type': 'application/json',
          'Accept': 'application/json', 
          'Authorization': 'Bearer '+access_token, 
        },
           body: JSON.stringify(values)
        }).then(function (response) {
            return response.json();
        }).then( (result)=> {
           console.log('mail====== pushNotification',result);
           
        })
     navigate(Links.MYDOCTORWALLET);
  }

  return (
    <div
      className={`flex flex-1 flex-col justify-center w-full md:p-[6px] sm:p-1 p-1.5`}
    >
      {datainfo?.user_name &&
        <div className="text-white text-3xl text-center">{datainfo?.user_name} <br /><span className="text-sm">Calling</span></div>
      }
      {!iscreateMeetingClicked && !isJoinMeetingClicked && (
        <div className="w-full md:mt-0 mt-4 flex flex-col">
          <div className="flex items-center justify-center w-full ">
          <div className="w-1/2 flex justify-center">
            <button
              className="w-14 flex justify-center items-center h-14 bg-red-500 text-white px-2 py-3 rounded-full mt-5"
              onClick={(e) => {
                DeclineCallPushnotificatio();
              }}
            >
                <CallDecline fill="#ffffff" className="w-10 h-10 text-center" />
            </button>
             </div>
           <div className="w-1/2 flex justify-center">
            <button
              className="animation-pulse1 w-14 h-14 flex justify-center items-center text-white px-2 py-3 rounded-full mt-5"
              onClick={(e) => {
                audio?.current?.pause();
                setIsJoinMeetingClicked(true);
                setParticipantName(user_name);
                if (meetingId.match("\\w{4}\\-\\w{4}\\-\\w{4}")) {
                  onClickJoin(meetingId);
                  AcceptCallPushnotificatio();
                } else setMeetingIdError(true);
              }}
              style={{backgroundColor:'#5cdb5c'}}
            >
             <IoCall fill="#ffffff" className="w-9 h-9 text-center" />
            </button>
           
            </div>
            
          </div>
        </div>
      )}
    </div>
  );
}
