import React, { useState, useRef, useEffect } from "react";
import Lottie from "lottie-react";
import { createPopper } from "@popperjs/core";
import { createMeeting, getToken, validateMeeting } from "api";
import { Link } from "react-router-dom";
import ModalView from '_utils/ModalView'
import ReqAppointmentFrm from "components/appointment/ReqAppointmentFrm";
import {API_ENDPOINT, toast_anim, toast_anim_error,amount_format} from 'components/config/Common'
import {useOutgoingModal} from "contexts/outgoingmodal/outgoingmodal";
 
const ScheduleAppointmentBtn = ({info}) => {
  const [access_token, setAccess_token] = useState(localStorage.getItem('access_token'))
  const [user_id, setUser_id] = useState(localStorage.getItem('user_id'));
  const [user_type, setUser_type] = useState(localStorage.getItem('user_type'));
  

  const [token,setToken] = useState('');
  const [meetingId,setMeetingId] = useState('');
  const [opencalling,setOpencalling] = useState(false);
  const [doctorinfo,setDoctorinfo] = useState(info);
  const {calldeclined, updateCallDeclined} = useOutgoingModal();

  const openRequestForm = () =>{
       setOpencalling(true);
       console.log("ABCDD",info);
  }

   useEffect(() => {
    console.log("DECLINED VAL VALUE",calldeclined);
    if(calldeclined == 'yes'){
      setOpencalling(false);
    }
   },[calldeclined]); 
  const Callfun = async() => {
    const token = await getToken();
    const _meetingId = await createMeeting({ token });
    const valid = await validateMeeting({
                        roomId: _meetingId,
                        token,
                      });

    if (valid) {
        setToken(token);
        localStorage.setItem('token',token);
        setMeetingId(_meetingId);
        console.log("MEETING ID",_meetingId);
          let values = {
            'meetingId': _meetingId,
            'participantName':info?.name,
            'docId':info?.id,
            'patient_id':user_id,
            'token':token,
           } ; // declaring array
        fetch(`${API_ENDPOINT}/calling_start_request`,{
          method: 'POST',
          headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json', 
            'Authorization': 'Bearer '+access_token, 
          },
             body: JSON.stringify(values)
          }).then(function (response) {
              return response.json();
          }).then( (result)=> {
             console.log('mail====== pushNotification',result);
             
          })

        setOpencalling(true);
    }

    //setToken(token);
    //setMeetingId(_meetingId);
    //setParticipantName("");
  }

  const closeCalling = () =>{
    setOpencalling(false);
  }

 
  return (
    <>  
      {user_type == 'expert'?
        null
        :
         <Link to={"#_"} class="relative inline-flex items-center justify-center p-4 px-6 py-3 overflow-hidden font-medium text-indigo-600 transition duration-300 ease-out border-2 border-secondary-color rounded-full shadow-md group" onClick={openRequestForm}>
        <span class="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-secondary-color group-hover:translate-x-0 ease">
        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
        </span>
        <span class="absolute flex items-center justify-center w-full h-full text-secondary-color transition-all duration-300 transform group-hover:translate-x-full ease">Request Appointment</span>
        <span class="relative invisible">Request Appointment</span>
        </Link>
      }
       
        <ModalView open={opencalling} onClose={closeCalling}>
          <ReqAppointmentFrm closeCalling={closeCalling} info={info} /> 
        </ModalView>
    </>
  );
};

export default ScheduleAppointmentBtn;
