import React, {useState, useEffect} from "react";

import {API_ENDPOINT} from 'components/config/Common'
import Checkbox from 'components/ui/checkbox/checkbox.tsx'
import Radio from 'components/ui/radio/radio.tsx';
import Button from 'components/ui/Button.tsx'
import {CloseIcon} from 'components/custom-icons'
import {isBrowser} from "react-device-detect"
import { BsFillArrowDownSquareFill } from "react-icons/bs";
import {BsFillArrowUpSquareFill} from "react-icons/bs";

const LanguageFilter = (props) => {

   const [languages, setLanguages] = useState(null)
   const [lan_id, setLan_id] =  useState(null)
   const [isopen, setIsopen] =  useState(false);
   
   useEffect(() => {
      getFilterLanguages()
   },[])

   const getFilterLanguages = () => {
      fetch(`${API_ENDPOINT}/languages_list`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
         //console.log('brands', result)
         setLanguages(result.data)
      })
   }

   const handleChange = (e) => {
      console.log('onchange list', e.target.name, e.target.value,e.target.checked);
      if(e.target.checked == true){
         setLan_id(e.target.value);
      }
      //&& e.target.checked == true

      languages.filter((item, i) =>  {
         if(item.id == e.target.value ){
            props.handleBrandFilter({
               lan:item.lan,
               id:item.id,
               checked:e.target.checked,
            })    
         }
      });
      //props.handleBrandFilter(e.target.value)
      if(isBrowser){
         setTimeout(() => {
            window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
            });
         }, 10);
      }
   }

   const clearBrandFilter = () => {
      let radio = document.getElementById(lan_id);
      radio.checked = false;
      props.handleBrandFilter({
         lan:null,
         id:null
      }) 
   }

   const toggleOtions = () =>{
      setIsopen(!isopen);
   }
   

   return(
      <div className="h-full w-full bg-light dark:bg-gray-700 p-2">
         <div className="flex flex-col">
            <div className="flex w-full cursor-pointer" onClick={toggleOtions}>
            <div className="text-heading dark:text-zinc-400 uppercase  w-9/12 ">Languages </div>
            <div className="w-3/12 flex justify-center  items-end   ">{isopen ? <BsFillArrowUpSquareFill/> : <BsFillArrowDownSquareFill />}</div>
            </div>
            <div class="border-2 border-gray-200 w-1/4 mt-2 mb-2"></div>
           
         </div>
         {isopen ?

         <ul class="text-xs" >
            {
            languages && languages.map((val, key) => (
            <li class="rounded-md py-1" key={`language-${val.id}`}>
               <div class="flex w-full items-center py-2 font-semibold text-body-dark outline-none transition-all ease-in-expo  focus:text-accent focus:outline-none focus:ring-0 ltr:text-left rtl:text-right text-body-dark text-sm text-body dark:text-zinc-400">
                  <Checkbox
                     name='language'
                     label={val.lan}
                     id={val.id}
                     value={val.id}
                     className=""
                     theme='primary'
                     onChange={handleChange}
                  />
               </div>
            </li>
            ))
            }
         </ul>
         :
         null
         }
         
      </div>
   )
}

export default LanguageFilter