import React, {useState, useEffect} from "react";
import Header from './Header';
import Footer from './Footer';
import CustomerSideBar from "./CustomerSideBar";
import ImageUpload from "components/ui/imageupload";

const LeftMenuLayout = ({children}) => {
      const [user_type,setUser_type] =useState(localStorage.getItem('user_type'));

   return(
      <>
      <Header hidesearch={true} />
         <div className={'mx-auto flex w-full max-w-1920 flex-col items-start bg-light py-10 px-5 lg:bg-gray-100 xl:flex-row xl:py-14 xl:px-8 2xl:px-14 dark:bg-gray-800'}>
            
            <CustomerSideBar /> 
            {children}
         </div>
      <Footer />      
      </>
   )
}

export default LeftMenuLayout