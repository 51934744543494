import React, { createContext, useContext, useState, useEffect , useCallback } from "react";
import {API_ENDPOINT} from 'components/config/Common'

const FORM_STATE = {
  step: 0,
  steps: {
    details: {
      valid: true,
      dirty: false,
      value: {
        name: '',
        phone_no: '',
        email: '',
        password: '',
      },
    },
    qualification: {
      valid: false,
      dirty: false,
      value: {
        qualification: '',
        experience: '',
      },
    },
     specification: {
      valid: false,
      dirty: false,
      value: {
        specifilization: '',
        language: '',
      },
    },
     sessionprice: {
      valid: false,
      dirty: false,
      value: {
        sessionPrice: '',
      },
    },
  },
};

const DoctorRegStateContext = createContext({
  form: FORM_STATE,
  setForm: (
    form: typeof FORM_STATE | ((form: typeof FORM_STATE) => typeof FORM_STATE)
  ) => {},
});

export const DoctorRegProvider = ({children}) => {

   const [form, setForm] = useState(FORM_STATE);

   const onComplete = useCallback((state) => {
       // do something with "state"
     }, []);

   return <DoctorRegStateContext.Provider value={{ form,
        setForm, }} >{children}</DoctorRegStateContext
.Provider>

}

export const useDoctorReg = () => {
   const context = React.useContext(DoctorRegStateContext
);
   if (context === undefined) {
      throw new Error(`useDoctorReg must be used within a DoctorRegStateContext
`);
   }
   return React.useMemo(() => context, [context]);
   //return context;
};