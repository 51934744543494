
import React, { FC, useEffect, useMemo, useState } from 'react';

export interface State {
  speclizationterm: string;
}

const initialState = {
  speclizationterm: '',
};

export const SpeclizationFilterContext = React.createContext<State | any>(initialState);

SpeclizationFilterContext.displayName = 'SpeclizationFilterContext';

export const SpeclizationFilterProvider: FC = (props) => {
  //const param = useParams();
  const [specTerm, setSpecTerm] = useState('');
  const [searchgender, setSearchgender] = useState('');
  const [searchlangues, setSearchlangues] = useState([]);
    const [searchlangueslbl, setSearchlangueslbl] = useState([]);

  const [searchlangueslen, setSearchlangueslen] = useState(0);
  const [searchonlineonly, setSearchonlineonly] = useState('no');
  const [sortpopular ,setSortpopular] = useState('no');
  const [sortsessionprice ,setSortsessionprice] = useState('');



  //console.log('context param', param)
  /* useEffect(() => {
    if (query?.text) {
      updateSearchTerm(query?.text as string);
    } else {
      updateSearchTerm('');
    }
  }); */

  const updateSpecTerm = (spec_id) => {
    console.log("SET  text",spec_id);
    setSpecTerm(spec_id)
  }


  const UpdateSearchgender = (gender) => {
    console.log("gender",gender);
    setSearchgender(gender)
  }
  

  const UpdateSearchlanuguage = (lan) => {
    //console.log("gender",gender);
    setSearchlangues(lan)
  }

  const value = useMemo(
    () => ({
      specTerm,
      setSpecTerm,
      searchgender,
      setSearchgender,
      searchlangues,
      setSearchlangues,
      searchlangueslen,
      setSearchlangueslen,
      searchonlineonly,
      setSearchonlineonly,
      sortpopular ,
      setSortpopular,
      sortsessionprice ,
      setSortsessionprice,
      searchlangueslbl, 
      setSearchlangueslbl
    }),
    [specTerm,searchgender,searchlangues,searchlangueslen,searchonlineonly,sortpopular,sortsessionprice]
  );

  return <SpeclizationFilterContext.Provider value={value} {...props} />;
};

export const useSpeclizationFilter = () => {
  const context = React.useContext(SpeclizationFilterContext);
  if (context === undefined) {
    throw new Error(`useSpeclizationFilter must be used within a SpeclizationFilterProvider`);
  }
  return context;
};