import React, {useState, useEffect , useRef} from "react";
import { Link , useNavigate } from "react-router-dom";

import LeftMenuLayout from 'layouts/LeftMenuLayout';
import {Links} from 'routes/PathLink'

import classNames from 'classnames';
import * as Yup from 'yup';
import {API_ENDPOINT, toast_anim , toast_anim_error} from 'components/config/Common'
import {Loading} from "components/custom-icons";

import axios from 'axios';  
import {APP_URL, APP_NAME , IMG_URL ,amount_format } from 'components/config/Common'

import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import { toast } from 'react-toastify';
import ScrollPageTop from 'components/ui/ScrollPageTop'

import { yupResolver } from '@hookform/resolvers/yup';
import Input from 'components/ui/input.jsx'
import Label from 'components/ui/label.tsx'
import Button from 'components/ui/Button.tsx'
import TextArea from 'components/ui/text-area';
import { RadioGroup } from '@headlessui/react'
//import { CheckIcon } from '@heroicons/react/20/solid'

import {
	Control,
	FieldErrors,
	useForm,
	useFormState,
	useWatch,
	Controller
 } from 'react-hook-form';

const amountSchema = Yup.object().shape({
	rating: Yup.number()
      .typeError('Amount must be a number')
      .positive('Amount must be positive')
      .required('Amount is required'),
});

/*
	resolver: yupResolver(amountSchema),

*/
let defaultValues = {  
	amount: '',
};


function CheckIcon(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
      <path
        d="M7 13l3 3 7-7"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const ReportSession = ({closeWithdraw,sessioninfo}) => {
	const navigate = useNavigate();
	const [access_token, setAccess_token] = useState(localStorage.getItem('access_token'))
	const [user_type, setUser_type] = useState(localStorage.getItem('user_type'))
	const [user_me, setUser_me] = useState(null);
	const [loading, setLoading] = useState(false);
	const [loading_social, setLoading_social] = useState(false);
   const [enabled, setEnabled] = useState(false)
   const [rating, setRating] = useState(0);
   const [reasons, setReasons] = useState([]);

     const [plan, setPlan] = useState(reasons[0])
     const [selected, setSelected] = useState(reasons[0]);


	const { register, handleSubmit , control, reset, setValue, setError, formState: { errors },} = useForm({
		defaultValues: defaultValues,
	
  	});


	useEffect(() => {
			getreportlist();
	},[])
	

	const getreportlist = () =>{
		//

		fetch(`${API_ENDPOINT}/get_report_reason`,{
			 method: 'GET',
			 headers : {        
				  'Content-Type': 'application/json',
				  'Accept': 'application/json', 
			 }
		}).then(function (response) {
				  return response.json();
		}).then( (result)=> {
			 console.log('reason list ======',result)
			 if(result?.status == 1){
			 	setReasons(result?.data);
			 }
			 //setStates(result.data)			
		})

	}

	const onSubmit = (values) => {
		console.log('value', values)
		console.log('sessioninfo', sessioninfo)
		setLoading(true)		
		
		let input = values;
      input = {...input, reason:selected, expert_id:sessioninfo?.expert_id,call_id:sessioninfo?.call_id,user_id:sessioninfo?.user_id}

      setLoading(true)
      axios.post(`${API_ENDPOINT}/add_report_session`,input,{
         headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer '+access_token, 
         }
      }).then( (result)=> {
         console.log('data=====',result.data)
         setLoading(false)
         console.log('data=====',result)
			if(result.data.status == 1){
				toast_anim(result.data.message);
				closeWithdraw();
				navigate(Links.MYDOCTORWALLET);
			}else if(result.data.status == 0){
				toast_anim_error(result.data.message);
			}
      })
	}


	// Catch Rating value
  const handleRating = (rate: number) => {
    setRating(rate)

    // other logic
  }

  const changeStatus = (val)=>{
  	console.log("val",val);
  	setSelected(val)
  }
  // Optinal callback functions
  const onPointerEnter = () => console.log('Enter')
  const onPointerLeave = () => console.log('Leave')
  const onPointerMove = (value: number, index: number) => console.log(value, index)

	return (<div className="w-full">
					<div className={classNames('w-full p-5 md:p-8 bg-light dark:bg-gray-700 shadow rounded mb-8')}>
						<p className="text-lg lg:text-xl text-heading capitalize mb-2 dark:text-zinc-400">Report this session</p>
						<div className="border-b-[1px] mb-2 pb-2 text-xs italic">
							Note: your opinion will not disclose to anyone. This is for system improvement.
						</div>
						<form onSubmit={handleSubmit(onSubmit)}>
								<div className="flex flex-col w-full">
								<div className="flex w-full mb-2">
								     <div className="mx-auto w-full max-w-md">
						        <RadioGroup value={selected} onChange={(val)=> changeStatus(val) }>
						          <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
						          <div className="space-y-2 my-4">
						            {reasons.map((plan) => (
						              <RadioGroup.Option
						                key={plan}
						                value={plan}
						                className={({ active, checked }) =>
						                  `${
						                    active
						                      ? 'ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-sky-300'
						                      : ''
						                  }
						                  ${
						                    checked ? 'bg-accent bg-opacity-75 text-white' : 'bg-gray-300'
						                  }
						                    relative flex cursor-pointer rounded-lg px-5 py-4 my-2 shadow-md focus:outline-none`
						                }
						              >
						                {({ active, checked }) => (
						                  <>
						                    <div className="flex w-full items-center justify-between">
						                      <div className="flex items-center">
						                        <div className="text-sm">
						                          <RadioGroup.Label
						                            as="p"
						                            className={`font-medium  ${
						                              checked ? 'text-white' : 'text-gray-900'
						                            }`}
						                          >
						                            {plan}
						                          </RadioGroup.Label>
						                          <RadioGroup.Description
						                            as="span"
						                            className={`inline ${
						                              checked ? 'text-sky-100' : 'text-gray-500'
						                            }`}
						                          >
						                           
						                          </RadioGroup.Description>
						                        </div>
						                      </div>
						                      {checked && (
						                        <div className="shrink-0 text-white">
						                          <CheckIcon className="h-6 w-6" />
						                        </div>
						                      )}
						                    </div>
						                  </>
						                )}
						              </RadioGroup.Option>
						            ))}
						          </div>
						        </RadioGroup>
						      </div>

						      </div>
						      {selected == 'Other' && 
						       <div>
								 <TextArea
		                   	name="reason"
		                   	placeholder="Type your reason here?"
		                   	className="w-full  mb-2"
		                   	{...register('reason')}
		                    />
		                    </div>
						   	}
						     
	                      </div>
									<div className="flex">
									<div className="flex  align-center justify-center">
									{
									!loading
									?	
									<button data-variant="normal" className="inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-5 py-0 h-12 ltr:ml-auto rtl:mr-auto">Report</button>
									:
									<Loading className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 ltr:ml-auto" />
									}

									<button data-variant="normal" onClick={closeWithdraw} className="ml-2 inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-black text-light border border-transparent hover:bg-black-hover px-5 py-0 h-12 ltr:ml-2 rtl:mr-auto">Cancel</button>

									
									</div>
								</div>
							</form>
					</div>
			</div>
   );
};

export default ReportSession