import React, {useState, useEffect ,useRef} from "react";

import { Link, useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import {
   Control,
   FieldErrors,
   useForm,
   useFormState,
   useWatch,
   Controller
 } from 'react-hook-form';
 
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';  
import {Links} from 'routes/PathLink'
import {
   API_ENDPOINT, 
   random_str, 
   toast_anim_error, 
   RAZOR_KEY, 
   loadScript,
   register_valid,
   register_fee,
   renew_per,
   pdfdoconly,
   specalizationOptions,
   languageOptions
} from 'components/config/Common'


import FileInput from 'components/ui/file-input.jsx';
import 'react-phone-input-2/lib/bootstrap.css'
import Button from 'components/ui/Button.tsx'
import {useAuth} from 'contexts/provider/AuthProvider'
import Label from 'components/ui/label.tsx'
import {deviceDetect} from "react-device-detect"
import Input from 'components/ui/input.jsx'
import { produce } from 'immer';
import { useDoctorReg } from "contexts/doctorregistration/doctor-reg";

//import SocialLogin from './SocialLogin'
/*
const SignupSchema = Yup.object().shape({
   name: Yup.string()
      .required('You must need to provide your company name.')
      .min(2, 'Name is too short!')
      .max(50, 'Name is too long!'),
   phone_no: Yup.string()
      .required('You must need to provide your phone number.')
      .min(12, 'Invalid phone number.'),
   email: Yup.string()
      .required('You must need to provide your email address.')
      .email('Invalid email.'),
   password: Yup.string()
      .required('You must need to provide your password.')
      .min(8, 'The password must be at least 8 characters.'),
   
   agree_to_pay:Yup.bool().oneOf([true], 'Field must be checked.'),
 
});
*/

const defaultValues = {  
 };

const RegisterSpecialisation = (props) => {
   const navigate = useNavigate();
   const [loading, setLoading] = useState(false);
   const [selected, setSelected] = useState([]);
   const {form,setForm} = useDoctorReg();
	const { login } = useAuth();

   const { register, handleSubmit , control, reset, setValue, setError, formState: { errors },} = useForm({
      defaultValues: defaultValues,
      shouldFocusError:false,
   });
   

   useEffect(() => {
      if(localStorage.getItem('access_token')){
         return navigate(Links.HOME, { replace: true });
      }
   })

   useEffect(() => {
      reset(defaultValues)
   },[])

   let fromdata = {
      'details':form?.steps?.details.values,
      'qualification':form?.steps?.qualification.values,
      'specification':form?.steps?.specification.values,
      'sessionprice':form?.steps?.sessionprice.values,
   } 
   const dealerSubscription = async (values) => {
      console.log("Session amunt Data",values);
      setLoading(true);
       setForm(
          produce((formState) => {
            formState.steps.sessionprice = {
              values,
              valid: false,
              dirty: false,
            };
          }));

       let fromdata = {
         'main':form?.steps?.main.values,
         'intro':form?.steps?.intro.values,
         'language':form?.steps?.language.values,
         'specialization':form?.steps?.specialization.values,
         'sessionprice':values,
         'register_from':'web',
        } 

        console.log("Form Data",fromdata);
       axios
      .post(API_ENDPOINT+'/expert_register', fromdata)
      .then((response) => {
         console.log("RESPONSE",response.data);
         if(response.data?.status == 1){
             localStorage.setItem('access_token',response.data.access_token);
             localStorage.setItem('user_type',response.data.user_type);
             localStorage.setItem('user_id',response.data.user_id);
             localStorage.setItem('user_name',response.data.user_name);
             setLoading(false);
             login({access_token:response.data.access_token, user_name:response.data.user_name})
             navigate(Links.DOCTORDASHBOARD, { replace: true });
         }
        //setPost(response.data);
      });
   }

   const onSubmit = async (values) => {
      // props.setActivestepfun(5);
      console.log("Session amunt Data",values);
   }
  
   return(
      <>
         <div className="flex justify-center">
            <div className="flex h-full min-h-screen w-screen flex-col justify-center bg-light py-6 px-5 sm:p-8 md:h-auto md:min-h-0 md:max-w-[580px] md:rounded-xl my-5">
               <div className="flex justify-center">
               <h1 className="text-xl font-black text-accent">Session Price</h1>
               </div>   
               <form onSubmit={handleSubmit(dealerSubscription)}>
                   
                   <div className="mb-5">
                        <Label>Price (for 30 minute for a single session) <span className="text-red-500"> </span></Label>
                        <Input
                           name="session-price"                            
                           control={control}   
                           {...register('session-price')}      
                            step="0.01"
                            type="number"  
                        />
                        <p className="my-2 text-xs text-start text-red-500">{errors.accessories?.message}</p>
                     </div>
                    <div className="flex w-full content-end ">
                    
                       <div className="w-1/2 ">
                        <Button 
                           className="w-1/2 self-end"
                           loading={loading}
                        >
                        Register
                        </Button>
                     </div>
                  </div>
               </form>
              
            </div>
         </div>
      </>
   )
}

export default RegisterSpecialisation