import React, {useState, useEffect , useRef} from "react";
import { Link } from "react-router-dom";

import LeftMenuLayout from 'layouts/LeftMenuLayout';

import classNames from 'classnames';
import * as Yup from 'yup';
import {API_ENDPOINT, toast_anim , toast_anim_error} from 'components/config/Common'
import {Loading} from "components/custom-icons";

import axios from 'axios';  
import {APP_URL, APP_NAME , IMG_URL ,amount_format } from 'components/config/Common'

import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import { toast } from 'react-toastify';
import ScrollPageTop from 'components/ui/ScrollPageTop'

import { yupResolver } from '@hookform/resolvers/yup';
import Input from 'components/ui/input.jsx'
import Label from 'components/ui/label.tsx'
import Button from 'components/ui/Button.tsx'

import {
	Control,
	FieldErrors,
	useForm,
	useFormState,
	useWatch,
	Controller
 } from 'react-hook-form';

const amountSchema = Yup.object().shape({
	account_name: Yup.string()
        .required('You must need to provide your Account name.')
        .min(2, 'Account Name is too short!')
        .max(50, 'Account Name is too long!'),
   bank_name: Yup.string()
        .required('You must need to provide your Bank name.')
        .min(2, 'Bank Name is too short!')
        .max(50, 'Bank Name is too long!'),
   account_no: Yup.string()
        .required('You must need to provide your Account number.'),
   ifsc_code: Yup.string()
        .required('You must need to provide your IFSC Code.'),
});


let defaultValues = {  
	amount: '',
};

const AddBankDetailForm = (props) => {
	
	const [access_token, setAccess_token] = useState(localStorage.getItem('access_token'))
	const [user_type, setUser_type] = useState(localStorage.getItem('user_type'))
	const [user_me, setUser_me] = useState(null);
	const [loading, setLoading] = useState(false);
	const [loading_social, setLoading_social] = useState(false);
   const [enabled, setEnabled] = useState(false)
  	const [totalamount,setTotalamount] = useState(0);

	const { register, handleSubmit , control, reset, setValue, setError, formState: { errors },} = useForm({
		defaultValues: defaultValues,
		resolver: yupResolver(amountSchema),
		
  	});


	useEffect(() => {
			getWalletTotal();
	},[])
	

	const getWalletTotal = () => {
		console.log(access_token)
		fetch(`${API_ENDPOINT}/doctor_total_wallet_amount`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': 'Bearer '+access_token, 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			console.log("TOTAL WALLET AMOUNT ",result)
			if(result.status == 1){
				setTotalamount(result.data);
			}
		})
	}	

	const onSubmit = (values) => {
		console.log('value', values)
		setLoading(true)		

		let input = values;
     
      setLoading(true)
      axios.post(`${API_ENDPOINT}/add_bank_details`,input,{
         headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer '+access_token, 
         }
      }).then( (result)=> {
         console.log('data=====',result.data)
         setLoading(false)
         console.log('data=====',result)
			if(result.data.status == 1){
				toast_anim(result.data.message);
				props.closeWithdraw();
			}else if(result.data.status == 0){
				toast_anim_error(result.data.message);
			}
      })

		
	}

	return (<div className="w-full">
					<div className={classNames('w-full p-5 md:p-8 bg-light dark:bg-gray-700 shadow rounded mb-8')}>
						<p className="text-lg lg:text-xl text-heading capitalize mb-2 dark:text-zinc-400">Add Bank Account</p>
						<form onSubmit={handleSubmit(onSubmit)}>
								<div className="flex w-full flex-col">
								 <Input                
	                            label={`Account Name`}
	                            {...register('account_name')}
	                            variant="outline"
	                            className="mb-5 w-full"
	                            error={errors.account_name?.message}
	                            required
	                        />
	                       <Input                
	                            label={`Bank Name`}
	                            {...register('bank_name')}
	                            variant="outline"
	                            className="mb-5 w-full"
	                            error={errors.bank_name?.message}
	                            required
	                        />

	                         <Input                
	                            label={`Account Number`}
	                            {...register('account_no')}
	                            variant="outline"
	                            className="mb-5 w-full"
	                            error={errors.account_no?.message}
	                            required
	                        />

	                        <Input                
	                            label={`Confirm Account Number`}
	                            {...register('account_no_confirm')}
	                            variant="outline"
	                            className="mb-5 w-full"
	                            error={errors.account_no_confirm?.message}
	                            required
	                        />
	                         <Input                
	                            label={`IFSC Code`}
	                            {...register('ifsc_code')}
	                            variant="outline"
	                            className="mb-5 w-full"
	                            error={errors.ifsc_code?.message}
	                            required
	                        />
	                      
	                      </div>
								<div className="flex">
									<div className="flex  align-center justify-center">
									{
									!loading
									?	
									<button data-variant="normal" className="inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-5 py-0 h-12 ltr:ml-auto rtl:mr-auto">Add</button>
									:
									<Loading className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 ltr:ml-auto" />
									}

									<button data-variant="normal" onClick={props.closeWithdraw} className="ml-2 inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-black text-light border border-transparent hover:bg-black-hover px-5 py-0 h-12 ltr:ml-2 rtl:mr-auto">Close</button>

									
									</div>
								</div>
							</form>
					</div>
			</div>
   );
};

export default AddBankDetailForm