import React, { useState, useRef, useEffect } from "react";
import Lottie from "lottie-react";
import { createPopper } from "@popperjs/core";
import { Link } from "react-router-dom";

import axios from 'axios';  

import {API_ENDPOINT, toast_anim, toast_anim_error,amount_format,APP_URL} from 'components/config/Common'
import ConfirmBoxSessionPrice from "components/calling/ConfirmBoxSessionPrice";
import {useOutgoingModal} from "contexts/outgoingmodal/outgoingmodal";
import ExpertStatusRadio from "./ExpertStatusRadio";
import {useAuth} from 'contexts/provider/AuthProvider'
import { AiOutlineHeart , AiFillHeart } from "react-icons/ai";

const ExpertWishlist = ({item}) => {
  const [access_token, setAccess_token] = useState(localStorage.getItem('access_token'))
  const [user_id, setUser_id] = useState(localStorage.getItem('user_id'));
  const [user_type, setUser_type] = useState(localStorage.getItem('user_type'));
  const { isUser, loginToken } = useAuth();
  const [doctorinfo,setDoctorinfo] = useState(item);


   useEffect(() => {
    setDoctorinfo(doctorinfo);
   },[doctorinfo]); 
 
   console.log("DATA INFO",doctorinfo);
   const toggleWishlist = (e) => {
    e.preventDefault(); 
    let input =[];
    input = {expert_id:item?.id};
     axios.post(`${API_ENDPOINT}/toggle_wishlist`,input,{
           headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer '+access_token,
           }
        }).then( (result)=> {
           console.log('data=====',result.data)
           //setLoading(false)
           console.log('data=====',result)
        if(result.data.status == 1){
          toast_anim(result.data.message);
          if(result?.data?.data){
            setDoctorinfo(result?.data?.data);
          }
          
          //props.closeCalling();
        }else if(result.data.status == 2){
          toast_anim(result.data.message);
          setDoctorinfo([]);
        }
        })
    }
  
 
  return (<div className="flex justify-center items-center ml-4 cursor-pointer  ">
    {doctorinfo?.wishlist_id  ?
    <AiFillHeart className="w-6 h-6 text-red-600"  onClick={toggleWishlist}/>
    :
    <AiOutlineHeart className="w-6 h-6 text-red-600" onClick={toggleWishlist} />
    }
    </div>);
};

export default ExpertWishlist;
