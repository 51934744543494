import React, { useEffect, useState } from "react";
import { Constants, MeetingProvider } from "@videosdk.live/react-sdk";
import { LeaveScreen } from "components/calling/screens/LeaveScreen";
import { AcceptScreen } from "components/calling/screens/AcceptScreen";
import { MeetingContainer } from "components/meeting/MeetingContainer";
import { MeetingAppProvider } from "contexts/meeting/MeetingAppContextDef";
import Pusher from 'pusher-js';
import {API_ENDPOINT, toast_anim, toast_anim_error,amount_format , EXTERNAL_AUDIO} from 'components/config/Common'

import {useLocation,useNavigate} from 'react-router-dom';
import {Links} from 'routes/PathLink'
import ModalView from '_utils/ModalView'

const AcceptCall = () => {
   //let participantNameId = Math.floor(Math.random() * 1000000);
  const [access_token, setAccess_token] = useState(localStorage.getItem('access_token'))
  const [user_id, setUser_id] = useState(localStorage.getItem('user_id'));
  const [user_name, setUserName] = useState(localStorage.getItem('user_name'));

  const location = useLocation();
  const datainfo = location?.state;
  const navigate = useNavigate();
  const [token, setToken] = useState("");
  const [meetingId, setMeetingId] = useState(datainfo?.meetingId);
  const [participantName, setParticipantName] = useState(Math.floor(Math.random() * 1000000));
  const [micOn, setMicOn] = useState(true);
  const [webcamOn, setWebcamOn] = useState(false);

  const [selectedMic, setSelectedMic] = useState({ id: null });
  const [selectedWebcam, setSelectedWebcam] = useState({ id: null });
  const [selectWebcamDeviceId, setSelectWebcamDeviceId] = useState(
    selectedWebcam.id
  );
  const [meetingMode, setMeetingMode] = useState(Constants.modes.CONFERENCE);
  const [selectMicDeviceId, setSelectMicDeviceId] = useState(selectedMic.id);
  const [isMeetingStarted, setMeetingStarted] = useState(false);
  const [isMeetingLeft, setIsMeetingLeft] = useState(false);

  const [opensuccess ,setOpensuccess] = useState(false);
  const [sessioninfo,setSessioninfo] = useState(null);

  const isMobile = window.matchMedia(
    "only screen and (max-width: 768px)"
  ).matches;

   useEffect(() => {
    console.log("state From PRevious page ",datainfo);
    if(!datainfo){
       navigate(Links.MYDOCTORWALLET);
    }
    
  }, [datainfo]);

  const closeSuccess = () =>{
    setOpensuccess(false);
    //navigate(Links.MYDASHBOARD);
  }

  const openSuccess = (data) =>{
    setOpensuccess(true);
  }

const markcompleteOnLeave = () =>{
    let values = {
            'meetingId': meetingId,
            'expert_id':user_id,
           } ; // declaring array
        fetch(`${API_ENDPOINT}/expert_mark_session_end`,{
          method: 'POST',
          headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json', 
            'Authorization': 'Bearer '+access_token, 
          },
             body: JSON.stringify(values)
          }).then(function (response) {
              return response.json();
          }).then( (result)=> {
            if(result.status == 1){
              
               navigate(Links.SESSIONSUCCESS,
                { state: { data:result.data } }
                );
              //setSessioninfo(result.data);
              //openSuccess(true);
            }else{

               navigate(Links.MYDOCTORWALLET);
            }
            
             //console.log('mail====== pushNotification',result);
          })
         
          setToken("");
          setMeetingId("");
          setParticipantName("");
          setWebcamOn(false);
          setMicOn(false);
          setMeetingStarted(false);
              
       //navigate(Links.DOCTORS);
  }


  /*useEffect(() => {
     var pusher = new Pusher('60a17a016b93f0924a12', {
      cluster: 'ap2'
    });

     var channel = pusher.subscribe('my-channel');
      channel.bind('my-event', function(data) {
        console.log("RESPOSNE",data);
        //alert(JSON.stringify(data));
        if(data.meetingId != ''){
          if(!isMeetingStarted){
            alert(JSON.stringify(data));
          }
        }
      });

    if (isMobile) {
      window.onbeforeunload = () => {
        return "Are you sure you want to exit?";
      };
    }
  }, [isMobile]);
  */
  return (
    <>
      {isMeetingStarted ? (
        <MeetingAppProvider
          selectedMic={selectedMic}
          selectedWebcam={selectedWebcam}
          initialMicOn={micOn}
          initialWebcamOn={webcamOn}
        >
          <MeetingProvider
            config={{
              meetingId,
              micEnabled: micOn,
              webcamEnabled: webcamOn,
              name: participantName ? participantName : "TestUser",
              mode: meetingMode,
              multiStream: true,
            }}
            token={token}
            reinitialiseMeetingOnConfigChange={true}
            joinWithoutUserInteraction={true}
          >
            <MeetingContainer
              onMeetingLeave={() => {

                markcompleteOnLeave();
               
                
              }}
              setIsMeetingLeft={setIsMeetingLeft}
              selectedMic={selectedMic}
              selectedWebcam={selectedWebcam}
              selectWebcamDeviceId={selectWebcamDeviceId}
              setSelectWebcamDeviceId={setSelectWebcamDeviceId}
              selectMicDeviceId={selectMicDeviceId}
              setSelectMicDeviceId={setSelectMicDeviceId}
              micEnabled={micOn}
              webcamEnabled={webcamOn}
            />
          </MeetingProvider>
        </MeetingAppProvider>
      ) : isMeetingLeft ? (
        <LeaveScreen setIsMeetingLeft={setIsMeetingLeft} />
      ) : (
        <AcceptScreen
          participantName={participantName}
          setParticipantName={setParticipantName}
          setMeetingId={setMeetingId}
          setToken={setToken}
          setMicOn={setMicOn}
          micEnabled={micOn}
          webcamEnabled={webcamOn}
          setSelectedMic={setSelectedMic}
          setSelectedWebcam={setSelectedWebcam}
          setWebcamOn={setWebcamOn}
          onClickStartMeeting={() => {
            setMeetingStarted(true);
          }}
          startMeeting={isMeetingStarted}
          setIsMeetingLeft={setIsMeetingLeft}
          meetingMode={meetingMode}
          setMeetingMode={setMeetingMode}
          datainfo={datainfo}
        />
      )}

      <ModalView open={opensuccess} onClose={closeSuccess}>
      </ModalView>
    </>
  );
};

export default AcceptCall;
