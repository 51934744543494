import React from 'react';


const StickerCard = (props) => {
  const {
  titleTransKey,
  subtitleTransKey,
  icon,
  iconBgStyle,
  price,
  indicator,
  indicatorText,
  note,
  link,
  linkText,
} = props;
 
  return (
    <div className="flex h-full w-full flex-col rounded bg-white shadow p-2 ">
      <div className="relative mb-auto flex w-full justify-center items-center pb-1 flex-col">
        <div className="absolute px-4 py-4 mb-20 left-0 top-0 bg-secondary-color -mt-8 drop-shadow-md flex justify-center items-center">{icon}</div>
        {/*<div
          className="ms-3 flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-gray-200"
          style={iconBgStyle}
        >
          {icon}
        </div> */}
      </div>
       <div className="flex w-full flex-col mt-6 justify-start items-end ">
          <span className="mb-1 text-base text-gray-400  ">
            {titleTransKey}
          </span>
          {link && (
          <a
            className="text-2xl font-semibold  no-underline " 
            href={link}
            target="_blank"
            rel="noreferrer"
          >
            {linkText}
          </a>
        )}
      </div>
      
     
    </div>
  );
};

export default StickerCard;
