import React, { useState,createRef,useEffect ,useRef } from 'react';
//import Cropper from 'cropperjs';
import Cropper, { ReactCropperElement } from "react-cropper";
import {API_ENDPOINT, toast_anim , pdfdoconly , BACKEND_URL , APP_URL} from 'components/config/Common'
import axios from 'axios';  

import { AiOutlineEdit } from "react-icons/ai";
import 'cropperjs/dist/cropper.css';
import ModalView from '_utils/ModalView';
import ImageUpload from 'components/ui/imageupload';

const ImageProfileDisplay = () => {
  const [image, setImage] = useState(localStorage.getItem('profile_image'));
  const [image_data ,setImage_data] = useState(null);
  const [cropper, setCropper] = useState(null);
  const cropperRef = createRef();
  const [access_token, setAccess_token] = useState(localStorage.getItem('access_token'))
  const [loading,setLoading] = useState(false);
  const fileRef = useRef( null );
  const [openimageupload, setOpenimageupload] = useState(false);


  useEffect(() => {
    getProfileImage();
  },[])
   
  const handleFileInputChange = (e) => {
    e.preventDefault();
    /*const fileReader = new FileReader();
    fileReader.onload = () => {
      console.log("OON file Load",fileReader.result);
      setImage(fileReader.result);
    };
    fileReader.readAsDataURL(e.target.files[0]);
    */
       let files;
      if (e.dataTransfer) {
        files = e.dataTransfer.files;
      } else if (e.target) {
        files = e.target.files;
      }
      const reader = new FileReader();
      reader.onload = () => {
        setImage_data(reader.result);
        setOpenimageupload(true);
      };
      reader.readAsDataURL(files[0]);
      
  };


  const getProfileImage = () =>{

    
    axios.get(`${API_ENDPOINT}/get_expert_avatar`,{
         headers: {
            'Content-Type': 'multipart/form-data',
              'Authorization': 'Bearer '+access_token, 
           }
        }).then( (result)=> {
           console.log('data=====',result.data)
           console.log('IN UPDATE',result?.data?.data);
           setLoading(false)
           console.log('data=====',result)
          if(result.data.status === 1){
            setImage(result?.data?.data?.profile_image);
            localStorage.setItem('profile_image',result?.data?.data?.profile_image);
          }
        })

  }

const onBtnClick = () => {
    /*Collecting node-element and performing click*/
    fileRef.current.click();
  }

const closeImageupload =() =>{
  setOpenimageupload(false);
  getProfileImage();
}
  return (
    <div className="flex justify-center mt-4">
      
    
       <div className="relative h-40 overflow-hidden shadow rounded-full w-40 h-auto align-middle border-none flex ">
          {image ? (<img src={`${BACKEND_URL}${image}`} className="object-cover w-full" />)
          :
          (<img src={`${APP_URL}/img/Doctor-placeholder-female.jpeg`}  className="object-cover w-full"  />)
          }
        <div className="absolute bottom-0  text-center bg-neutral-700 text-white w-40 h-8 font-semibold cursor-pointer " onClick={()=> onBtnClick()}>
          <div className="flex justify-center">
            <AiOutlineEdit size={20} /> 
          </div>
           <input type="file" onChange={handleFileInputChange} className="invisible" ref={fileRef} />
        </div>
       </div>
      
        <ModalView open={openimageupload} onClose={closeImageupload}>
        <ImageUpload closeImageupload={closeImageupload} image_data={image_data} />
        </ModalView>
    </div>
  );
};

export default ImageProfileDisplay;