import { useState , useEffect} from 'react'
import { RadioGroup } from '@headlessui/react'
import axios from 'axios';  
import {API_ENDPOINT, toast_anim , toast_anim_error} from 'components/config/Common'

const plans = [
  {
    name: 'Online',
    id:'online',
  },
  {
    name: 'Offline',
    id:'offline',
  },
  {
    name: 'Busy',
    id:'busy',
  },
   {
    name: 'On Another Call',
    id:'on another call',
  },
]

export default function ExpertStatusRadio(props) {
  const [selected, setSelected] = useState(plans[0]);
  const [access_token, setAccess_token] = useState(localStorage.getItem('access_token'))
  const [user_type, setUser_type] = useState(localStorage.getItem('user_type'))
  const [avaliable_status, setAvaliable_status] = useState('');

   useEffect(() => {
    getAvailStatus();
   },[]); 
 

  const getAvailStatus = () => {
    //console.log(user_id)

    fetch(`${API_ENDPOINT}/get_availability_status`,{
      method: 'GET',
      headers : {        
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${access_token}`, 
      }
    }).then(function (response) {
        return response.json();
    }).then( (result)=> {
      console.log('products======',result.data)
      if(result.status === 1){
         console.log('avail status',result.data?.availability_status);
         setAvaliable_status(result.data?.availability_status);

         let val = plans?.findIndex((value,key)=>{
            if(value.id == result.data?.availability_status){
              return key;
            }
        });

         /*let val={
          id:result.data?.availability_status,
          name:result.data?.availability_status,
         };
         */
         console.log('avail status val',val);
         setSelected(plans[val]);
      }else{
        //navigate(Links.HOME);
      }
    })
  }

  const changeStatus = (val) =>{

    //console.log("slected radion ",val);
    setSelected(val);
    let input = {
      status:val?.id,
    }
    axios.post(`${API_ENDPOINT}/set_availability_status`,input,{
         headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer '+access_token,
         }
      }).then( (result)=> {
         console.log('data=====',result.data)
         //setLoading(false)
         console.log('data=====',result)
      if(result.data.status == 1){
        toast_anim(result.data.message);
        props.closeCalling();
      }else if(result.data.status == 0){
        toast_anim_error(result.data.message);
      }
      })

  }
  
  return (
    <div className="w-full px-4 py-16 mt-4 mb-4">
      <div className="flex items-center justify-between px-5 mb-4">
            <h1 className="text-lg text-heading sm:text-xl dark:text-body">
            Choose Your Current Online Status
            </h1>
         </div>
      <div className="mx-auto w-full max-w-md">
        <RadioGroup value={selected} onChange={(val)=>changeStatus(val)}>
          <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
          <div className="space-y-2 my-4">
            {plans.map((plan) => (
              <RadioGroup.Option
                key={plan.name}
                value={plan}
                className={({ active, checked }) =>
                  `${
                    active
                      ? 'ring-2 ring-white ring-opacity-60 ring-offset-2 ring-offset-sky-300'
                      : ''
                  }
                  ${
                    checked ? 'bg-accent bg-opacity-75 text-white' : 'bg-gray-300'
                  }
                    relative flex cursor-pointer rounded-lg px-5 py-4 my-2 shadow-md focus:outline-none`
                }
              >
                {({ active, checked }) => (
                  <>
                    <div className="flex w-full items-center justify-between">
                      <div className="flex items-center">
                        <div className="text-sm">
                          <RadioGroup.Label
                            as="p"
                            className={`font-medium  ${
                              checked ? 'text-white' : 'text-gray-900'
                            }`}
                          >
                            {plan.name}
                          </RadioGroup.Label>
                          <RadioGroup.Description
                            as="span"
                            className={`inline ${
                              checked ? 'text-sky-100' : 'text-gray-500'
                            }`}
                          >
                           
                          </RadioGroup.Description>
                        </div>
                      </div>
                      {checked && (
                        <div className="shrink-0 text-white">
                          <CheckIcon className="h-6 w-6" />
                        </div>
                      )}
                    </div>
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
    </div>
  )
}

function CheckIcon(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
      <path
        d="M7 13l3 3 7-7"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
