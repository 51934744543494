
import React from 'react';
import {
   APP_URL, 
} from 'components/config/Common'
const SuccessIcon = () =>  {
    
    return (
      <><img src={`${APP_URL}/img/animation_success.gif`} alt="Success Message" className="w-1/2" /></>
    );
};

export default SuccessIcon;
