import React, {useState, useEffect} from "react";
import Header from "layouts/Header";
import Footer from "layouts/Footer";

import {APP_URL, APP_NAME, API_ENDPOINT, IMG_URL} from 'components/config/Common'

import ScrollPageTop from 'components/ui/ScrollPageTop'
import {Loading} from 'components/custom-icons'
import Seo from 'components/seo/seo'

const About = (props) => {
   const [loading, setLoading] = useState(false)
   const [details, setDetails] = useState(null)
   const [metaTitle, setMetaTitle] = useState('')
	const [metaDesc, setMetaDesc] = useState('')

   useEffect(() => {
      getPageDetails()
   },[]);

   const getPageDetails = () => {
      setLoading(true)
      fetch(`${API_ENDPOINT}/page_by_slug/about`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			//console.log('slider======',result)
         setLoading(false)
			setDetails(result.data)
         setMetaTitle(result.data.meta_title ? result.data.meta_title : result.data.title)
			setMetaDesc(result.data.meta_description ? result.data.meta_description : result.data.content)
		})
   }

   return(
      <ScrollPageTop>
      <Seo 
         title={`${metaTitle} - ${APP_NAME}`}
         desc={metaDesc && metaDesc.substring(0, 200)}
         url={`${APP_URL}/about`}
         image={`${IMG_URL}${details?.image}`}
      />
      <Header hidesearch="true" />
      {
         loading ?
         <div className="w-full h-96 flex items-center justify-center">
            <Loading className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 inline" />
         </div>
         :
         <section className="mx-auto w-full max-w-1920 bg-light py-8 px-4 lg:py-10 lg:px-8 xl:py-14 xl:px-16 2xl:px-20">
         
            <h1 className="mb-4  text-heading sm:mb-5">
            {details?.title}
            </h1>
            {details?.image ?
                <div className="flex items-center justify-center mb-5">
                  <img src={`${IMG_URL}${details?.image}`} />
               </div>
               :
               null
            }
           
            
            {<div className="leading-loose text-body-dark" dangerouslySetInnerHTML={{__html:details?.content}} />}
            
         </section>
      }
      <Footer />
      </ScrollPageTop>
   )
}

export default About