import { useState, useEffect } from 'react'


import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import classNames from 'classnames';
import {useAuth} from 'contexts/provider/AuthProvider';
import ImageProfileDisplay from "components/ui/imageprofiledisplay";

import {SideMenu} from './SideMenu'

const CustomerSideBar = (props) => {
   const location = useLocation();
   const [user_type,setUser_type] =useState(localStorage.getItem('user_type'));

   const [sideMenu, SetSideMenu] = useState([])
   const [isLogin, setIsLogin] = useState(false)
   const navigate = useNavigate();
   const { logout } = useAuth();

   useEffect(() => {
      //console.log('location.pathname', sm)
      SetSideMenu(SideMenu)
      
    },[]);

   console.log("usertype sidebar",user_type);

    const logoutUser = () => {
      //localStorage.clear();
      let user_name = localStorage.getItem("user_name")
      localStorage.removeItem("access_token")
      localStorage.removeItem("user_type")
      localStorage.removeItem("user_id")
      localStorage.removeItem("user_name")
      setIsLogin(false)
      logout()
      navigate(
         '/logout',
         {
            state: { user_name: user_name },
            replace: true 
         },
      );

   }

   return (
      <aside className="hidden shrink-0 ltr:mr-8 rtl:ml-8 lg:block lg:w-64">
         <div className="overflow-hidden rounded border border-border-200 bg-light dark:bg-gray-700 dark:border-none">
             {user_type == 'expert' ?
                 <ImageProfileDisplay /> 
                 :
                 null
             }
            <ul className="py-7">
            {
            sideMenu && sideMenu.map((item, index) => (
               <li className="py-1" key={`side-menu-${index}`}>
                  <Link to={item.link}
                     className={classNames(
                        'block border-l-4 border-transparent py-2 px-5 text-heading transition-colors hover:text-accent focus:text-accent dark:text-zinc-400 dark:hover:text-accent dark:focus:text-accent',
                        {
                        '!border-accent text-accent dark:text-accent': location.pathname === item.link,
                        }
                     )}
                     >
                     {item.label}
                  </Link>                  
               </li>
               ))
            }
            </ul>
            <ul className="border-t border-border-200 bg-light py-4 dark:bg-gray-700 dark:border-gray-600">
               <li className="block py-2 px-11 ">
                  <button className={classNames('text-heading transition-colors hover:text-accent focus:text-accent dark:text-zinc-400 dark:hover:text-accent dark:focus:text-accent')} onClick={logoutUser}>
                  Logout
                  </button>
               </li>
            </ul>
            {/* End of bottom part menu */}   
         </div>
      </aside>
   )
}
export default CustomerSideBar;