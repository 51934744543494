import React ,{useState,useEffect} from "react";
import { Link } from "react-router-dom";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import {API_ENDPOINT, BACKEND_URL ,APP_URL, APP_NAME, IMG_URL, amount_format,discount, LIMIT, replaceWithBr,
 showRating , blogs} from '../components/config/Common'
 import {Loading} from "components/custom-icons";

import AOS from 'aos';
import 'aos/dist/aos.css';
import ScrollPageTop from '../components/ui/ScrollPageTop'

import { BiRupee ,BiMask ,BiTimer } from "react-icons/bi";
import { TfiShield } from "react-icons/tfi";


import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import {Links} from '../routes/PathLink'
import InfiniteScroll from "react-infinite-scroll-component";

// import Swiper core and required modules
import { Navigation, Pagination, Thumbs, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { LazyLoadImage, LazyLoadComponent } from 'react-lazy-load-image-component';
import HomeReviews from "components/reviews/HomeReviews";
import BlogPostCard from 'components/cards/BlogPostCard';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/thumbs';




const Home = ()=> {

	const [doctors,setDoctors]= useState([]);
	const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)
    const [totalRec, setTotalRec] = useState(0)
    const [process, setProcess] = useState(false);


	useEffect(() => {
		getDoctors(1);
	},[]);
	

	const getDoctors = (pg) => {
      
      let paginate = `page=${pg}&limit=${LIMIT}`;
      let search = ''
      
		fetch(`${API_ENDPOINT}/get_experts_front?${paginate}`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			console.log('cat products======',result.data)
			//setProducts(result.data)
         if(pg === 1){
            setDoctors(result.data)
         }else{
            setDoctors([...doctors, ...result.data])
         }
         let nextPage = page + 1;
         setPage(nextPage)
         setTotalRec(result.total)

         if(result.data.length < LIMIT){
            setHasMore(false)
         }else{
            setHasMore(true)
         }

         setProcess(true)
         
         /* let p = document.getElementById('product-list')
         p.scrollIntoView( { behavior: 'smooth', block: 'start' } ); */
		})
	}

	const fetchData = () =>{
	}
		const siteMetadata = {
			url: `${APP_URL}`,
			title:  `${APP_NAME}`,
			description:  `${APP_NAME}`,
			image: `./img/home-bg.jpg`,
		 }
	
		
	return (
		<ScrollPageTop>
		<ReactSEOMetaTags
			render={(el: React.ReactNode) => <Helmet>{el}</Helmet>}
			website={{ ...siteMetadata }}
			breadcrumb={[
        		{ title: 'Home', url: `${APP_URL}` },
			]}
		/>
		<Header hidesearch={true}/>

					<div className="flex bg-accent/[.4] bg-gradient-to-bl	px-4  align-center">
		            <div className="bg-cover w-5/6 md:w-1/2 bg-center h-full"  >
		               <div className="px-4 py-3">
		               <div className="flex w-full items-center lg:w-auto">
		                    {/*<Logo className={'ltr:ml-0 rtl:mr-0 text-white px-2 py-2 '} />*/}
		                </div>
		                <h2 className="text-5xl flex justify-center align-center mt-10 leading-tight	font-light		">
		                Talk to Top Therapists and Mental Health Experts
		                </h2>
		                <p className=" flex justify-center align-center mt-10">
		                  Sometimes all you need to feel better is someone who really understands. Connect with our experts for 24/7 professional help at an affordable price.
		                </p>
		                </div>
		            </div>
		              <div className="bg-no-repeat bg-[length:auto 90%] w-1/2 bg-center h-full rounded-md" style={{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url(${APP_URL}/img/624735db935b2d4d194c143d_hero-devices.jpeg)`,backgroundSize:'auto 90%'}} >
		              		<div className="flex h-64	md:h-96"></div>
		            	</div>
            	</div>

		<main className="block w-full lg:mt-6 xl:overflow-hidden ltr:xl:pl-5 ltr:xl:pr-5 rtl:xl:pr-5 rtl:xl:pl-5">
					 
					<div name="grid" className="px-4 xl:px-0">
						<div className="w-full py-6">
						{/* section 1 */}
							<div className="flex justify-center align-center">
								<div className="w-2/6 flex justify-center align-center flex-col">
								<div className="flex justify-center align-center"><TfiShield size={40} /></div>
								<div className="flex justify-center align-center mt-2 mb-6">PRIVATE & CONFIDENTIAL</div>
								</div>
								<div className="w-2/6 flex justify-center align-center flex-col">
								<div className="flex justify-center align-center"><BiMask size={40} /></div>
								<div className="flex justify-center align-center mt-2 mb-6">ANONYMOUS DISCUSSIONS</div>
								</div>
								<div className="w-2/6 flex justify-center align-center flex-col">
								<div className="flex justify-center align-center"><BiTimer size={40} /></div>
								<div className="flex justify-center align-center mt-2 mb-6">24X7 ROUND THE CLOCK SUPPORT</div>
								</div>
							</div>
							{/* End section 1*/}
						</div>
					</div>
					
				</main>
				
				<div className="flex w-full pt-10 pb-10 bg-light justify-center">
					<div className="flex flex-col items-center justify-center">
						<h1 className="mb-4">How it works</h1>
						<div className="grid grid-cols-1 md:grid-cols-3 gap-10 ml-10 mr-10">
							<div className="flex flex-col ">
								<div className="flex items-center">
									<div className=" flex justify-center text-3xl text-white w-10 h-10 bg-secondary-color/[0.5] rounded-full">1</div>
									<div className="ml-2 text-xl">Find Online Experts</div>
								</div>
								<div className="mt-2 ">Take your pick from our panel of mental health & self-improvement experts</div>
								<img src={`${APP_URL}/img/findexpert.jpg`} />
							</div>
							<div className="flex flex-col">
								<div className="flex items-center">
									<div className="flex justify-center text-3xl text-white w-10 h-10 bg-secondary-color/[0.5] rounded-full">2</div>
									<div className="ml-2 text-xl">Call Expert</div>
								</div>
								<div className="mt-2 ">Initiate an instant chat or call with the expert for a price as low as ₹3/min</div>
								<img src={`${APP_URL}/img/callexpert.jpg`} />
							</div>
							<div className="flex flex-col">
								<div className="flex items-center">
									<div className="flex justify-center text-3xl text-white w-10 h-10 bg-secondary-color/[0.5] rounded-full">3</div>
									<div className="ml-2 text-xl">Unleash a Better You</div>
								</div>
								<div className="mt-2 ">Share your thoughts, get support, and feel better</div>
								<img src={`${APP_URL}/img/discusswithexpert.jpg`} />

							</div>
						</div>
					</div>		
				</div>
				<HomeReviews />


					<div className="flex w-full pt-10 pb-2 bg-light justify-center">
					<div className="flex flex-col items-center justify-center">
						<h1 className=" mb-4">Our Latest Articles</h1>
						<div className="grid grid-cols-1 md:grid-cols-3 gap-10 ml-10 mr-10">
							{blogs  && blogs.map((item, key) => (
								<>
								<BlogPostCard  item={item} key={`blog-post-${item.id}`}   />
								</>
							))}
						</div>
					</div>		
				</div>

					<div className="bg-light flex justify-center align-center w-full mb-10 pb-4">
				<Link to={Links.BLOG} target="_blank"  class="relative inline-flex items-center justify-start py-3 pl-4 pr-12 overflow-hidden font-semibold text-accent uppercase transition-all duration-150 ease-in-out rounded hover:pl-10 hover:pr-6 bg-gray-50 group">
				<span class="absolute bottom-0 left-0 w-full h-1 transition-all duration-150 ease-in-out bg-accent group-hover:h-full"></span>
				<span class="absolute right-0 pr-4 duration-200 ease-out group-hover:translate-x-12">
				<svg class="w-5 h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
				</span>
				<span class="absolute left-0 pl-2.5 -translate-x-12 group-hover:translate-x-0 ease-out duration-200">
				<svg class="w-5 h-5 text-green-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
				</span>
				<span class="relative w-full text-left transition-colors duration-200 ease-in-out group-hover:text-white">View All Articles</span>
				</Link>
			</div>

		<Footer />
		</ScrollPageTop>
		);
	
};


export default Home;