import React, {useState, useEffect} from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import {
   Control,
   FieldErrors,
   useForm,
   useFormState,
   useWatch,
   Controller
} from 'react-hook-form';
import {
   API_ENDPOINT, 
   random_str, 
   toast_anim_error, 
   register_fee_hospital, 
   register_valid , 
   renew_per,
   RAZOR_KEY, 
   loadScript 
} from 'components/config/Common'

import { yupResolver } from '@hookform/resolvers/yup';
import Input from 'components/ui/input.jsx'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import Button from 'components/ui/Button.tsx'
import {useAuth} from 'contexts/provider/AuthProvider'
import SelectInput from 'components/ui/select-input.jsx'
import Label from 'components/ui/label.tsx'
import {deviceDetect} from "react-device-detect"
import Checkbox from 'components/ui/checkbox/checkbox.tsx'
import axios from 'axios';  



const SignupSchema = Yup.object().shape({
   state_id:Yup.object().nullable().required('State is required.'),
   city_id:Yup.object().nullable().required('City is required.'),
   phone_no: Yup.string()
      .required('You must need to provide your phone number.')
      .min(12, 'Invalid phone number.'),
   hospital_reg: Yup.string()
      .required('You must need to provide your Hospital Registration Number.'),
    agree_to_pay:Yup.bool().oneOf([true], 'Field must be checked.'),
   
});
const defaultValues = {  
   phone_no:'',
   state_id:'',
   city_id:'',
   hospital_reg:'',
   agree_to_pay:true
 };

const HospitalSocialLogin = (props) => {

   const [loading, setLoading] = useState(false);
   const [states, setStates] = useState(null);
   const [cities, setCities] = useState(false);
   const { login } = useAuth();

   const { register, handleSubmit , control, reset, setValue, setError, formState: { errors },} = useForm({
      defaultValues: defaultValues,
      resolver: yupResolver(SignupSchema),
   });

   useEffect(() => {
      //console.log('props',props)
      getState()
      reset(defaultValues)
   },[])
   
   const getState = () => {
      fetch(`${API_ENDPOINT}/get_states`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			//console.log('states======',result)
			setStates(result.data)			
		})
   }

   const getCitiesByState = (state_id) => {
      fetch(`${API_ENDPOINT}/get_cities_by_state/${state_id}`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			//console.log('cities======',result)
			setCities(result.data)			
		})
   }

   const handleSelect = (val,name) => {  
      //console.log('cat val======',val,name.name)
      setError(name.name, null)
      setValue(name.name, val, true);
      if(name.name === 'state_id'){
         //setModels(null)
         getCitiesByState(val.id)
         setValue('city_id', null, true);
      }
   }

    const hospitalSubscription = async (values) => {
      /* console.log(values)
      return */

      const res = await loadScript(
          "https://checkout.razorpay.com/v1/checkout.js"
      );

      if (!res) {
         //alert("Razorpay SDK failed to load. Are you online?");
         toast_anim_error('Razorpay failed to load. Are you online?')
         return false;
      }

      
      const validate =  await axios.post(`${API_ENDPOINT}/validate_social_hospital`,values)

      if(validate.data.status === 0){
         Object.entries(validate.data.message).forEach((item,ky) =>{
            if(item[0] === 'email'){                    
               setError('email', { type: 'custom', message: item[1] });
            }
            if(item[0] === 'phone_no'){
               setError('phone_no', { type: 'custom', message: item[1] });
            }
            if(item[0] === 'hospital_reg'){
               setError('hospital_reg', { type: 'custom', message: 'You must need to provide Hospital/Diagnostic Registration Number.' });
            }
            
         })
         return false
      }

      //console.log(validate.data)

      

      let invoice = `DS_${random_str(10)}`;

      fetch(`${API_ENDPOINT}/razor_payment_order`,{
         method: 'POST',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json',
         },
         body: JSON.stringify({
            amount:register_fee_hospital*100,
            invoice:invoice
         })
      }).then(function (response) {
         return response.json();
      }).then( (result)=> {
         console.log('invoice=====',result)
         var options = {
            "key": RAZOR_KEY, // Enter the Key ID generated from the Dashboard
            "amount": result.data.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            "currency": "INR",
            "name": "CTMRI",
            "description": "Hospital Subscription",
            "image": "https://i.imgur.com/3g7nmJC.jpg",
            "order_id": result.data.order_id,
            "handler": function (response){
               console.log('rzp1 response', response)
               const payment = {
                  razorpay_payment_id: response.razorpay_payment_id,
                  razorpay_order_id: response.razorpay_order_id,
                  razorpay_signature: response.razorpay_signature,
                  amount: result.data.amount,
                  valid_upto:register_valid,

               };
               //console.log('razorpay data', data)
               const formData = {...values, payment}
               if(response.razorpay_payment_id){
                  onSubmit(formData)            
               }
            },
            "prefill": {
               "name": values.name,
               "email": values.email,
               "contact": values.phone_no
            },
            "theme": {
               "color": "#009f7f"
            }
         };

         const rzp1 = new window.Razorpay(options);
         /* rzp1.on('payment.failed', function (response){
            alert(response.error.code);
            alert(response.error.description);
            alert(response.error.source);
            alert(response.error.step);
            alert(response.error.reason);
            alert(response.error.metadata.order_id);
            alert(response.error.metadata.payment_id);
         }); */
         rzp1.open();
      })
   }


   const onSubmit = (values) => {
      //console.log('props social',props.social_data)
      
      let input = values;
      input = {
         ...input, 
         device:deviceDetect(), 
         name:props.social_data.name,
         email:props.social_data.email,
         provider:props.social_data.provider,
         password:random_str(10),
         email_verified:true,
      }
      //console.log('on submit======',input)
      fetch(`${API_ENDPOINT}/customer_register`,{
         method: 'POST',
         headers : {        
             'Content-Type': 'application/json'
         },
         body: JSON.stringify(input)
     }).then(function (response) {
         return response.json();
     }).then( (result)=> {
         setLoading(false)
         
         if(result.status === 1){
             localStorage.setItem('access_token',result.access_token);
             localStorage.setItem('user_type',result.user_type);
             localStorage.setItem('user_id',result.user_id);
             localStorage.setItem('user_name',result.user_name);
             localStorage.setItem('email',result.email);
             props.setLogin()
             login({access_token:result.access_token, user_name:result.user_name})
             
             //call from AMC ticket
             if(props.buyTicket){
                 props.buyTicket();
             }
         }
         /* if(result.status === 0){
             Object.entries(result.message).forEach((item,ky) =>{
                 if(item[0] === 'email'){
                     setErrors({email:item[1]})
                 }
                 if(item[0] === 'phone_no'){
                     setErrors({phone_no:item[1]})
                 }
             })
         } */
         //setErrors({phone_no:result.message})
     })
   }

   return(
      <>
         <div className="flex justify-center">
            <h1 className="text-2xl font-black text-accent mb-5">Doctor/Hospital Register</h1>
         </div> 
         <form onSubmit={handleSubmit(hospitalSubscription)}>
            <Input                
               label={`Hospital Registration Number`}
               {...register('hospital_reg')}
               variant="outline"
               className="mb-5"
               error={errors.hospital_reg?.message}
               required
            />
            <div className="mb-5">
               <Label>State <span className="text-red-500"> *</span></Label>
               <SelectInput
                  name="state_id"                            
                  control={control}
                  getOptionLabel={(option: any) => option.name}
                  getOptionValue={(option: any) => option.id}                        
                  options={states}
                  onChange={handleSelect}
               />
               <p className="my-2 text-xs text-start text-red-500">{errors.state_id?.message}</p>
            </div>
            <div className="mb-5">
               <Label>City <span className="text-red-500"> *</span></Label>
               <SelectInput
                  name="city_id"                            
                  control={control}
                  getOptionLabel={(option: any) => option.city}
                  getOptionValue={(option: any) => option.id}                        
                  options={cities}
                  onChange={handleSelect}
               />
               <p className="my-2 text-xs text-start text-red-500">{errors.city_id?.message}</p>
            </div>
            <div className="mb-5">
               <label htmlFor="phone_no" className="block mb-3 text-sm font-semibold leading-none text-body-dark">Phone number <span className="text-red-500">*</span></label>
               <Controller
                  name="phone_no"
                  control={control}
                  render={({ field }) => (
                  <PhoneInput
                     country="in"
                     disableDropdown
                     countryCodeEditable={false}
                     inputClass="!p-0 ltr:!pr-4 rtl:!pl-4 ltr:!pl-14 rtl:!pr-14 !flex !items-center !w-full !appearance-none !transition !duration-300 !ease-in-out !text-heading !text-sm focus:!outline-none focus:!ring-0 !border !border-border-base !rounded focus:!border-accent !h-12"
                     dropdownClass="focus:!ring-0 !border !border-border-base !shadow-350"
                     {...field}
                  />
                  )}
               />
               <p className="my-2 text-xs text-start text-red-500">{errors.phone_no?.message}</p>
            </div>
             <div className="mb-5">
                  <Checkbox 
                       name='agree_to_pay'
                       {...register('agree_to_pay')}
                       label={`Rs.${register_fee_hospital}/- Registration fee, Charges will be applicable for ${register_valid} months from the date of registration. Fee will increase by ${renew_per}% when renew.`}
                       theme='primary'
                       error={errors.agree_to_pay?.message}
                    />
               </div>
            <div>
                  <Button 
                     className="w-full"
                     loading={loading}
                  >
                  Register
                  </Button>
               </div>
         </form>
      </>
   )
}

export default HospitalSocialLogin