import React, {useState, useEffect , useRef} from "react";
import { Link } from "react-router-dom";

import LeftMenuLayout from 'layouts/LeftMenuLayout';

import classNames from 'classnames';
import * as Yup from 'yup';
import {API_ENDPOINT, toast_anim , toast_anim_error , LIMIT} from 'components/config/Common'
import {Loading} from "components/custom-icons";

import axios from 'axios';  
import {APP_URL, APP_NAME , IMG_URL ,amount_format ,BACKEND_URL } from 'components/config/Common'

import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import { toast } from 'react-toastify';
import ScrollPageTop from 'components/ui/ScrollPageTop'

import { yupResolver } from '@hookform/resolvers/yup';
import Input from 'components/ui/input.jsx'
import Label from 'components/ui/label.tsx'
import Button from 'components/ui/Button.tsx'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TextArea from 'components/ui/text-area';
import Table from 'rc-table';
import Pagination from '_utils/pagination.jsx'
import * as moment from 'moment'

import {
	Control,
	FieldErrors,
	useForm,
	useFormState,
	useWatch,
	Controller
 } from 'react-hook-form';


const UserTodaysAppointment = (props) => {
	const {info} = props;
	const [access_token, setAccess_token] = useState(localStorage.getItem('access_token'))
	const [user_type, setUser_type] = useState(localStorage.getItem('user_type'))
	const [user_me, setUser_me] = useState(null);
	const [loading, setLoading] = useState(false);
	const [loading_social, setLoading_social] = useState(false);
   const [enabled, setEnabled] = useState(false)
   const [hasmore ,setHasMore] = useState(false);
  	const [appointments,setAppointments] = useState([]);
  	const [totalRec,setTotalRec] = useState(0);
  	const [process ,setProcess] = useState(false);


  	const [currentPage, setCurrentPage] = useState(1);
  	useEffect(() => {
      getTodaysAppointment(currentPage);
   },[access_token])


   let handleColor = (time) => {
    return time.getHours() > 12 ? "text-success" : "text-error";
   };


	const getTodaysAppointment = (pg) => {
      
		//setProcess(true);
      fetch(`${API_ENDPOINT}/get_today_user_appoint?pg=${pg}&limit=${LIMIT}`,{
         method: 'GET',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json', 
            'Authorization': 'Bearer '+access_token, 
         }
      }).then(function (response) {
            return response.json();
      }).then( (result)=> {
         console.log('cat Doctors======',result)
         //setProducts(result.data)
         if(pg === 1){
            setAppointments(result.data)
         }else{
            setAppointments([...appointments, ...result.data])
         }
         //let nextPage = page + 1;
         //setPage(nextPage)
        // setTotalRec(result.total)
         //setProcess(false);
         if(result?.status == 1 && result?.data?.length < LIMIT){
            setHasMore(false)
         }else{
            setHasMore(true)
         }

          setProcess(true)
         
         /* let p = document.getElementById('product-list')
         p.scrollIntoView( { behavior: 'smooth', block: 'start' } ); */
      })
   }


	const columns = [
       {
         title: 'User',
         dataIndex: '',
         key: 'pivot',
         align: 'left',
         width: 100,
         render: (record,user) => {
         	return (<div>
         				<p className="text-sm text-body capitalize">{record?.user?.user_name}</p>
         				<p className="text-sm text-body capitalize">{record?.user?.gender}</p>
         				<div className="text-gray-800 text-l">{moment(record?.appoint_date).format('DD/MM/YYYY hh:mm A')}</div>
         			</div>
         		);
         },
       },
       {
         title: 'Action',
         dataIndex: '',
         align: 'right',
         fixed: 'right',
         className:'bg-gray-50',
         width: 30,
         render:renderReview
       }
    ];

	const onPagination = (page, limit) => {
      //console.log('page===',page, limit) 
      
      /*getSellerProducts(page)
      
		*/
		setCurrentPage(page)
      window.scrollTo({
         top: 0,
         left: 0,
         behavior: "smooth"
      });
   }

	return (<div className="w-full">
				{
            !process ?
            <div className="w-full h-96 flex items-center justify-center">
               <Loading className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 inline" />
            </div>
            :
            <div style={{overflow: 'hidden'}} className={classNames('w-full py-5 bg-light shadow rounded dark:bg-gray-700')}>
               <div className="flex items-center justify-between px-5">
                  <h1 className="text-lg text-heading sm:text-xl dark:text-body">
                  Today's Appointment
                  </h1>
               </div>
               {appointments && 
               appointments.length > 0 ?
               <div className="mt-6">
                  <Table 
                     columns={columns} 
                     data={appointments} 
                     className="orderDetailsTable w-full"
                     rowKey={(record:any) => record.appoint_id}
                     scroll={{ x: 650 }}
                  />
                  <div className="mt-5 flex items-center justify-end">
                  {appointments && 
                  appointments.length > LIMIT &&
                  <Pagination
                     total={totalRec}
                     current={currentPage}
                     pageSize={LIMIT}
                     onChange={onPagination}
                  />
                  }
                  </div>
               </div>
               :
                null
               }
            </div>
            }		
			</div>
   );
};

export default UserTodaysAppointment

 const renderReview = () => {
 }