
import {APP_URL, IMG_URL, amount_format,discount , truncate} from 'components/config/Common'

import { motion as ms } from 'framer-motion';
import {Links} from 'routes/PathLink'
import { Link } from "react-router-dom";
const BlogPostCard = ({
   item
}:props) => {

   return(
      <div className="bg-light drop-shadow-lg rounded-lg  mx-15 my-15 mb-10">
      <Link to={item.link} target="_blank" >

       <div className="px-2 py-2  ">
       <div className="flex w-full">
          <div className="justify-center align-center w-full ">
              {item.img
                  ?
                  <img src={item.img} className="h-auto w-full md:h-[157px] object-cover " alt={item.title}  />
                  :
                  <img src={`${APP_URL}/img/no-image-available.png`} alt={item.title}   />
               }
            </div>
          </div>
          </div>
            <div className="mb-0  text-black bg-gray-200  border-gray-900 flex flex-row flex-wrap items-center justify-between gap-x-8 text-base  text-heading sm:flex-col lg:flex-nowrap">
            <div className="w-full px-2 py-2 h-16  overflow-hidden ">
              <span className="mb-2 block lg:mb-0 lg:inline-block lg:ltr:mr-4 lg:rtl:ml-4 text-xl ">
               {item.title}
              </span>
           </div>
           <div className="px-2 py-2 pb-10 h-36 w-full">{item.content}</div>

          <div>
        </div>
      </div>
      </Link>
    </div>
   )
}

export default BlogPostCard;