import React, {useState, useEffect , useRef} from "react";
import { Link } from "react-router-dom";

import LeftMenuLayout from 'layouts/LeftMenuLayout';

import classNames from 'classnames';
import * as Yup from 'yup';
import {API_ENDPOINT, toast_anim , toast_anim_error} from 'components/config/Common'
import {Loading} from "components/custom-icons";

import axios from 'axios';  
import {APP_URL, APP_NAME , IMG_URL ,amount_format } from 'components/config/Common'

import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import { toast } from 'react-toastify';
import ScrollPageTop from 'components/ui/ScrollPageTop'

import { yupResolver } from '@hookform/resolvers/yup';
import Input from 'components/ui/input.jsx'
import InputWithCurrency from 'components/ui/inputWithCurrency.jsx';
import Label from 'components/ui/label.tsx'
import Button from 'components/ui/Button.tsx'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
	Control,
	FieldErrors,
	useForm,
	useFormState,
	useWatch,
	Controller
 } from 'react-hook-form';

const amountSchema = Yup.object().shape({
	amount: Yup.number()
      .typeError('Amount must be a number')
      .positive('Amount must be positive')
      .required('Amount is required'),
});


let defaultValues = {  
	amount: '',
};

const SetDailyAvalibility = (props) => {
	
	const [access_token, setAccess_token] = useState(localStorage.getItem('access_token'))
	const [user_type, setUser_type] = useState(localStorage.getItem('user_type'))
	const [user_me, setUser_me] = useState(null);
	const [loading, setLoading] = useState(false);
	const [loading_social, setLoading_social] = useState(false);
   const [enabled, setEnabled] = useState(false)
  	const [totalamount,setTotalamount] = useState(0);
  	const [bankdtlist,setBankdtlist]= useState(null);
  	const [startDate, setStartDate] = useState(new Date());
  	const [morningstartDate, setMorningstartDate] = useState();
  	const [afternoonstartDate, setAfternoonstartDate] = useState();
  	const [eveningstartDate, setEveningstartDate] = useState();

  	const [morningendDate, setMorningendDate] = useState();
  	const [afternoonendDate, setAfternoonendDate] = useState();
  	const [eveningendDate, setEveningendDate] = useState();


	const { register, handleSubmit , control, reset, setValue, setError, formState: { errors },} = useForm({
		defaultValues: defaultValues,
		
  	});


	useEffect(() => {
			getProfileDetails();
			//getBankAccountList();
	},[])
	

	


	const getProfileDetails = () => {
		fetch(`${API_ENDPOINT}/expert_me`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': 'Bearer '+access_token, 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			console.log(result)
			setUser_me(result.data)
			let data = result.data

				defaultValues = {charge30_min: data.session_price,
				charge45_min:data.session_price45,
				charge60_min:data.session_price60,
			};
			reset(defaultValues)
		})
	}	

	const onSubmit = (values) => {
		console.log('value', values)
		setLoading(true)		

		let input = values;
     	input = {day:props?.currentweekday,
     				fromTime:morningstartDate,
     				toTime:morningendDate,
     			}
      setLoading(true)
      axios.post(`${API_ENDPOINT}/set_daily_availability`,input,{
         headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer '+access_token, 
         }
      }).then( (result)=> {
         console.log('data=====',result.data)
         setLoading(false)
         console.log('data=====',result)
			if(result.data.status == 1){
				toast_anim(result.data.message);
				props.closeSessionch();
			}else if(result.data.status == 0){
				toast_anim_error(result.data.message);
			}
      })

		
	}

	return (<div className="w-full">
					<div className={classNames('w-full p-5 md:p-8 bg-light dark:bg-gray-700 shadow rounded mb-8')}>
						<p className="text-lg lg:text-xl text-heading capitalize mb-2 dark:text-zinc-400">Set availability for {props?.currentweekday}</p>
						<form onSubmit={handleSubmit(onSubmit)}>
								<div className="flex w-full flex-col">
									<div className="flex">
									<div className="w-1/2">
									 <label for="name" class="mb-3 block text-sm font-semibold leading-none text-body-dark dark:text-zinc-400">Start Time</label>

								 	<DatePicker
								      selected={morningstartDate}
								      onChange={(date) => setMorningstartDate(date)}
								      showTimeSelect
								      showTimeSelectOnly
								      timeIntervals={15}
								      timeCaption="Time"
								      dateFormat="h:mm aa"
								      className="w-10/12 px-4 h-12 flex items-center w-full rounded appearance-none transition duration-300 ease-in-out text-heading text-sm focus:outline-none focus:ring-0 dark:bg-gray-600 dark:border-gray-700 dark:text-zinc-400 border border-border-base focus:border-accent h-12"
								    />
								    </div>
								    <div className="w-1/2">
								    <label for="name" class="mb-3 block text-sm font-semibold leading-none text-body-dark dark:text-zinc-400">End Time</label>

	                         <DatePicker
								      selected={morningendDate}
								      onChange={(date) => setMorningendDate(date)}
								      showTimeSelect
								      showTimeSelectOnly
								      timeIntervals={15}
								      timeCaption="Time"
								      dateFormat="h:mm aa"
								      className="w-10/12	px-4 h-12 flex items-center w-full rounded appearance-none transition duration-300 ease-in-out text-heading text-sm focus:outline-none focus:ring-0 dark:bg-gray-600 dark:border-gray-700 dark:text-zinc-400 border border-border-base focus:border-accent h-12"
								    />
								    </div>
	                        </div>
	                      
	                      </div>
								<div className="flex mt-4">
									<div className="flex  align-center justify-center">
									{
									!loading
									?	
									<button data-variant="normal" className="inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-5 py-0 h-12 ltr:ml-auto rtl:mr-auto">Save</button>
									:
									<Loading className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 ltr:ml-auto" />
									}

									<button data-variant="normal" onClick={props.closeSessionch} className="ml-2 inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-black text-light border border-transparent hover:bg-black-hover px-5 py-0 h-12 ltr:ml-2 rtl:mr-auto">Close</button>

									
									</div>
								</div>
							</form>
					</div>
			</div>
   );
};

export default SetDailyAvalibility