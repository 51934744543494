import React, {useState, useEffect , useRef} from "react";
import { Link } from "react-router-dom";

import LeftMenuLayout from 'layouts/LeftMenuLayout';

import classNames from 'classnames';
import * as Yup from 'yup';
import {API_ENDPOINT, toast_anim } from 'components/config/Common'
import {Loading , WalletPointsIcon} from "components/custom-icons";

import axios from 'axios';  
import {APP_URL, APP_NAME , IMG_URL , LIMIT ,amount_format , EXPERT_LABEL} from 'components/config/Common'

import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import { toast } from 'react-toastify';
import ScrollPageTop from 'components/ui/ScrollPageTop'
import {Links} from 'routes/PathLink'

import { yupResolver } from '@hookform/resolvers/yup';
import Input from 'components/ui/input.jsx'
import Label from 'components/ui/label.tsx'
import Button from 'components/ui/Button.tsx'
import InfiniteScroll from "react-infinite-scroll-component";
import * as moment from 'moment'
import ModalView from '_utils/ModalView'
import WithdrawMoney from "components/wallet/WithdrawMoney";
import Badge from "components/ui/badge/badge";
import NoRecords from 'pages/common/NoRecords';
import { Tab } from '@headlessui/react'
import BankDetailsList from 'pages/wallet/BankDetailsList';

const MyDoctorWallet = () => {

	const [access_token, setAccess_token] = useState(localStorage.getItem('access_token'))
	const [user_type, setUser_type] = useState(localStorage.getItem('user_type'))
	const [user_id , setUser_id] = useState(localStorage.getItem('user_id'));
	const [user_me, setUser_me] = useState(null);
	const [loading, setLoading] = useState(false);
	const [loading_social, setLoading_social] = useState(false);
	const [totalamount,setTotalamount] = useState(0);
	const [transactions,setTransactions]= useState([]);
	const [page, setPage] = useState(1)
   const [totalRec, setTotalRec] = useState(0)
   const [hasMore, setHasMore] = useState(true)
   const [openwithdraw ,setOpenwithdraw] = useState(false);
   const [currentab ,setcurrentab] = useState(0);

	useEffect(() => {
		getWalletTotal();
		getTranscationsLog(1);
	},[])
	

	const siteMetadata = {
		url: `${APP_URL}/profile`,
		title:  `My ${EXPERT_LABEL} wallet - ${APP_NAME}`,
		description: `My ${EXPERT_LABEL} wallet - ${APP_NAME}`,
	}

	const getWalletTotal = () => {
		console.log(access_token)
		fetch(`${API_ENDPOINT}/expert_total_wallet_amount`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': 'Bearer '+access_token, 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			console.log("TOTAL WALLET AMOUNT ",result)
			if(result.status == 1){
				setTotalamount(result.data);
			}
		})
	}	


	const getTranscationsLog = (pg) => {
      
      let paginate = `page=${pg}&limit=${LIMIT}`;
      let search = ''
      
		fetch(`${API_ENDPOINT}/get_expert_wallet_transactions?${paginate}`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
				'Authorization': 'Bearer '+access_token, 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			console.log('cat products======',result.data)
			//setProducts(result.data)
         if(pg === 1){
            setTransactions(result.data)
         }else{
            setTransactions([...transactions, ...result.data])
         }
         let nextPage = page + 1;
         setPage(nextPage)
         setTotalRec(result.total)

         if(result.data.length < LIMIT){
            setHasMore(false)
         }else{
            setHasMore(true)
         }

         //setProcess(true)
         
         /* let p = document.getElementById('product-list')
         p.scrollIntoView( { behavior: 'smooth', block: 'start' } ); */
		})
	}

	const fetchData = () =>{
		getTranscationsLog(page)
	}

	const closeWithdraw = () =>{
		setOpenwithdraw(false);
		getTranscationsLog(1);
		getWalletTotal();
	}

	const openWithdrawModal = () =>{
		setOpenwithdraw(true);
	}

	return (
		<ScrollPageTop>
			<ReactSEOMetaTags
				render={(el: React.ReactNode) => <Helmet>{el}</Helmet>}
				website={{ ...siteMetadata }}
				breadcrumb={[
					{ title: 'Home', url: `${APP_URL}` },
					{ title: 'Profile', url: `${APP_URL}/profile` },
				]}
			/>
         <LeftMenuLayout> 
				<div className="w-full">
					<div className={classNames('w-full p-5 md:p-8 bg-light dark:bg-gray-700 shadow rounded mb-8')}>
						<p className="text-lg lg:text-xl text-heading capitalize mb-5 dark:text-zinc-400">My Wallet</p>
						<div className="flex mb-5 w-full">
							<div className="w-1/2 mb-5 flex">
							<WalletPointsIcon className="w-5 mr-2 text-3xl" /> {amount_format(totalamount)}
							</div>
							<div className="w-1/2 align-end flex">
							 <Button onClick={openWithdrawModal}>WithDraw Money</Button>
							</div>
						</div>


							<div className="w-full  px-2 py-2 sm:px-0">
							<Tab.Group onChange={(index) => {
						        console.log('Changed selected tab to:', index)
						        setcurrentab(index);
						      }}>
						      <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1" >
						        <Tab className={`w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-accent  ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2  ${currentab == 0 ? ' bg-white shadow ': '' }`}>Transactions</Tab>
						        <Tab className={`w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-accent  ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2 ${currentab == 1 ? ' bg-white shadow ': '' }`}>Bank Details</Tab>
						      </Tab.List>
						      <Tab.Panels>
						        <Tab.Panel className="rounded-xl bg-white p-3 ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2">
						        	{transactions && transactions.length > 0 ?
											<>
											{
												transactions && transactions.length && 	
												<InfiniteScroll
												dataLength={transactions.length} //This is important field to render the next data
												next={fetchData}
												hasMore={hasMore}
												loader={
													<h4 className="h-20 mt-5"><Loading className="w-5 h-5 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 inline" />Loading...</h4>
												}							
												scrollThreshold={0.7}
												>
												<div className="grid  gap-4 divide-y">
												{
													transactions.map((item, key) => (
														<>
														<div className="flex py-2">
															{item.transaction_type == 'withdraw' ?
																<><div className="text-red-600 w-1/2">Money widtdrawn from wallet
																	<div className="text-gray-400 text-sm">{moment(item.created_at).format('DD/MM/YYYY hh:mm A')}</div>
																		{item.status == 'paid'?
																		<div><Badge text={item.status} color="bg-accent-400 " /></div>
																		:null
																	}
																	{item.status == 'pending'?
																		<div><Badge text={item.status} color="bg-yellow-400 " /></div>
																		:null
																	}
																	{item.status == 'rejected'?
																		<div><Badge text={item.status} color="bg-gray-400 " /></div>
																		:null
																	}
																</div>
																<div className="text-red-600 w-1/2 text-right font-medium">- {item.amount}</div>
																</>
																:null
															}
															{item.transaction_type == 'session_fee'  ?
																<><div className="text-accent w-1/2">Money Added to wallet (Session Fee Recived)
																	<div className="text-gray-400 text-sm">{moment(item.created_at).format('DD/MM/YYYY hh:mm A')}</div>
																		{item.status == 'paid'?
																		<div><Badge text={item.status} color="bg-accent-400 " /></div>
																		:null
																	}
																	{item.status == 'pending'?
																		<div><Badge text={item.status} color="bg-yellow-400 " /></div>
																		:null
																	}
																	{item.status == 'rejected'?
																		<div><Badge text={item.status} color="bg-gray-400 " /></div>
																		:null
																	}
																</div>
																<div className="text-black w-1/2 text-right font-medium">+ {item.amount}</div>
																</>
																:null
															}
														</div>
														</>
													))
												}
												</div>
												</InfiniteScroll>	
												}
												</>
												:
												<NoRecords message="Sorry, No Transactions Yet :(" />
											}
						        </Tab.Panel>
						        <Tab.Panel><BankDetailsList /></Tab.Panel>
						      </Tab.Panels>
						    </Tab.Group>
						</div>

						
					</div>
				</div>
				<ModalView open={openwithdraw} onClose={closeWithdraw}>
				<WithdrawMoney closeWithdraw={closeWithdraw} />
				</ModalView>
		</LeftMenuLayout> 
      </ScrollPageTop>
   );
};

export default MyDoctorWallet