import React, {useState, useEffect ,useRef } from "react";
import Header from 'layouts/Header'
import Footer from 'layouts/Footer'
import ScrollPageTop from 'components/ui/ScrollPageTop'
import SuccessIcon from 'components/ui/successicon'

import { Link, useNavigate } from "react-router-dom";

import * as Yup from 'yup';
import {
   Control,
   FieldErrors,
   useForm,
   useFormState,
   useWatch,
   Controller
 } from 'react-hook-form';
 
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';  
import {Links} from 'routes/PathLink'
import {
   API_ENDPOINT, 
   random_str, 
   toast_anim_error, 
   RAZOR_KEY, 
   loadScript,
   register_valid,
   register_fee,
   renew_per,
   pdfdoconly,
} from 'components/config/Common'

import Button from 'components/ui/Button.tsx'
import {useAuth} from 'contexts/provider/AuthProvider'

const RegisterSuccess = (props) => {
   const navigate = useNavigate();
   const [loading, setLoading] = useState(false);
   
   return(
      <ScrollPageTop>
         <Header />
         <div className="flex justify-center">
            <div className="flex h-full min-h-screen w-screen flex-col justify-center bg-light py-6 px-5 sm:p-8 md:h-auto md:min-h-0 md:max-w-[580px] md:rounded-xl my-5">
                 <div className="flex justify-center">
                  <h1 className="text-xl font-black text-accent">Registeration Completed</h1>
                </div>   
                <div className="flex justify-center">
                   <SuccessIcon />
                 </div>   
            </div>
         </div> 
         <Footer />
      </ScrollPageTop>
   )
}

export default RegisterSuccess