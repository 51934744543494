import React, {useState, useEffect ,useRef} from "react";

import { Link, useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import {
   Control,
   FieldErrors,
   useForm,
   useFormState,
   useWatch,
   Controller
 } from 'react-hook-form';
 
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';  
import {Links} from 'routes/PathLink'
import {
   API_ENDPOINT, 
   random_str, 
   toast_anim_error, 
   RAZOR_KEY, 
   loadScript,
   register_valid,
   register_fee,
   renew_per,
   pdfdoconly,
   languageOptions
} from 'components/config/Common'

import FileInput from 'components/ui/file-input.jsx';
import 'react-phone-input-2/lib/bootstrap.css'
import Button from 'components/ui/Button.tsx'
import {useAuth} from 'contexts/provider/AuthProvider'
import SelectInput from 'components/ui/select-input.jsx'
import TextArea from 'components/ui/text-area';
import Label from 'components/ui/label.tsx'
import {deviceDetect} from "react-device-detect"
import { TagsInput } from "react-tag-input-component";
import { useDoctorReg } from "contexts/doctorregistration/doctor-reg";
import { produce } from 'immer';

//import SocialLogin from './SocialLogin'

const SignupSchema = Yup.object().shape({
   language:Yup.array().nullable().min(1, 'Pick at least 1 Language').max(3, 'Can pick only 5 Language').required('Please choose Language you speak!'),

});


const defaultValues = {  
   language: '',
 };

const RegisterLanguages = (props) => {
   const navigate = useNavigate();
   const [loading, setLoading] = useState(false);
   const [selected, setSelected] = useState([]);
	const { login } = useAuth();

   const { register, handleSubmit , control, reset, setValue, setError, formState: { errors },} = useForm({
      defaultValues: defaultValues,
      resolver: yupResolver(SignupSchema),
      shouldFocusError:false,
   });
   const {form,setForm} = useDoctorReg();

   useEffect(() => {
      if(localStorage.getItem('access_token')){
         return navigate(Links.HOME, { replace: true });
      }
   })

   useEffect(() => {
      reset(defaultValues)
   },[])

   
   const dealerSubscription = async (values) => {
      console.log("STEP THREE FORM VALUE",values);
      console.log("FORM DATA",form);
      //setForm(values);
      setForm(
          produce((formState) => {
            formState.steps.language = {
              values,
              valid: false,
              dirty: false,
            };
          }));
     props.setActivestepfun(5);
   }

   const onSubmit = async (values) => {
       props.setActivestepfun(3);
     
   }
  
  const handleSelect = (val,name) => {  
      console.log('cat val======',val,name.name)
      setError(name.name, null)
      setValue(name.name, val, true);
   }

   return(
      <>
        
         <div className="flex justify-center">
            <div className="flex h-full min-h-screen w-screen flex-col justify-center bg-light py-6 px-5 sm:p-8 md:h-auto md:min-h-0 md:max-w-[580px] md:rounded-xl my-5">
               <div className="flex justify-center">
               <h1 className="text-xl font-black text-accent">Communication Language</h1>
               </div>   
               <form onSubmit={handleSubmit(dealerSubscription)}>
                   <div className="mb-5">
                     <Label>Language <span className="text-red-500"> </span></Label>
                           <SelectInput
                              name="language"                            
                              control={control}
                              getOptionLabel={(option: any) => option.label}
                              getOptionValue={(option: any) => option.id}                        
                              options={languageOptions}
                              onChange={handleSelect}   
                              isMulti                        
                           />
                     <p className="my-2 text-xs text-start text-red-500">{errors.language?.message}</p>

                   </div>
                    <div className="flex w-full content-end ">
                    
                       <div className="w-1/2 ">
                        <Button 
                           className="w-1/2 self-end"
                           loading={loading}
                        >
                        Next
                        </Button>
                     </div>
                  </div>
               </form>
            </div>
         </div>
      </>
   )
}

export default RegisterLanguages