import React, { useEffect, useState } from "react";
import ModalView from '_utils/ModalView'
import {Links} from 'routes/PathLink'
import Pusher from 'pusher-js';
import AcceptCall from "pages/calling/AcceptCall";
import { useNavigate } from 'react-router-dom';
import ConfirmBox from "components/calling/ConfirmBox";
import {useOutgoingModal} from "contexts/outgoingmodal/outgoingmodal";
import {useCallingAudio} from "contexts/callingaudio/callingaudio";
import { LoadingLottie} from 'components/custom-icons'


const PusherRequest = ({}) => {
    const navigate = useNavigate();
    const [access_token,setAccess_token] = useState(localStorage.getItem('access_token'));
    const [user_id, setUser_id] = useState(localStorage.getItem('user_id'))
    const [user_type, setUser_type] = useState(localStorage.getItem('user_type'))
    const [meetingId, setMeetingId] = useState('')
    const [datainfo, setDatainfo] = useState('')
    const [reqcount, setReqcount] = useState(0);
    const [confirmdecline,setConfirmdecline] = useState(false);
    const {calldeclined, updateCallDeclined} = useOutgoingModal();
    const {callingaudio,updateCallingAudio} = useCallingAudio();
    const [acceptCallModal,setAcceptCallModal] = useState(true);
    const [openacceptcalling,setOpenacceptcalling] = useState(false);
    const [callaccept,setCallaccept] = useState(false);

  useEffect(() => {
     var pusher = new Pusher('60a17a016b93f0924a12', {
      cluster: 'ap2'
    });
     
     var channel = pusher.subscribe('my-channel');
     
     
    
      channel.bind('calling_accept', function(data) {
        console.log("RESPOSNE calling_accept",data);
        //call_start_deduct_session_amount
        console.log("RESPOSNE USER ID",user_id);
        console.log("RESPOSNE USER Type",user_type);

        if(data.meetingId != '' && data.user_id == user_id && reqcount == 0 && user_type == 'user'){
          console.log("IN IF ACCEPT");
          setCallaccept(true);
          channel.unbind('calling_accept');
          setReqcount(1);
          updateCallingAudio('yes');
          setTimeout(() => { 
            navigate(Links.CALLNOW,{state:data})
            setCallaccept(false);
          }, 2000);
          
          //callingaudio?.pause();
          //navigate(Links.CALLNOW,{state:data})

        }
      });


       channel.bind('calling_declined', function(data) {
        console.log("RESPOSNE calling_declined",data);
        if(data.meetingId != '' && data.user_id == user_id && user_type == 'user'){
          //console.log("IN IF Call DEclined");
          channel.unbind('calling_declined');
          //setReqcount(1);
          setConfirmdecline(true);
          updateCallDeclined('yes');
          //alert("IN IF Call DEclined");
           //navigate(Links.DOCTORS,{state:data})
            //alert("Pusher Notification Recived");
          }
      }); 


      //
      /*let data = {
          'meetingId':'y4e2-8lv6-wbma',
          'docId':18,
        }
        setDatainfo(data);
        setMeetingId(meetingId);
        if(data.meetingId){
          navigate(Links.ACCEPTCALL,{state:data})
        }
        */
    },[access_token]);

 
  const closeLoginNav = () =>{

  }

  const closeAcceptCalling = () =>{
    setCallaccept(false);
  }

  return (
      <>
      <ModalView open={callaccept} onClose={closeAcceptCalling}>
        <div className="w-full h-96 flex items-center justify-center">
            <LoadingLottie className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 inline" />
         </div>
      </ModalView>
       <ConfirmBox
        open={confirmdecline}
        title="Call Declined"
        subTitle={"Your Call Request Declined by doctor."}
        successText="DISMISS"
        onSuccess={() => {
          setConfirmdecline(false);
          updateCallDeclined('');
          navigate(Links.DOCTORS);
        }}
       
      />
        <ModalView open={openacceptcalling} onClose={closeAcceptCalling}>
          <AcceptCall closeCalling={closeAcceptCalling}   /> 
        </ModalView>

      </>
  );
};

export default PusherRequest;
