import React, {useState, useEffect,useRef} from "react";
import { Link } from "react-router-dom";

import LeftMenuLayout from 'layouts/LeftMenuLayout';

import classNames from 'classnames';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {API_ENDPOINT, toast_anim , pdfdoconly , languageOptions , specalizationOptions,amount_format , weekdays} from 'components/config/Common'
import {Loading} from "components/custom-icons";
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin } from "react-icons/fa";

import axios from 'axios';  

import {APP_URL, APP_NAME , IMG_URL} from 'components/config/Common'
import FileInput from 'components/ui/file-input.jsx';

import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import { toast } from 'react-toastify';
import ScrollPageTop from 'components/ui/ScrollPageTop'

import { yupResolver } from '@hookform/resolvers/yup';
import Input from 'components/ui/input.jsx'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
import SelectInput from 'components/ui/select-input.jsx'
import Label from 'components/ui/label.tsx'
import Button from 'components/ui/Button.tsx'
import { Switch } from '@headlessui/react'
import TextArea from 'components/ui/text-area';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Tab } from '@headlessui/react'
import { BsPencilFill } from "react-icons/bs";
import SetDailyAvalibility from 'components/experts/SetDailyAvalibility';
import ModalView from '_utils/ModalView'
import { FiDelete } from "react-icons/fi";

import {
	Control,
	FieldErrors,
	useForm,
	useFormState,
	useWatch,
	Controller
 } from 'react-hook-form';

const SignupSchema = Yup.object().shape({
	name: Yup.string()
		.required('You must need to provide name.')
		.min(2, 'Name is too short!')
		.max(50, 'Name is too long!'),
	email: Yup.string()
		.required('You must need to provide email address.')
		.email('Invalid email.'),		
});

const SocialSchema = Yup.object().shape({
	facebook: Yup.string()
		.url('Invalid url')
		//.matches('/facebook/', 'Provide valid facebook profile url')
		.nullable(),
	twitter: Yup.string()
		.url('Invalid url')
		.nullable(),
	instagram: Yup.string()
		.url('Invalid url')
		.nullable(),
});

let defaultValues = {  
	name: '',
	email:'',
	phone_no:'',
	password:'',
};

const ExpertAvalibilty = () => {
	
	const [access_token, setAccess_token] = useState(localStorage.getItem('access_token'))
	const [user_type, setUser_type] = useState(localStorage.getItem('user_type'))
	const [user_me, setUser_me] = useState(null);
	const [loading, setLoading] = useState(false);
	
   const [enabled, setEnabled] = useState(false);
   const [daily_avalibilty, setDaily_avalibilty] = useState([]);
   const [daily_avalibilty_id, setDaily_avalibilty_id] = useState(0);

   const [currentweekday, setCurrentweekday] = useState();

   const [opensessionchange ,setOpensessionchange] = useState(false);

	const { register, handleSubmit , control, reset, setValue, setError, formState: { errors },} = useForm({
		defaultValues: defaultValues,
  	});

  	const [startDate, setStartDate] = useState(new Date());

   let handleColor = (time) => {
    return time.getHours() > 12 ? "text-success" : "text-error";
   };


	const refphone = useRef(null);

	useEffect(() => {
		getdaily_avalibilty();
	},[])
	

  const handleSelect = (val,name) => {  
		console.log('cat val======',val,name.name)
		setError(name.name, null)
		setValue(name.name, val, true);
		
	}

	

	const closeSessionch = () =>{
		setOpensessionchange(false);
		getMyProfile();
		getdaily_avalibilty();
	}

	const openSessionch = (day,id) =>{
		setCurrentweekday(day)
		setOpensessionchange(true);
		if(id){
			setDaily_avalibilty_id(id);
		}
		
	}
	
	const getdaily_avalibilty =() => {
		axios.get(`${API_ENDPOINT}/get_daily_availability`,{
         headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer '+access_token, 
         }
      }).then( (result)=> {
         console.log('data=====',result.data)
         if(result.data?.data){
         	setDaily_avalibilty(result.data?.data);
         }
      })
	}


	const getMyProfile = () => {
		console.log(access_token)
		fetch(`${API_ENDPOINT}/expert_me`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json',
				'Authorization': 'Bearer '+access_token, 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			console.log(result)
			setUser_me(result.data)
			let data = result.data

			defaultValues = {  
				next_available_date:data?.next_available_date,
			};
			reset(defaultValues)
			console.log("Availability date",data?.next_available_date);
			if(data?.next_available_date){
				setStartDate(Date.parse(data?.next_available_date));
			}
			
			if(data?.specalizationdt){
				setValue('specialisation',data?.specalizationdt);
			}else{
				setValue('specialisation',[]);
			}
			if(data?.othspecalizationdt){
				setValue('other_specialisation',data?.othspecalizationdt);
			}else{
				setValue('other_specialisation',[]);
			}

			if(data?.languagesdt){
				setValue('language',data?.languagesdt);
			}else{
				setValue('language',[]);
			}
		})
	}	

	const siteMetadata = {
		url: `${APP_URL}/profile`,
		title:  `Profile - ${APP_NAME}`,
		description: `Profile ${APP_NAME}`,
	}

	const onSubmit = (values) => {
		console.log('value', values)
		setLoading(true)		

		let input = values;
		input = {nextDate:values?.next_available_date};
      	setLoading(true)
		
      axios.post(`${API_ENDPOINT}/set_availability`,input,{
         headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer '+access_token, 
         }
      }).then( (result)=> {
         console.log('data=====',result.data)
         setLoading(false)
         console.log('data=====',result.data.status)
			if(result.data.status === 1){
				//alert("IN IF");
				//localStorage.setItem('user_name',result.data.data.name);
				toast_anim(result.data.message)
			}
      })
	}

	const deleteSlot = (da_id,slotId) => {

		let input = {};
		input = {da_id:da_id,slotId:slotId};
      setLoading(true)
		
      axios.post(`${API_ENDPOINT}/delete_daily_availability`,input,{
         headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer '+access_token, 
         }
      }).then( (result)=> {
         console.log('data=====',result.data)
         setLoading(false)
         console.log('data=====',result.data.status)
			if(result.data.status === 1){
				//alert("IN IF");
				//localStorage.setItem('user_name',result.data.data.name);
				toast_anim(result.data.message);
				getdaily_avalibilty();
			}
      })

	}

	return (
		<ScrollPageTop>
			<ReactSEOMetaTags
				render={(el: React.ReactNode) => <Helmet>{el}</Helmet>}
				website={{ ...siteMetadata }}
				breadcrumb={[
					{ title: 'Home', url: `${APP_URL}` },
					{ title: 'Profile', url: `${APP_URL}/profile` },
				]}
			/>
         <LeftMenuLayout>
				<div className="w-full">
				
					<div className={classNames('w-full p-5 md:p-8 bg-light dark:bg-gray-700 shadow rounded mb-8')}>
						<p className="text-lg lg:text-xl text-heading capitalize mb-5 dark:text-zinc-400">Set Availability</p>
						<form onSubmit={handleSubmit(onSubmit)}>
						<div className=" flex w-full flex-col  px-2 py-2 ">
								<label for="name" class="mb-3 block text-sm font-semibold leading-none text-body-dark dark:text-zinc-400">Next Appointment Avaliablity </label>
								<div className="flex">
					       	<div className="mb-5">
									<Controller
								    control={control}
								    name='next_available_date'
								    render={({ field }) => (
										<DatePicker
								      showTimeSelect
								      selected={startDate}
								      onChange={(date) => {field.onChange(date);
								      setStartDate(date); }}
								      timeClassName={handleColor}
								      minDate={(new Date())- 1}
								      dateFormat="yyyy/MM/dd hh:mm a"
								    />
								     )}
  									/>
								    </div>
								    <div className="ml-6 ">
								   	 <button data-variant="normal" className="inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-5 py-0 h-12 ltr:ml-auto rtl:mr-auto">Set</button>
									 </div>
								</div>
					    </div>
					    </form>		
					    <div>
					    	{/* <div className="ml-6 py-4 px-0 border-t-[1px] border-gray-300">
								<button data-variant="normal" className="inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-5 py-0 h-12 ltr:ml-auto rtl:mr-auto">Set Daily Availability</button>
							</div> */}
							<div className="py-4 px-0 border-t-[1px] border-gray-300">
								<h1 className="text-xl">Daily Availability</h1>
							</div> 

							<div className="divide-y-[1px]">
								
								{weekdays.map((item,key) => {
									let is_match = 0;
									return(<>
									{daily_avalibilty && daily_avalibilty.map((itemd,keyd)=>{
										if(item.label == itemd.day){
											is_match = 1;
										}
										

									  return(<>{item.label == itemd.day &&
									  		<div className="flex">
											<div className="px-2 py-2 w-2/5	 "><span className="uppercase text-gray-600">{itemd.day}</span>
											{itemd?.slot && itemd?.slot.map((slt,sk)=>(
												<div className="text-sm px-2 py-1 flex">{slt.fromTime} - {slt.toTime} <FiDelete onClick={()=> { deleteSlot(slt.da_id,slt.id) }} className=" cursor-pointer ml-2 w-6 h-6 text-red-600" /></div>
											))}
											</div>
											<div className="w-2/5 flex justify-start items-center">
												<button data-variant="normal" onClick={()=> { openSessionch(itemd.day,itemd?.id) }} className="inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-2 py-0 h-8 ltr:ml-auto rtl:mr-auto">Set Availability</button>
											</div>
										</div>
										}
										</>)
									})}
									{is_match == 0 && 
									<div className="flex">
										<div className="px-2 py-2 w-2/5	">{item.label}

										</div>
										<div className="w-2/6 flex justify-start items-center">
											<button data-variant="normal" onClick={()=> { openSessionch(item.label) }} className="inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-2 py-0 h-8 ltr:ml-auto rtl:mr-auto">Set Availability</button>
										</div>
									</div>
									}
									</>)
								})
            				}
							</div>
					    </div>
					
					</div>
						
				</div>
			</LeftMenuLayout>
			<ModalView open={opensessionchange} onClose={closeSessionch} >
				<SetDailyAvalibility closeSessionch={closeSessionch} currentweekday={currentweekday} daily_avalibilty_id={daily_avalibilty_id} />
			</ModalView>
      </ScrollPageTop>
   );
};

export default ExpertAvalibilty