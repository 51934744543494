import { Fragment, useState, useEffect } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { Link } from "react-router-dom";
import {useAuth} from '../contexts/provider/AuthProvider'

import {SideMenu} from './SideMenu'
import ImageUpload from "components/ui/imageupload";

const AuthorizedMenu = (props) => {
    const { login,  logout , myInfo} = useAuth();
    const [userName, SetUserName] = useState()
    const [sideMenu, SetSideMenu] = useState(SideMenu)
    const [user_type,setUser_type] =useState(localStorage.getItem('user_type'));
    useEffect(() => {
        //const user_name = localStorage.getItem(process.env.REACT_APP_CODE+'user_name');
        //const access_token = localStorage.getItem(process.env.REACT_APP_CODE+'access_token');
        const user_name = localStorage.getItem('user_name');
        const access_token = localStorage.getItem('access_token');
       // const user_type = localStorage.getItem('access_token');
        if(user_name){
            SetUserName(user_name)
            login({access_token:access_token, user_name:user_name})
        }
    },[user_type]);

    const logoutUser = () => {
        logout()
        props.logout()
    }

    return (
        <Menu
            as="div"
            className="relative inline-block ltr:text-left rtl:text-right"
            >
            <Menu.Button className="flex flex-col items-center focus:outline-none text-accent dark:text-zinc-400">
                <div className="flex">
                {userName}
                <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                </div>
                <div>
                {user_type == 'user'?
                 <><p className="text-xs">you will be anonymous to others</p>
                 {myInfo && myInfo?.user_name && 
                 <p className="text-xs">{myInfo?.user_name}</p>
                 }
                 </>
                 :
                 null
                }
               </div>
            </Menu.Button>
           
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items
                    as="ul"
                    className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-600"
                >
                {
                    sideMenu && sideMenu.map((item, index) => (
                        <Menu.Item key={index}>
                            <Link to={item.link} className='block px-4 py-2 text-sm dark:text-zinc-300'>{item.label}</Link>
                        </Menu.Item>    
                    ))
                }
                    
                    <Menu.Item>
                        <button className='block w-full px-4 py-2 text-left text-sm dark:text-zinc-300' onClick={logoutUser}
                        >Logout</button>
                    </Menu.Item>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}
export default AuthorizedMenu;