import React, { createContext, useContext, useState, useEffect ,useMemo } from "react";
import {API_ENDPOINT} from '../../components/config/Common'

const OutgoingModalContext = createContext('OutgoingModalContext');

export const OutgoingModalProvider = (props) => {

   const [calldeclined, setCalldeclined] = useState('')
   

   useEffect(() => {
      
   },[])

   const updateCallDeclined = (text) => {
      setCalldeclined(text)
  }

   const value = useMemo(
    () => ({
      calldeclined,
      updateCallDeclined,
    }),
    [calldeclined]
  );

   return <OutgoingModalContext.Provider value={value} {...props} />

}

export const useOutgoingModal = () => {
   const context = React.useContext(OutgoingModalContext);
   if (context === undefined) {
      throw new Error(`useSiteSettings must be used within a SiteSettingContext`);
   }
   return React.useMemo(() => context, [context]);
   //return context;
};