import React, {useState, useEffect , useRef} from "react";
import { Link } from "react-router-dom";

import LeftMenuLayout from 'layouts/LeftMenuLayout';

import classNames from 'classnames';
import * as Yup from 'yup';
import {API_ENDPOINT, toast_anim } from 'components/config/Common'
import {Loading , WalletPointsIcon} from "components/custom-icons";

import axios from 'axios';  
import {APP_URL, APP_NAME , IMG_URL , LIMIT ,amount_format,showRating , EXPERT_LABEL} from 'components/config/Common'
import NoRecords from 'pages/common/NoRecords'

import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import { toast } from 'react-toastify';
import ScrollPageTop from 'components/ui/ScrollPageTop'
import {Links} from 'routes/PathLink'

import { yupResolver } from '@hookform/resolvers/yup';
import Input from 'components/ui/input.jsx'
import Label from 'components/ui/label.tsx'
import Button from 'components/ui/Button.tsx'
import InfiniteScroll from "react-infinite-scroll-component";
import * as moment from 'moment'
import ModalView from '_utils/ModalView'
import Badge from "components/ui/badge/badge";

const DoctorRatings = () => {

	const [access_token, setAccess_token] = useState(localStorage.getItem('access_token'))
	const [user_type, setUser_type] = useState(localStorage.getItem('user_type'))
	const [user_id , setUser_id] = useState(localStorage.getItem('user_id'));
	const [user_me, setUser_me] = useState(null);
	const [loading, setLoading] = useState(false);
	const [loading_social, setLoading_social] = useState(false);
	const [totalamount,setTotalamount] = useState(0);
	//const [transactions,setTransactions]= useState([]);
	const [doctorratings,setDoctorratings]= useState([]);

	const [page, setPage] = useState(1)
   const [totalRec, setTotalRec] = useState(0)
   const [hasMore, setHasMore] = useState(true)
   const [openwithdraw ,setOpenwithdraw] = useState(false);
	useEffect(() => {
		
		getDoctorratings(1);
	},[])
	

	const siteMetadata = {
		url: `${APP_URL}/profile`,
		title:  `My ${EXPERT_LABEL} Ratings - ${APP_NAME}`,
		description: `My ${EXPERT_LABEL} Ratings - ${APP_NAME}`,
	}

	const getDoctorratings = (pg) => {
      
      let paginate = `page=${pg}&limit=${LIMIT}`;
      let search = ''
      
		fetch(`${API_ENDPOINT}/get_expert_ratings?${paginate}`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
				'Authorization': 'Bearer '+access_token, 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			console.log('cat products======',result.data)
			//setProducts(result.data)
         if(pg === 1){
            setDoctorratings(result.data)
         }else{
            setDoctorratings([...doctorratings, ...result.data])
         }
         let nextPage = page + 1;
         setPage(nextPage)
         setTotalRec(result.total)

         if(result.data.length < LIMIT){
            setHasMore(false)
         }else{
            setHasMore(true)
         }

         //setProcess(true)
         
         /* let p = document.getElementById('product-list')
         p.scrollIntoView( { behavior: 'smooth', block: 'start' } ); */
		})
	}

	const fetchData = () =>{
		getDoctorratings(page)
	}

	return (
		<ScrollPageTop>
			<ReactSEOMetaTags
				render={(el: React.ReactNode) => <Helmet>{el}</Helmet>}
				website={{ ...siteMetadata }}
				breadcrumb={[
					{ title: 'Home', url: `${APP_URL}` },
					{ title: 'Profile', url: `${APP_URL}/profile` },
				]}
			/>
         <LeftMenuLayout> 
				<div className="w-full">
					<div className={classNames('w-full p-5 md:p-8 bg-light dark:bg-gray-700 shadow rounded mb-8')}>
						<p className="text-lg lg:text-xl text-heading capitalize mb-5 dark:text-zinc-400">My Session Ratings</p>
						{doctorratings && doctorratings.length?
						<>
						{
							doctorratings && doctorratings.length && 	
							<InfiniteScroll
							dataLength={doctorratings.length} //This is important field to render the next data
							next={fetchData}
							hasMore={hasMore}
							loader={
								<h4 className="h-20 mt-5"><Loading className="w-5 h-5 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 inline" />Loading...</h4>
							}							
							scrollThreshold={0.7}
							>
							<div className="grid  gap-4 divide-y">
							{
								doctorratings.map((item, key) => (
									<>
									<div className="flex py-2">
											<><div className="text-accent w-2/6">Rating by User - {item?.userinfo?.user_name}
												<div className="text-gray-400 text-sm">{moment(item.created_at).format('DD/MM/YYYY hh:mm A')}</div>
													<div className="text-gray-400 text-sm">{item.call_time}</div>
											</div>
											<div className="w-2/6 font-light">{item?.patient_comment}</div>
											<div className="text-red-600 w-2/6  font-medium">
												<div className="flex justify-end">{showRating(item?.rating)}</div>
											</div>
											</>
									</div>
									</>
								))
							}
							</div>
							</InfiniteScroll>	
							}
							</>
							:
							<NoRecords message="Sorry, No Ratings Yet :(" />
						}
					</div>
				</div>
			
		</LeftMenuLayout> 
      </ScrollPageTop>
   );
};

export default DoctorRatings