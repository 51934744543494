import React, {useState, useEffect, useRef} from "react";
import Header from "layouts/Header";
import Footer from "layouts/Footer";
import {API_ENDPOINT, APP_URL, APP_NAME, IMG_URL, replaceWithBr, LIMIT, showRating} from 'components/config/Common'
import ScrollPageTop from 'components/ui/ScrollPageTop'
import {Links} from 'routes/PathLink'
import Seo from 'components/seo/seo'
import {Loading , LoadingLottie} from 'components/custom-icons'
import cn from 'classnames';
import InfiniteScroll from "react-infinite-scroll-component";
import NoRecords from '../common/NoRecords'



const ShowTestimonials = () => {
   const [loading, setLoading] = useState(true)
   const [details, setDetails] = useState(null)
   const [totalRec, setTotalRec] = useState(0)
   const [page, setPage] = useState(1)
   const [hasMore, setHasMore] = useState(true)
   const [desc, setDesc] = useState(null)
   
   const [pagecontent, setPagecontent] = useState(null);
   const [pagetitle, setPageTitle] = useState(null);

   useEffect(() => {
      getTestimonials(1)
   },[])


      useEffect(() => {
      getPageDetails()
   },[]);

    const getPageDetails = () => {
      //setLoading(true)
      fetch(`${API_ENDPOINT}/page_by_slug/testimonials`,{
         method: 'GET',
         headers : {        
            'Content-Type': 'application/json',
            'Accept': 'application/json', 
         }
      }).then(function (response) {
            return response.json();
      }).then( (result)=> {
         //console.log('slider======',result)
         //setLoading(false)
         setPagecontent(result?.data?.content);
         setPageTitle(result?.data?.title);
         //setMetaTitle(result.data.meta_title ? result.data.meta_title : result.data.title)
         //setMetaDesc(result.data.meta_description ? result.data.meta_description : result.data.content)
      })
   }

   
   const getTestimonials = (pg) => {
      fetch(`${API_ENDPOINT}/get_testimonials?page=${pg}&limit=${LIMIT}`,{
         method: 'GET',
         headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
         }
      }).then(function (response) {
            return response.json();
      }).then( (result)=> {
         console.log('testimonials=====',result)
         setDetails(result.data)
         setLoading(false)
         if(pg === 1){
            setDetails(result.data)
         }else{
            setDetails([...details, ...result.data])
         }
         let nextPage = page + 1
         setPage(nextPage)

         if(result.data.length < LIMIT){
            setHasMore(false)
         }else{
            setHasMore(true)
         }
         setTotalRec(result.total)
      })
   }

   const fetchData = () => {
      //console.log('page==',page)
      getTestimonials(page)
      
	}

   const showQuote = (text) => {
      return(
         <p>{text}</p>
      )
   }
   
   return(
      <ScrollPageTop>
         <Seo 
            title={`Testimonials - ${APP_NAME}`}
            desc="Testimonials ctmri"
            url={`${APP_URL}${Links.TESTIMONIALS}`}
            image={`./img/home-bg.jpg`}
         />

         <Header hidesearch="true"  />
         {
         loading ?
         <div className="w-full h-96 flex items-center justify-center">
            <LoadingLottie className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 inline" />
         </div>
         :
         <section className="mx-auto w-full max-w-1920 py-8 px-4 lg:py-10 lg:px-8 xl:py-14 xl:px-16 2xl:px-20">
            {
            details &&
            <>

                  
                  
            <h1 className="mb-4 text-heading sm:mb-5  text-center">
            What Our Clients Say About Us
            </h1>
            <p className="text-body mb-5" dangerouslySetInnerHTML={{__html:pagecontent}}></p>
            <InfiniteScroll
               dataLength={details.length} //This is important field to render the next data
               next={fetchData}
               hasMore={hasMore}
               loader={
                  <div className="h-20 mt-5 w-full md:max-w-[680px] mx-auto"><Loading className="w-5 h-5 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 inline" />Loading...</div>
               }
               scrollThreshold={0.7}
            >
            <div class="w-full h-fit md:columns-3 lg:columns-3 sm:columns-2 gap-6">

            {
            details && details.map((val, key) => (
            
            
               <div className=" py-2 flex w-full group break-inside-avoid-column">
               <div className=" bg-light flex flex-col py-4 px-4 ">
                  {
                     val.image &&
                  <div className="rounded-full overflow-hidden w-40 h-40 mx-auto">
                     <img src={`${IMG_URL}${val.image}`} alt={val.name} className="w-40 h-40 object-cover" />
                  </div>
                  }
                 
                  {val.testimony && <blockquote className="mt-3 break-inside-avoid-column text-center text-xl text-gray-900 dark:text-white" dangerouslySetInnerHTML={{__html:replaceWithBr(val.testimony)}} />}
                  <div className="flex items-center justify-center mt-3">{showRating(val.rating)}</div>
                   <div className="text-center text-2xl font-light text-heading text-gray-900 dark:text-white capitalize">
                     {val.name}
                  </div>
               </div>
            </div>
           
            ))
            }
            </div>
            </InfiniteScroll>
            </>
            }
         </section>
         }
         <Footer />
      </ScrollPageTop>
   )
}

export default ShowTestimonials