
// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import {getAuth} from "firebase/auth"

// Your web app's Firebase configuration
const firebaseConfig = {
   apiKey: "AIzaSyCqGVmYDY606MZdVjSSXOi1twQw5MtAAkY",
   authDomain: "counsellingapp-216d3.firebaseapp.com",
   projectId: "counsellingapp-216d3",
   storageBucket: "counsellingapp-216d3.appspot.com",
   messagingSenderId: "171260936172",
   appId: "1:171260936172:web:ecf30b2ba7cdec59f9cac9"
 };
 

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
let auth = firebase.auth();

 const authentication = getAuth(app)
export {firebase , authentication, auth};