import React, {useState, useEffect} from "react";

import {API_ENDPOINT} from 'components/config/Common'
import Checkbox from 'components/ui/checkbox/checkbox.tsx'
import Radio from 'components/ui/radio/radio.tsx';
import Button from 'components/ui/Button.tsx'
import {CloseIcon} from 'components/custom-icons'
import {isBrowser} from "react-device-detect"
import { BsFillArrowDownSquareFill } from "react-icons/bs";
import {BsFillArrowUpSquareFill} from "react-icons/bs";

const sortOptions = [
   /*{
    'id':'rating_high',
    'name':'rating',
    'direction':'DESC',
    'label':'High Rating',
  },
  {
    'id':'rating_low',
    'name':'rating',
    'direction':'ASC',
    'label':'Low Rating',
  },
  */
  {
    'id':'session_price_high',
    'name':'session_price',
    'class':'border-t mt-1',
    'direction':'DESC',
    'label':'High Price Per Session',
  },
  {
    'id':'session_price_low',
    'name':'session_price',
    'direction':'ASC',
    'label':'Low Price Per Session',
  },
];

const SortBy = (props) => {

   //const [brands, setBrands] = useState(null)
   //const [brand_id, setBrand_id] =  useState(null)
   const [isopen, setIsopen] =  useState(false);

   const [rating, setRating] =  useState(null)
   const [sessionprice, setSessionPrice] =  useState(null)
   const [is_online, setIs_online] =  useState(false);



   const handleChange = (e) => {
      console.log('onchange', e.target.name, e.target.value)
      if(e.target.name == 'rating'){
         setRating(e.target.value);
      }
      
      if(e.target.name == 'session_price'){
         props.handlePriceSort(e.target.value);
      }
   }

   const handleOnlineChange = (e) =>{
      if(e.target.checked == true){
         props.handleOnlineFilter('yes');
         //setIs_online(true)
      }else{
         props.handleOnlineFilter('no');
          //setIs_online(false);
      }
   }

   const handlePopularChange = (e) =>{
      if(e.target.checked == true){
         props.handlePopularSort('yes');
         //setIs_online(true)
      }else{
         props.handlePopularSort('no');
          //setIs_online(false);
      }
   }

  /* const clearBrandFilter = () => {
      let radio = document.getElementById(gender);
      radio.checked = false;
      props.handleGenderFilter(null) ;
   }
   */

   const toggleOtions = () =>{
      setIsopen(!isopen);
   }

   return(
      <div className="h-full w-full bg-light dark:bg-gray-700 p-2">
        <div className="flex flex-col ">
          
             <div className="flex w-full cursor-pointer" onClick={toggleOtions}>
            <div className="text-heading dark:text-zinc-400 uppercase  w-9/12 ">Sort By </div>
            <div className="w-3/12 flex justify-center  items-end   ">{isopen ? <BsFillArrowUpSquareFill/> : <BsFillArrowDownSquareFill />}</div>
            </div>
            <div class="border-2 border-gray-200 w-1/4 mt-2 mb-2"></div>

         </div>
         {isopen ?
          <ul class="text-xs" >
             <li class="rounded-md py-1 border-t mt-1" key={`sortotp-online_only`}>
               <div class="flex w-full items-center py-2 font-semibold text-body-dark outline-none transition-all ease-in-expo  focus:text-accent focus:outline-none focus:ring-0 ltr:text-left rtl:text-right text-body-dark text-sm text-body dark:text-zinc-400">
                  <Checkbox
                     name='online_only'
                     label="Online Only"
                     id="online_only"
                     value="online_only"
                     className=""
                     theme='primary'
                     onChange={handleOnlineChange}
                  />
               </div>
            </li>
             <li class="rounded-md py-1 border-t mt-1" key={`sortotp-popular`}>
               <div class="flex w-full items-center py-2 font-semibold text-body-dark outline-none transition-all ease-in-expo  focus:text-accent focus:outline-none focus:ring-0 ltr:text-left rtl:text-right text-body-dark text-sm text-body dark:text-zinc-400">
                  <Checkbox
                     name='rating'
                     label="By Popularity"
                     id="rating"
                     value="rating"
                     className=""
                     theme='primary'
                     onChange={handlePopularChange}
                  />
               </div>
            </li>
            {
            sortOptions && sortOptions.map((val, key) => (
            <li class={`rounded-md py-1 ${val.class}`} key={`sortotp-${val.id}`}>
               <div class="flex w-full items-center py-2 font-semibold text-body-dark outline-none transition-all ease-in-expo  focus:text-accent focus:outline-none focus:ring-0 ltr:text-left rtl:text-right text-body-dark text-sm text-body dark:text-zinc-400">
                  {/*<Checkbox
                     name='sortby'
                     label={val.label}
                     id={val.id}
                     value={val.id}
                     className=""
                     theme='primary'
                     onChange={handleChange}
                  />
                     */}
                   <Radio
                     name={val.name}
                     label={val.label}
                     id={val.id}
                     value={JSON.stringify(val)}
                     className="mr-2"
                     onChange={handleChange}
                  />
               </div>
            </li>
            ))
            }

           
         </ul>
         :
         null
      }
      </div>
   )
}

export default SortBy