import React, {useState, useEffect , useRef} from "react";
import Header from 'layouts/Header'
import Footer from 'layouts/Footer'
import Logo from 'layouts/logo';
import ScrollPageTop from 'components/ui/ScrollPageTop'
import {EyeOff, Eye, Loading} from "components/custom-icons";
import Button from 'components/ui/Button.tsx'
import Input from 'components/ui/input.jsx'

import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import {
   Control,
   FieldErrors,
   useForm,
   useFormState,
   useWatch,
   Controller
 } from 'react-hook-form';

import axios from 'axios';  
import { yupResolver } from '@hookform/resolvers/yup';
import {API_ENDPOINT,toast_anim , toast_anim_error} from 'components/config/Common'
import {useAuth} from 'contexts/provider/AuthProvider'
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {Links} from 'routes/PathLink'
import ModalView from '_utils/ModalView'
import {deviceDetect} from "react-device-detect"
import { produce } from 'immer';
import { useDoctorReg } from "contexts/doctorregistration/doctor-reg";
import SocialLogin from "components/auth/SocialLogin";
import OtpInput from "react-otp-input";
import {firebase , auth, authentication} from 'components/config/FirebaseConfig';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'
const SignupSchema = Yup.object().shape({
	 phone_no: Yup.string()
      .required('You must need to provide your phone number.'),
});

const defaultValues = {  
   phone_no: '',
 };

const SigninwithPhone = (props) => {
   const [show, setshow] = useState(false);
   const [loading, setLoading] = useState(false);
   const {form,setForm} = useDoctorReg();

   const { login } = useAuth();
   let navigate = useNavigate();
   const [isOpen, setIsOpen] = useState(false)
   const [otp, setotp] = useState('');
  const [final, setfinal] = useState('');
  const [whereto, setWhereto] = useState('');
const [phone_no, setPhone_no] = useState(false);
  const [userData,setUserData] = useState([]);
      const { register, handleSubmit , control, reset, setValue, setError, formState: { errors },} = useForm({
      defaultValues: defaultValues,
      resolver: yupResolver(SignupSchema),
      shouldFocusError:false,
   });
const refphone = useRef(null);
   useEffect(() => {
     
      if(localStorage.getItem('access_token')){
         return navigate("/", { replace: true });
      }
   })

   const handleView = () => {
		setIsOpen(true)
	}

   /*  const ValidateOtp = () =>{
      console.log("OTP VALE",otp);
      if(final?.otp ==  otp){
        // alert("OTP MATCHED");
        let values = {
         email:email
        };
         axios
         .post(API_ENDPOINT+'/check_user_exist', values)
         .then((response) => {
            console.log("RESPONSE",response.data);

            if(response.data?.status == 1){
              // alert("DOCTOR EXIST");

                let input ={
                  email:email,
                  password:response.data?.data?.password,
                } ;
                  //input = {...input, device:deviceDetect()}

                  fetch(`${API_ENDPOINT}/user_login`,{
                     method: 'POST',
                     headers : {        
                        'Content-Type': 'application/json',         
                     },
                     body: JSON.stringify(input)
                  }).then(function (response) {
                        return response.json();
                  }).then( (result)=> {
                     //console.log(result)
                     setLoading(false)
                     if(result.status === 1){
                        localStorage.setItem('access_token',result.access_token);
                        localStorage.setItem('user_type',result.user_type);
                        localStorage.setItem('user_id',result.user_id);
                        localStorage.setItem('user_name',result.user_name);
                        //props.setLogin()
                        login({access_token:result.access_token, user_name:result.user_name})
                        return navigate(Links.MYDASHBOARD, { replace: true });
                     }
                     if(result.status === 0){
                        //setErrors({password:result.message})
                     }
                     //setErrors({phone_no:result.message})
                  })
              // props.setActivestepfun(2);
            }else{
               //alert("DOCTOR Not EXIST");
               props.setActivestepfun(2);
            }
           //setPost(response.data);
         });
      }else{
          //alert("OTP INVALID");
      }
   }
   */


   const ValidateOtp = () =>{
       if (otp === null || final === null)
            return;
        final.confirm(otp).then((result) => {
         
            console.log("USER DATA",userData);
            let input = [];
            input = {...input,phone_no:phone_no }
            input = {...input, device:deviceDetect()}

            fetch(`${API_ENDPOINT}/check_expert_phoneno_exist`,{
               method: 'POST',
               headers : {        
                  'Content-Type': 'application/json',         
               },
               body: JSON.stringify(input)
            }).then(function (response) {
                  return response.json();
            }).then( (result)=> {
               //console.log(result)
               setLoading(false)
               if(result.status === 1){
                 toast_anim_error("Your are alreay registered with us , please Login.")
               }
               if(result.status === 0){
                  props.setActivestepfun(2);
                  //setErrors({password:result.message})
               }
               //setErrors({phone_no:result.message})
            });
            // success
       
        }).catch((err) => {
            toast_anim_error("Wrong code");
        })
  
   }

   const closeModal = () => {
      setIsOpen(false)      
   }

     const loginsubmit = async (values) => {
       //props.setActivestepfun(2);
       setLoading(true);
       //console.log(values);
        let input ={
                  phone_no:'+'+values?.phone_no,
                  from:'phoneno',
                } ;
       values = input;
       setForm(
          produce((formState) => {
            formState.steps.main = {
              values,
              valid: true,
              dirty: false,
            };
          }));
          console.log("form Data ",form);
       setPhone_no(values?.phone_no);
       values = {phone_no:values?.phone_no};    
       axios
      .post(API_ENDPOINT+'/check_expert_phoneno_exist', values)
      .then((response) => {
         console.log("RESPONSE",response.data);

         if(response.data?.status == 1){
            toast_anim_error("Your are alreay registered with us , please Login.")
            setLoading(false);
         }else{
            
            let verify = new firebase.auth.RecaptchaVerifier('recaptcha-container',{ size: "invisible" });
            //console.log("PHONE NO",'+'+values?.phone_no);

              auth.signInWithPhoneNumber(values?.phone_no, verify).then((result) => {
               console.log("REsult",result);
                  setfinal(result);
                  //alert("code sent")
                  toast_anim("Code sent to your phone number.")
                  setshow(true);
                  setLoading(false);
              }).catch((err) => {
               setLoading(false);
                      alert(err);
                      window.location.reload()
            });
         }
         
        //setPost(response.data);
      });


       return false;
   }

   const setOtp = (val) =>{
      console.log("OPTBSADASD ",val)
      setotp(val);
   }
   return( <div className="flex justify-center">
            <div className="flex h-full  w-screen flex-col justify-center bg-light  px-5  md:h-auto md:min-h-0 md:max-w-[480px] md:rounded-xl my-5">
                  {show ?
                  ( <><div style={{ display: show ? "block" : "none" }}>
                     <div className="flex flex-col justify-center align-center">
                        <div className="flex flex-col justify-center align-center">
                           <h1 class="text-2xl font-bold">OTP Verification</h1>
                           <div class="flex flex-col mt-4">
                             <span>Enter the OTP you received at</span>
                             <span class="font-bold">{phone_no}</span>
                            </div>
                        </div>  
                    
                           <OtpInput
                             inputStyle={{  
                               width: '3rem',  
                               height: '3rem',  
                               margin: '20px 1rem',  
                               fontSize: '1rem',  
                               borderRadius: 4,  
                               border: '2px solid rgba(0,0,0,0.3)',                      
                           }}  
                              value={otp}
                              width={'2em'}
                              onChange={setOtp}
                              numInputs={6} 
                              className="m-2 border h-10 w-10 text-center form-control rounded"
                              renderSeparator={<span>-</span>}
                              renderInput={(props) => <input type="text"  style={{width:'2em'}}   {...props}  />}
                             />
                    <br /><br />
                    <Button onClick={ValidateOtp}>Verify</Button>
                </div>
                </div> </>)
                  :
                  (<>
                  {/* <div className="flex justify-center">
                  <h1 className="text-xl font-black text-accent">Login</h1>
                  </div> */}      
                  <form onSubmit={handleSubmit(loginsubmit)}>  
                  {/*<p className="mt-1 mb-8 text-center text-sm text-body  md:text-base">
                     sign in with your Phone number and OTP
                  </p>*/}
                   
                       <div className="mb-5 w-full flex flex-col">
                           <label htmlFor="phone_no" className="block mb-3 text-sm font-semibold leading-none text-body-dark">Phone number <span className="text-red-500">*</span></label>
                           <Controller
                              name="phone_no"
                              control={control}
                              ref={refphone}
                              render={({ field }) => (
                                 <PhoneInput
                                    country="in"
                                    disableDropdown
                                    countryCodeEditable={false}
                                    inputClass="!p-0 ltr:!pr-4 rtl:!pl-4 ltr:!pl-14 rtl:!pr-14 !flex !items-center !w-full !appearance-none !transition !duration-300 !ease-in-out !text-heading !text-sm focus:!outline-none focus:!ring-0 !border !border-border-base !rounded focus:!border-accent !h-12"
                                    dropdownClass="focus:!ring-0 !border !border-border-base !shadow-350"
                                    {...field}
                                 />
                              )}
                           />
                           {errors?.phone_no?.message && 
                           <p className="my-2 text-xs text-start text-red-500">{errors.phone_no?.message}</p>
                           }
                        </div>
                  
                     <div className="mt-8 flex justify-center">
                        {
                        !loading
                        ?
                        <Button className="w-full" >Send OTP</Button>
                        :
                        <Loading className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600" />
                        }
                     </div>
               </form>
               
               </>
               )
            }
            </div>
         </div>)
}

export default SigninwithPhone