import {amount_format} from 'components/config/Common'
import { AiOutlineSchedule } from "react-icons/ai";

const NotifcationAppointmentbadge = ({max, min}:props) => {
   
   max = Number(max)
   min = Number(min)
   
   let def =  max - min
   let disc = (def/max)*100
   disc = Math.round(disc);

   
      return(
       <button type="button" class="relative inline-flex items-center p-2 text-sm font-medium text-center text-white bg-accent rounded-lg hover:bg-accent focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-accent dark:focus:ring-blue-800">
         <AiOutlineSchedule className="w-5 h-5 text-white" stroke="#fff" />
        <span class="sr-only">Pending Appointment</span>
        <div class="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-secondary-color border-2 border-white rounded-full -top-2 -right-2 dark:border-gray-900">0</div>
      </button>
      )
  

}

export default NotifcationAppointmentbadge