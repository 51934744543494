import React, {useState, useEffect , useRef} from "react";
import { Link } from "react-router-dom";

import LeftMenuLayout from 'layouts/LeftMenuLayout';

import classNames from 'classnames';
import * as Yup from 'yup';
import {API_ENDPOINT, toast_anim , toast_anim_error} from 'components/config/Common'
import {Loading} from "components/custom-icons";

import axios from 'axios';  
import {APP_URL, APP_NAME , IMG_URL ,amount_format } from 'components/config/Common'

import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import { toast } from 'react-toastify';
import ScrollPageTop from 'components/ui/ScrollPageTop'

import { yupResolver } from '@hookform/resolvers/yup';
import Input from 'components/ui/input.jsx'
import Label from 'components/ui/label.tsx'
import Button from 'components/ui/Button.tsx'

import {
	Control,
	FieldErrors,
	useForm,
	useFormState,
	useWatch,
	Controller
 } from 'react-hook-form';


function isValid_UPI_ID(upi_Id) {
	//console.log("UPI ID",upi_Id);
    // Regex to check valid
    // upi_Id
    let regex = new RegExp(/^[a-zA-Z0-9.\-_]{2,49}@[a-zA-Z._]{2,49}$/);
 
    // upi_Id
    // is empty return false
    if (upi_Id == null) {
    	//console.log("UPI ID NULL");
        return false;
    }
 
    // Return true if the upi_Id
    // matched the ReGex
    if (regex.test(upi_Id) == true) {
    	  //console.log("UPI ID IS VALID");
        return true;
    }
    else {
    	 //console.log("NOT UPI ID IS VALID");
        return false;
    }
}


Yup.addMethod(Yup.string, "upiValidate", function (errorMessage) {
  return this.test(`test-upi-format`, errorMessage, function (value) {
    const { path, createError } = this;

    return (
      isValid_UPI_ID(value)
    );
  });
});

function equalTo(ref, msg) {
	return this.test({
		name: 'equalTo',
		exclusive: false,
    message: msg || '${path} must be the same as ${reference}',
		params: {
			reference: ref.path
		},
		test: function(value) {
      return value === this.resolve(ref) 
		}
	})
};

Yup.addMethod(Yup.string, 'equalTo', equalTo);


const amountSchema = Yup.object().shape({
	upi_id: Yup.string()
        .required('You must need to provide your upi id.')
        .upiValidate('INvlaid upi Id'),
   upi_id_confirm:Yup.string().equalTo(Yup.ref('upi_id'))
});


let defaultValues = {  
	amount: '',
};

const AddUPIForm = (props) => {
	
	const [access_token, setAccess_token] = useState(localStorage.getItem('access_token'))
	const [user_type, setUser_type] = useState(localStorage.getItem('user_type'))
	const [user_me, setUser_me] = useState(null);
	const [loading, setLoading] = useState(false);
	const [loading_social, setLoading_social] = useState(false);
   const [enabled, setEnabled] = useState(false)
  	const [totalamount,setTotalamount] = useState(0);

	const { register, handleSubmit , control, reset, setValue, setError, formState: { errors },} = useForm({
		defaultValues: defaultValues,
		resolver: yupResolver(amountSchema),
		
  	});


	useEffect(() => {
			//getWalletTotal();
	},[])
	

	
	const onSubmit = (values) => {
		console.log('value', values)
		setLoading(true)		

		let input = values;
     
      setLoading(true)
      axios.post(`${API_ENDPOINT}/add_upi_details`,input,{
         headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer '+access_token, 
         }
      }).then( (result)=> {
         console.log('data=====',result.data)
         setLoading(false)
         console.log('data=====',result)
			if(result.data.status == 1){
				toast_anim(result.data.message);
				props.closeUpifrm();
			}else if(result.data.status == 0){
				toast_anim_error(result.data.message);
			}
      })

	}

	return (<div className="w-full">
					<div className={classNames('w-full p-5 md:p-8 bg-light dark:bg-gray-700 shadow rounded mb-8')}>
						<p className="text-lg lg:text-xl text-heading capitalize mb-2 dark:text-zinc-400">Add UPI Id</p>
						<form onSubmit={handleSubmit(onSubmit)}>
								<div className="flex w-full flex-col">
								

	                         <Input                
	                            label={`UPI Id`}
	                            {...register('upi_id')}
	                            variant="outline"
	                            className="mb-5 w-full"
	                            error={errors.upi_id?.message}
	                            required
	                        />

	                        <Input                
	                            label={`Confirm UPI Id`}
	                            {...register('upi_id_confirm')}
	                            variant="outline"
	                            className="mb-5 w-full"
	                            error={errors.upi_id_confirm?.message}
	                            required
	                        />
	                      </div>
								<div className="flex">
									<div className="flex  align-center justify-center">
									{
									!loading
									?	
									<button data-variant="normal" className="inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-5 py-0 h-12 ltr:ml-auto rtl:mr-auto">Add</button>
									:
									<Loading className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 ltr:ml-auto" />
									}

									<button data-variant="normal" type="button" onClick={props.closeUpifrm} className="ml-2 inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-black text-light border border-transparent hover:bg-black-hover px-5 py-0 h-12 ltr:ml-2 rtl:mr-auto">Close</button>

									
									</div>
								</div>
							</form>
					</div>
			</div>
   );
};

export default AddUPIForm