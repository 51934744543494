import React from 'react';
import {
   APP_URL, 
   BACKEND_URL,
} from 'components/config/Common'
import {CallDecline , CallIcon} from 'components/custom-icons'; 
import { useTimer } from 'react-timer-hook';
import { LazyLoadImage, LazyLoadComponent } from 'react-lazy-load-image-component';

function MyTimer({ expiryTimestamp , closeCalling }) {
  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume,
    restart,
  } = useTimer({ expiryTimestamp, onExpire: () => {
    console.warn('onExpire called');
    //closeCalling();
  }  });


  return (  <div style={{textAlign: 'center'}}>
      <div className="text-white text-lg ">
        <span>{minutes}</span>:<span>{seconds}</span>
      </div>
    </div>);

  }
const Calloutgoing = ({closeCalling,info }) => {
   const time = new Date();
  time.setSeconds(time.getSeconds() + 60);
  console.log("call info",info);

  return (
    <div className="bg-gray-800 h-screen flex flex-col flex-1 items-center justify-start" style={{ backgroundColor:"#3C8986"}}>
      <div className="flex">
        <div>
         <CallIcon fill="#ffffff" />
        </div>
         <div className="text-2xl text-light uppercase mt-4 mb-4">
          OutGoing Session Call
        </div>
      </div>
      <div className="flex w-full flex-wrap justify-center md:w-1/2 mt-10 mb-10">
         <div className="h-40 overflow-hidden  shadow rounded-full bg-light animation-pulse1 w-40 h-auto align-middle border-none flex-wrap flex">
          {info.profile_image
             ?
             <LazyLoadImage src={`${BACKEND_URL}${info.profile_image}`} className="object-cover w-full" alt={info.name}  />
             :
             <LazyLoadImage src={`${APP_URL}/img/Doctor-placeholder-female.jpeg`} alt={info.name} className="shadow rounded-full max-w-full h-auto align-middle border-none" sizes="100vw" />
             }
           {/*<img src="https://www.creative-tim.com/learning-lab/tailwind-starter-kit/img/team-2-800x800.jpg" alt="..." className="shadow rounded-full max-w-full h-auto align-middle border-none" /> */}
         </div>
       </div>     
      <h1 className="text-white text-2xl mb-2">you calling to {info.name} ...</h1>
      {/*<><img src={`${APP_URL}/img/calling.gif`} alt="Calling" className="w-1/2" /></>*/}
      <MyTimer expiryTimestamp={time} closeCalling={closeCalling} />
      <div className="mt-12">
        <button
          className="flex justify-center items-center bg-red-600 rounded-full text-white  w-14 h-14 text-sm"
          onClick={closeCalling}
        >
        <CallDecline fill="#ffffff" className="w-10 h-10 text-center" />
        </button>
        <div className="text-xs text-light text-center">decline</div>
      </div>


    </div>
  );
}

export default Calloutgoing;
