import React, {useState, useEffect} from "react";

import {API_ENDPOINT} from 'components/config/Common'
import Checkbox from 'components/ui/checkbox/checkbox.tsx'
import Radio from 'components/ui/radio/radio.tsx';
import Button from 'components/ui/Button.tsx'
import {CloseIcon} from 'components/custom-icons'
import {isBrowser} from "react-device-detect"
import { BsFillArrowDownSquareFill } from "react-icons/bs";
import {BsFillArrowUpSquareFill} from "react-icons/bs";

const GenderFilter = (props) => {

   //const [brands, setBrands] = useState(null)
   //const [brand_id, setBrand_id] =  useState(null)
   const [isopen, setIsopen] =  useState(true);

   const [gender, setGender] =  useState(null)
  
   

   const handleChange = (e) => {
      //console.log('onchange', e.target.name, e.target.value)
      setGender(e.target.value)
      //setValue([...value, e.target.value])
      //console.log('onchange', value)
      props.handleGenderFilter(e.target.value)
      if(isBrowser){
         setTimeout(() => {
            window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
            });
         }, 10);
      }
   }

   const clearBrandFilter = () => {
      let radio = document.getElementById(gender);
      radio.checked = false;
      props.handleGenderFilter(null) ;
   }

   const toggleOtions = () =>{
      setIsopen(!isopen);
   }

   return(
      <div className="h-full w-full bg-light dark:bg-gray-700 p-2">
         <div className="flex  flex-col" >
             <div className="flex w-full cursor-pointer" onClick={toggleOtions}>
            <div className="text-heading dark:text-zinc-400 uppercase  w-9/12 ">Gender </div>
            <div className="w-3/12 flex justify-center  items-end   ">{isopen ? <BsFillArrowUpSquareFill/> : <BsFillArrowDownSquareFill />}</div>
            </div>
            <div class="border-2 border-gray-200 w-1/4 mt-2 mb-2"></div> 
            {/* <Button 
               size='smaller'
               variant='normal'
               onClick={clearBrandFilter}
            >
            <CloseIcon className="w-4 h-4 pt-0.5 mr-0.5" /> Clear
            </Button> */}
         </div>
         {isopen ?
         <ul class="text-xs" >
          
            <li class="py-1 " key="">

               <div class="flex w-full items-center py-2 font-semibold text-body-dark outline-none transition-all ease-in-expo  focus:text-accent focus:outline-none focus:ring-0 ltr:text-left rtl:text-right text-body-dark text-sm text-body dark:text-zinc-400">
                
                  <Radio
                     name='gender'
                     label="Male"
                     id="male"
                     value="male"
                     className="mr-2"
                     onChange={handleChange}
                  />
                  
               </div>
            </li>
             <li class="py-1" key="">
               <div class="flex w-full items-center py-2 font-semibold text-body-dark outline-none transition-all ease-in-expo  focus:text-accent focus:outline-none focus:ring-0 ltr:text-left rtl:text-right text-body-dark text-sm text-body dark:text-zinc-400">
                
                  
                  <Radio
                     name='gender'
                     label="FeMale"
                     id="female"
                     value="female"
                     className="mr-2"
                     onChange={handleChange}
                  />
                 
               </div>
            </li>
             <li class=" py-1 " key="">
               <div class="flex w-full items-center py-2 font-semibold text-body-dark outline-none transition-all ease-in-expo  focus:text-accent focus:outline-none focus:ring-0 ltr:text-left rtl:text-right text-body-dark text-sm text-body dark:text-zinc-400">
                
                
                  <Radio
                     name='gender'
                     label="Non Binary"
                     id="non-binary"
                     value="non-binary"
                     className="mr-2"
                     onChange={handleChange}
                  />
               </div>
            </li>
           
         </ul>
         :
         null
      }
         
      </div>
   )
}

export default GenderFilter