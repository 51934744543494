import React, {useState, useEffect} from "react";
import {API_ENDPOINT} from 'components/config/Common'
import cn from 'classnames';

import RelatedExpertCard from 'components/cards/RelatedExpertCard'

const RelatedProducts = ({
   slug,specilization
} :props) => {
   const [products, setProducts] = useState(null)
   useEffect(() => {
      const getRelatedProducts = () => {
      //console.log(currentProduct, category)
      fetch(`${API_ENDPOINT}/get_similar_experts/${slug}`,{
         method: 'GET',
         headers : {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
         }
      }).then(function (response) {
            return response.json();
      }).then( (result)=> {
         //console.log('insert data qty=====',result)
         setProducts(result.data)
      })
   }
      getRelatedProducts()
   },[slug, specilization])
   
   
   return(
      <>
         {
         products && 
         <div className="w-full flex flex-col">
            <h2 className="text-lg text-heading tracking-tight font-semibold mb-6">
            Similar Experts
            </h2>
            <div
            className={cn(
            'grid grid-cols-1 md:grid-cols-1 xl:grid-cols-1 gap-4 lg:grid-cols-1 2xl:grid-cols-1 !gap-3')}
            >
            {
               products.map((item, key) => (
                  <RelatedExpertCard item={item} key={`doctor-${item.id}`} />
               ))
            }
            </div>
         </div>
         }
      </>
   )
}

export default RelatedProducts;