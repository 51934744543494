import React, {useState, useEffect ,useRef} from "react";

import { Link, useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import {
   Control,
   FieldErrors,
   useForm,
   useFormState,
   useWatch,
   Controller
 } from 'react-hook-form';
 
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';  
import {Links} from 'routes/PathLink'
import {
   API_ENDPOINT, 
   random_str, 
   toast_anim_error, 
   RAZOR_KEY, 
   loadScript,
   register_valid,
   register_fee,
   renew_per,
   pdfdoconly,
   pngjpgonly,
} from 'components/config/Common'

import 'react-phone-input-2/lib/bootstrap.css'
import Button from 'components/ui/Button.tsx'
import {useAuth} from 'contexts/provider/AuthProvider'
import SelectInput from 'components/ui/select-input.jsx'
import TextArea from 'components/ui/text-area';
import Label from 'components/ui/label.tsx'
import {deviceDetect} from "react-device-detect"
import { TagsInput } from "react-tag-input-component";
import { useDoctorReg } from "contexts/doctorregistration/doctor-reg";
import { produce } from 'immer';
import Input from 'components/ui/input.jsx'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/bootstrap.css'

const SignupSchema = Yup.object().shape({
   name: Yup.string()
      .required('You must need to provide your  name.')
      .min(2, 'Name is too short!')
      .max(50, 'Name is too long!'),
   gender: Yup.string()
    .required("Please choose gender it is Required"),
  
});


const defaultValues = {  
   name: '',
   gender:'',
   intro:'',
 };

const RegisteIntro = (props) => {
   const navigate = useNavigate();
   const [loading, setLoading] = useState(false);
   const [selected, setSelected] = useState([]);

	const { login } = useAuth();
   const refphone = useRef(null);
   
   const { register, handleSubmit , control, reset, setValue, setError, formState: { errors },} = useForm({
      defaultValues: defaultValues,
      resolver: yupResolver(SignupSchema),
      shouldFocusError:false,
   });
   const {form,setForm} = useDoctorReg();

   useEffect(() => {
      if(localStorage.getItem('access_token')){
         return navigate(Links.HOME, { replace: true });
      }
   })

   useEffect(() => {
      reset(defaultValues)
   },[])

   
   const dealerSubscription = async (values) => {
      console.log("STEP THREE FORM VALUE",values);
      console.log("FORM DATA",form);
      //setForm(values);
      setForm(
          produce((formState) => {
            formState.steps.intro = {
              values,
              valid: false,
              dirty: false,
            };
          }));
     props.setActivestepfun(3);
   }

   const onSubmit = async (values) => {
       props.setActivestepfun(3);
     
   }
  
   return(
      <>
        
         <div className="flex justify-center">
            <div className="flex h-full min-h-screen w-screen flex-col justify-center bg-light py-6 px-5 sm:p-8 md:h-auto md:min-h-0 md:max-w-[580px] md:rounded-xl my-5">
               <div className="flex justify-center">
               <h1 className="text-xl font-black text-accent">Introduction</h1>
               </div>   
               <form onSubmit={handleSubmit(dealerSubscription)}>
                   <div className="mb-5">
                    <Input                
                     label={`Name`}
                     {...register('name')}
                     variant="outline"
                     className="mb-5"
                     error={errors.name?.message}
                     required
                  />    
                  {form?.steps?.main?.values?.from == 'phoneno'?
                  <div className="mb-5">
                       <Input                
                           label={`Email`}
                           {...register('email')}
                           variant="outline"
                           className="mb-5"
                           type="email"
                           error={errors.email?.message}
                           required
                        />
                  </div>   
                  :
                  <div className="mb-5 w-full flex flex-col">
                        <label htmlFor="phone_no" className="block mb-3 text-sm font-semibold leading-none text-body-dark">Phone number <span className="text-red-500">*</span></label>
                        <Controller
                           name="phone_no"
                           control={control}
                           ref={refphone}
                           render={({ field }) => (
                              <PhoneInput
                                 country="in"
                                 disableDropdown
                                 countryCodeEditable={false}
                                 inputClass="!p-0 ltr:!pr-4 rtl:!pl-4 ltr:!pl-14 rtl:!pr-14 !flex !items-center !w-full !appearance-none !transition !duration-300 !ease-in-out !text-heading !text-sm focus:!outline-none focus:!ring-0 !border !border-border-base !rounded focus:!border-accent !h-12"
                                 dropdownClass="focus:!ring-0 !border !border-border-base !shadow-350"
                                 {...field}
                              />
                           )}
                        />
                        <p className="my-2 text-xs text-start text-red-500">{errors.phone_no?.message}</p>
                     </div>
                  }
                  <div className="mb-5">
                  <label for="name" class="mb-3 block text-sm font-semibold leading-none text-body-dark dark:text-zinc-400">Gender<span class="text-red-500"> *</span></label>
                  <ul class="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                      <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                          <div class="flex items-center pl-3">
                              <input id="horizontal-list-radio-license" {...register('gender')}  type="radio" value="male" name="gender" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                              <label for="horizontal-list-radio-license" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Male </label>
                          </div>
                      </li>
                      <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                          <div class="flex items-center pl-3">
                              <input id="horizontal-list-radio-id" type="radio" value="female" {...register('gender')} name="gender" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                              <label for="horizontal-list-radio-id" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Female</label>
                          </div>
                      </li>
                      <li class="w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600">
                          <div class="flex items-center pl-3">
                              <input id="horizontal-list-radio-millitary" type="radio" value="non-binary" {...register('gender')} name="gender" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                              <label for="horizontal-list-radio-millitary" class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Non Binary</label>
                          </div>
                      </li>
                  </ul>
                  <p className="my-2 text-xs text-start text-red-500">{errors.gender?.message}</p>

                  </div>

                   <TextArea
                   name="bio"
                   placeholder="Tell us about yourself"
                   {...register('bio')}
                    />
                   </div>
                    <div className="flex w-full content-end ">
                     
                       <div className="w-1/4 ml-2">
                        <Button 
                           className="w-full bg-black self-end"
                           loading={loading}
                        >
                        Next
                        </Button>
                     </div>
                  </div>
               </form>
              
            </div>
         </div>
      </>
   )
}

export default RegisteIntro