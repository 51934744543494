import { Link } from "react-router-dom";
import {APP_URL, APP_NAME} from '../components/config/Common'
import { useSiteSettings } from '../contexts/site-settings/site-settings';


const Logo = ({className , classNametext}) => {
  const { sitelogo } = useSiteSettings();
  return (
    <Link to="/" class={`inline-flex mx-auto lg:mx-0 ${className}`}>
      <span className="flex relative w-44 overflow-hidden md:w-60 items-center">
        {sitelogo ?
        ( <img src={`${process.env.REACT_APP_IMG_URL+sitelogo}`} alt={`${APP_NAME} Logo`} />)
        :
        ( <h1 className={`${classNametext ? classNametext : 'text-3xl' }  `}>HealthLogist</h1>)
        }
      </span>
    </Link>
  );
};

export default Logo;
