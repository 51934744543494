import React, { useEffect, useState } from "react";
import {API_ENDPOINT, toast_anim, toast_anim_error,amount_format} from 'components/config/Common'
import {Links} from 'routes/PathLink'
import ModalView from '_utils/ModalView'
import { Link , useLocation } from "react-router-dom";
import ReportSession from "components/calling/report/ReportSession";

const SessionSuccess = () => {
   //let participantNameId = Math.floor(Math.random() * 1000000);
  const [access_token, setAccess_token] = useState(localStorage.getItem('access_token'))
  const [user_id, setUser_id] = useState(localStorage.getItem('user_id'));
  const [user_name, setUserName] = useState(localStorage.getItem('user_name'));

  const [opensuccess ,setOpensuccess] = useState(false);
  const [sessioninfo,setSessioninfo] = useState(null);
  const {state} = useLocation();
 

   useEffect(() => {
    console.log("state From PRevious page ",state?.data);
    if(sessioninfo){
      setSessioninfo(sessioninfo)
    }else{
      setSessioninfo(state?.data)
    }
    
  }, [state]);
  
  const isMobile = window.matchMedia(
    "only screen and (max-width: 768px)"
  ).matches;

  const closeSuccess = () =>{
    setOpensuccess(false);
    //navigate(Links.MYDASHBOARD);
  }

  const openSuccess = (data) =>{
    setOpensuccess(true);
  }

  return (
    <>
    <div className="flex flex-col justify-center items-center mt-10">
      <div class="svg-container">    
          <svg class="ft-green-tick" xmlns="http://www.w3.org/2000/svg" height="100" width="100" viewBox="0 0 48 48" aria-hidden="true">
              <circle class="circlesuccess" fill="#5bb543" cx="24" cy="24" r="22"/>
              <path class="tick" fill="none" stroke="#FFF" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M14 27l5.917 4.917L34 17"/>
          </svg>
      </div>
      <h1 className="text-2xl mt-4">Thank you</h1>
      <p className="text-dark">Your session has ended!</p>

      <div className="flex w-full h-48 justify-center items-center">
         
        <Link to={Links.MYDASHBOARD} className="relative inline-flex items-center justify-center p-4 px-6 py-3 overflow-hidden font-medium text-accent transition duration-300 ease-out border-2 border-accent rounded-full shadow-md group">Go To Dashboard
        </Link>
        <Link to="#" onClick={()=> openSuccess(true)} className="ml-4 relative inline-flex items-center justify-center p-4 px-6 py-3 overflow-hidden font-medium text-accent transition duration-300 ease-out border-2 border-accent rounded-full shadow-md group">Report This Session
        </Link>
      </div>
      </div>
      <ModalView open={opensuccess} onClose={closeSuccess}>
      <ReportSession closeWithdraw={closeSuccess} sessioninfo={sessioninfo}/>
      </ModalView>
    </>
  );
};

export default SessionSuccess;
