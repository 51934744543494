import React, { useState, useRef, useEffect } from "react";
import Lottie from "lottie-react";
import { createPopper } from "@popperjs/core";
import { createMeeting, getToken, validateMeeting } from "api";
import { Link } from "react-router-dom";
import ModalView from '_utils/ModalView'
import Calloutgoing from "components/calling/screens/Calloutgoing";
import {API_ENDPOINT, toast_anim, toast_anim_error,amount_format,APP_URL} from 'components/config/Common'
import ConfirmBoxSessionPrice from "components/calling/ConfirmBoxSessionPrice";
import {useOutgoingModal} from "contexts/outgoingmodal/outgoingmodal";
import ExpertStatusRadio from "./ExpertStatusRadio";
import {useAuth} from 'contexts/provider/AuthProvider'

const ExpertStatus = ({info}) => {
  const [access_token, setAccess_token] = useState(localStorage.getItem('access_token'))
  const [user_id, setUser_id] = useState(localStorage.getItem('user_id'));
  const [user_type, setUser_type] = useState(localStorage.getItem('user_type'));
  const { isUser, loginToken } = useAuth();

  const [token,setToken] = useState('');
  const [meetingId,setMeetingId] = useState('');
  const [opencalling,setOpencalling] = useState(false);
  const [doctorinfo,setDoctorinfo] = useState(info);

  const {calldeclined, updateCallDeclined} = useOutgoingModal();

    const [avaliable_status, setAvaliable_status] = useState('');


  console.log("loginToken",loginToken);
   useEffect(() => {
    getAvailStatus();
   },[]); 
 

   const getAvailStatus = () => {
    //console.log(user_id)

    fetch(`${API_ENDPOINT}/get_availability_status`,{
      method: 'GET',
      headers : {        
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${access_token}`, 
      }
    }).then(function (response) {
        return response.json();
    }).then( (result)=> {
      console.log('products======',result.data)
      if(result.status === 1){
         console.log('avail status',result.data?.availability_status);
        setAvaliable_status(result.data?.availability_status);
      }else{
        //navigate(Links.HOME);
      }
    })
  }
  const closeCalling = () =>{
    setOpencalling(false);
    getAvailStatus();
  }


  const openExpertOnline = () =>{
    setOpencalling(true);
  }

 
  return (
    <>  
      {user_type == 'expert'?
         <>
         <button onClick={()=>openExpertOnline()} title="Online Status"
                className={`flex flex-col fixed z-100 top-22 right-8 ${avaliable_status == 'online' ? ' bg-green-600 ' : ' bg-red-600 ' } w-20 h-20 rounded-full drop-shadow-lg flex justify-center items-center text-white text-xl hover:bg-green-700 hover:drop-shadow-2xl  `}>{avaliable_status} </button>
        </>
        :
         null
      }
       
        <ModalView open={opencalling} onClose={closeCalling}>
          <ExpertStatusRadio closeCalling={closeCalling} info={info} /> 
        </ModalView>
    </>
  );
};

export default ExpertStatus;
