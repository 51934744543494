import React, {useState, useEffect , useRef} from "react";
import { Link } from "react-router-dom";

import LeftMenuLayout from 'layouts/LeftMenuLayout';

import classNames from 'classnames';
import * as Yup from 'yup';
import {API_ENDPOINT, toast_anim , toast_anim_error} from 'components/config/Common'
import {Loading} from "components/custom-icons";

import axios from 'axios';  
import {APP_URL, APP_NAME , IMG_URL ,amount_format } from 'components/config/Common'

import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import { toast } from 'react-toastify';
import ScrollPageTop from 'components/ui/ScrollPageTop'

import { yupResolver } from '@hookform/resolvers/yup';
import Input from 'components/ui/input.jsx'
import Label from 'components/ui/label.tsx'
import Button from 'components/ui/Button.tsx'
import TextArea from 'components/ui/text-area';
import { Rating } from 'react-simple-star-rating'

import {
	Control,
	FieldErrors,
	useForm,
	useFormState,
	useWatch,
	Controller
 } from 'react-hook-form';

const amountSchema = Yup.object().shape({
	rating: Yup.number()
      .typeError('Amount must be a number')
      .positive('Amount must be positive')
      .required('Amount is required'),
});

/*
	resolver: yupResolver(amountSchema),

*/
let defaultValues = {  
	amount: '',
};

const SessionReviewFrm = ({closeWithdraw,sessioninfo}) => {
	
	const [access_token, setAccess_token] = useState(localStorage.getItem('access_token'))
	const [user_type, setUser_type] = useState(localStorage.getItem('user_type'))
	const [user_me, setUser_me] = useState(null);
	const [loading, setLoading] = useState(false);
	const [loading_social, setLoading_social] = useState(false);
   const [enabled, setEnabled] = useState(false)
   const [rating, setRating] = useState(0);

	const { register, handleSubmit , control, reset, setValue, setError, formState: { errors },} = useForm({
		defaultValues: defaultValues,
	
  	});


	useEffect(() => {
			
	},[])
	

	

	const onSubmit = (values) => {
		console.log('value', values)
		setLoading(true)		
		if(rating == 0){
			toast_anim_error("Please choose rating!");
			setLoading(false);		
			return true;
		}
		let input = values;
      input = {...input, rating:rating,expert_id:sessioninfo?.expert_id,call_id:sessioninfo?.call_id}

      setLoading(true)
      axios.post(`${API_ENDPOINT}/add_rating`,input,{
         headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': 'Bearer '+access_token, 
         }
      }).then( (result)=> {
         console.log('data=====',result.data)
         setLoading(false)
         console.log('data=====',result)
			if(result.data.status == 1){
				toast_anim(result.data.message);
				closeWithdraw();
			}else if(result.data.status == 0){
				toast_anim_error(result.data.message);
			}
      })
	}


	// Catch Rating value
  const handleRating = (rate: number) => {
    setRating(rate)

    // other logic
  }
  // Optinal callback functions
  const onPointerEnter = () => console.log('Enter')
  const onPointerLeave = () => console.log('Leave')
  const onPointerMove = (value: number, index: number) => console.log(value, index)

	return (<div className="w-full">
					<div className={classNames('w-full p-5 md:p-8 bg-light dark:bg-gray-700 shadow rounded mb-8')}>
						<p className="text-lg lg:text-xl text-heading capitalize mb-2 dark:text-zinc-400">Rating Session</p>
						<div className="border-b-[1px] mb-2 pb-2">
						{sessioninfo?.doctorinfo?.name ?
							(<>
								<div className="text-accent">
								<div className="flex w-full flex-wrap justify-start w-1/2">

                                   <div className="h-8 overflow-hidden shadow rounded-full w-8	 h-auto align-middle border-none flex-wrap flex">
                                      {sessioninfo?.doctorinfo?.profile_image?
                                       <img src={`${IMG_URL}${sessioninfo?.doctorinfo?.profile_image}`} className="object-cover w-full" alt={sessioninfo?.doctorinfo?.name}  />
                                       :
                                       <img src={`${APP_URL}/img/Doctor-placeholder-female.jpeg`} alt="{sessioninfo?.doctorinfo?name}" className="shadow rounded-full max-w-full h-auto align-middle border-none" sizes="100vw" />
                                       }
                                   </div>
                                 </div>     
								Doctor - {sessioninfo?.doctorinfo?.name}</div>
								<div className="text-gray-400 text-sm">Call Time: {sessioninfo?.call_time}</div>

							 </>)
							:
							null
						}
						</div>
						<form onSubmit={handleSubmit(onSubmit)}>
								<div className="flex flex-col w-full">
								<div
									  className="flex w-full mb-2"
									>
								 <Rating
						        onClick={handleRating}
						       initialValue={rating}
						       className="flex w-full ratingstar"
						      />
						      </div>
						      <div>
								 <TextArea
		                   	name="user_comment"
		                   	placeholder="Tell us about how was session?"
		                   	className="w-full  mb-2"
		                   	{...register('user_comment')}
		                    />
		                    </div>
	                      </div>
									<div className="flex">
									<div className="flex  align-center justify-center">
									{
									!loading
									?	
									<button data-variant="normal" className="inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-accent text-light border border-transparent hover:bg-accent-hover px-5 py-0 h-12 ltr:ml-auto rtl:mr-auto">Save</button>
									:
									<Loading className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 ltr:ml-auto" />
									}

									<button data-variant="normal" onClick={closeWithdraw} className="ml-2 inline-flex items-center justify-center shrink-0 font-semibold leading-none rounded outline-none transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700 bg-black text-light border border-transparent hover:bg-black-hover px-5 py-0 h-12 ltr:ml-2 rtl:mr-auto">Close</button>

									
									</div>
								</div>
							</form>
					</div>
			</div>
   );
};

export default SessionReviewFrm