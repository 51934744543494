import React, {useState} from "react";
import {APP_URL, remove_overflow, EXPERT_LABEL} from 'components/config/Common'
import { useNavigate } from "react-router-dom";
import {useAuth} from 'contexts/provider/AuthProvider';
import { Link } from "react-router-dom";
import {CloseIcon} from 'components/custom-icons';
import {Links} from 'routes/PathLink';
import { Drawer } from "flowbite";
import GenderFilter from "./GenderFilter";
import LanguageFilter from './LanguageFilter';
import SortBy from "./SortBy";
import { BiDownArrow ,BiFilter } from "react-icons/bi";
import Checkbox from 'components/ui/checkbox/checkbox.tsx'
import {useSpeclizationFilter} from 'contexts/speclization-filter/speclization-filter.context.tsx';



const DoctorFilterDrawer = (props) => {
   //const [sideMenu, SetSideMenu] = useState(SideMenu)
   const [drawer , setDrawer] = useState(null);
   const navigate = useNavigate();
   const { logout } = useAuth();
   const [gender,setGender] = useState(null);
   const [languages, setLanguages] = useState([]);
   const [languageslbl, setLanguageslbl] = useState([]);
   const [languageslen, setLanguageslen] = useState(0);
   const [is_online, setIsonline] = useState('no');
   const [sortbypopular, setSortbypopular] = useState('no');
   const [sortprice, setSortprice] = useState(null);

   //const [sortbypopular, setSortbypopular] = useState('no');
   const {searchgender,setSearchgender, searchlangues,
      setSearchlangues,searchlangueslen,
      searchlangueslbl, setSearchlangueslbl,
      setSearchlangueslen,
   searchonlineonly,
      setSearchonlineonly,
    sortpopular ,
      setSortpopular,
   sortsessionprice ,
      setSortsessionprice} = useSpeclizationFilter();


   const logoutUser = () => {
      let user_name = localStorage.getItem("user_name")
      localStorage.removeItem("access_token")
      localStorage.removeItem("user_type")
      localStorage.removeItem("user_id")
      localStorage.removeItem("user_name")
      logout()
      remove_overflow()
      //setIsLogin(false)
      navigate(
         Links.LOGOUT,
         {
            state: { user_name: user_name },
            replace: true 
         },
      );
   }

   const go_to_link = (link) => {
      //console.log(link)
      remove_overflow()
      navigate(link);
   }

   const handleBrandFilter = (languagesp) => {
      console.log('language======',languagesp)

      if(languagesp.checked == true){
         languages.push(languagesp.id);
         languageslbl.push(languagesp.lan);

      }else{
         languages.pop(languagesp.id);
         languageslbl.pop(languagesp.lan);
      }

      setLanguages(languages);
      setLanguageslbl(languageslbl);
      setLanguageslen(languages.length);
      //console.log('language list ======',languages);
   }

     const handleGenderFilter = (genderval) => {
      //console.log('brand_id======',genderval);
      setGender(genderval);
      console.log(genderval);
   }

   const handleOnlineFilter = (is_online) => {
      //console.log("IS Njhsdasd",is_online);
      setIsonline(is_online);
   }

   const handlePopularSort = (sort_by_popular) => {
      setSortbypopular(sort_by_popular);
   }

   const handlePriceSort = (sort_by_price) => {
      let data_sort = JSON.parse(sort_by_price);
      //console.log("SORT BY SESSION PRICE",data_sort);
      //console.log("SORT BY SESSION PRICE",data_sort?.name);
      //console.log("SORT BY SESSION PRICE Direction",data_sort?.direction);
      setSortprice(data_sort?.direction);
      //setSortbypopular(sort_by_popular);
   }


  /* const handleSortSelect = (val) =>{
      console.log("SORT parameter",val?.id);
      setSortparam(val?.id);
   }
   */


   const openLeftMenu = () => {
      const options = {
         placement: 'left',
         backdrop: true,
         bodyScrolling: false,
         edge: false,
         edgeOffset: '',
         backdropClasses: 'bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-30',
       };
     const $targetEl = document.getElementById('drawer-filter');
     const drawer: DrawerInterface = new Drawer($targetEl, options);
     setDrawer(drawer);
     drawer.show();
   }

   const closeLeftDrawer = () => {
      //console.log('close drawer')
     /* const options1 = {
          placement: 'left',
          backdrop: false,
          bodyScrolling: true,
          edge: false,
          edgeOffset: '',
          backdropClasses: 'bg-gray-900 bg-opacity-0 dark:bg-opacity-0 fixed',
        };
      const $targetEl = document.getElementById('drawer-filter');
      const drawer: DrawerInterface = new Drawer($targetEl, options1);
      */
      drawer.hide();
   }

   const handleChange = (e) =>{

   }

   const filterSeleted = () => {
      setSearchgender(gender);
      setSearchlangues(languages);
      setSearchlangueslen(languages.length);
      setSearchlangueslbl(languageslbl);
      setSearchonlineonly(is_online);
      setSortpopular(sortbypopular);
      setSortsessionprice(sortprice);
      closeLeftDrawer();
   }

   const claerSeleted = () => {
      setSearchgender('');
      setSearchlangues([]);
      setSearchlangueslen(0);
      setSearchonlineonly('no');
      setSortpopular('no');
      setSearchlangueslbl('');
      setSortprice(null);
      closeLeftDrawer();
   }

   return (<><div class="text-center">
               <button class="flex items-center text-white bg-accent hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800" type="button" data-drawer-target="drawer-filter" data-drawer-show="drawer-filter" aria-controls="drawer-filter"  onClick={openLeftMenu}>
                 <BiFilter size={30}/> Filter
               </button>
            </div>
         <div id="drawer-filter" aria-hidden="true" class="fixed  top-0 left-0  z-40 h-screen p-4 overflow-y-auto transition-transform -translate-x-full bg-white w-80 dark:bg-gray-800" tabindex="-1" aria-labelledby="drawer-label">
            <h5 id="drawer-label" class="inline-flex items-center mb-4 text-base font-semibold text-gray-500 dark:text-gray-400">
               Filter {EXPERT_LABEL}
            </h5>
            <button type="button" data-drawer-hide="drawer-filter" aria-controls="drawer-filter" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 right-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white" onClick={closeLeftDrawer} >
               <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
               </svg>
               <span class="sr-only">Close menu</span>
            </button>
            <div className="flex divide-y flex-col">
               <div className="px-1 pb-6">
                  <GenderFilter handleGenderFilter={handleGenderFilter}/>
                </div>
                <div className="px-1 pb-6 pt-2">
                  <SortBy handleOnlineFilter={handleOnlineFilter} handlePopularSort={handlePopularSort} handlePriceSort={handlePriceSort} />
               </div>
               <div className="px-1 pb-14 mb-4">
                  <LanguageFilter handleBrandFilter={handleBrandFilter} />
               </div>
                   <div class='fixed bottom-0 bg-white'>
                     <button class='my-8 float-right px-5 py-2 bg-gray-500 text-white text-sm font-bold tracking-wide rounded-full focus:outline-none ml-3' onClick={claerSeleted}>Clear All</button>
                      <button class='my-8 ml-auto px-5 py-2 bg-red-500 text-white text-sm font-bold tracking-wide rounded-full focus:outline-none' onClick={filterSeleted}>Filter</button>
                      <button class='bottom-0 my-8 float-right px-5 py-2 bg-black text-white text-sm font-bold tracking-wide rounded-full focus:outline-none ml-3' onClick={closeLeftDrawer}>Cancel</button>
                  </div>
               </div>

         </div>

       </>)
}

export default DoctorFilterDrawer;