import React, {useState, useEffect} from "react";
import Header from "layouts/Header";
import Footer from "layouts/Footer";

import {APP_URL, APP_NAME, API_ENDPOINT, IMG_URL} from 'components/config/Common'
import Helmet from 'react-helmet'
import { ReactSEOMetaTags } from 'react-seo-meta-tags'
import ScrollPageTop from 'components/ui/ScrollPageTop'
import {Links} from 'routes/PathLink'
import {Loading} from 'components/custom-icons'
import Seo from 'components/seo/seo'
import { Disclosure } from '@headlessui/react'

import { AiOutlineArrowDown } from "react-icons/ai";
   
const Faq = (props) => {
   
   const [loading, setLoading] = useState(false)
   const [details, setDetails] = useState(null)
   const [metaTitle, setMetaTitle] = useState('')
	const [metaDesc, setMetaDesc] = useState('')

   useEffect(() => {
      getPageDetails()
   },[]);

   const getPageDetails = () => {
      setLoading(true)
      fetch(`${API_ENDPOINT}/page_by_slug/faq`,{
			method: 'GET',
			headers : {        
				'Content-Type': 'application/json',
				'Accept': 'application/json', 
			}
		}).then(function (response) {
				return response.json();
		}).then( (result)=> {
			//console.log('slider======',result)
         setLoading(false)
			setDetails(result.data)
         setMetaTitle(result.data.meta_title ? result.data.meta_title : result.data.title)
			setMetaDesc(result.data.meta_description ? result.data.meta_description : result.data.content)
		})
   }

   return(
      <ScrollPageTop>
      <Seo 
         title={`${metaTitle} - ${APP_NAME}`}
         desc={metaDesc && metaDesc.substring(0, 200)}
         url={`${APP_URL}/faq`}
         image={`${IMG_URL}${details?.image}`}
      />
      <Header hidesearch="true" />
      {
         loading ?
         <div className="w-full h-96 flex items-center justify-center">
            <Loading className="w-10 h-10 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 inline" />
         </div>
         :
         <section className="mx-auto w-full max-w-1920 bg-light py-8 px-4 lg:py-10 lg:px-8 xl:py-14 xl:px-16 2xl:px-20">
            <h1 className="mb-4 text-heading sm:mb-5 ">
            {details?.title}
            </h1>
            {details?.image ?
                <div className="flex items-center justify-center mb-5">
                  <img src={`${IMG_URL}${details?.image}`} />
               </div>
               :
               null
            }
            
            {<div className="leading-loose text-body-dark" dangerouslySetInnerHTML={{__html:details?.content}} />}
            
         </section>
      }
      <div className="w-full px-4 pt-16 mb-10">
      <div className="w-full rounded-2xl bg-white p-2">
        <Disclosure>
         {({ open }) => (
           /* Use the `open` state to conditionally change the direction of an icon. */
           <>
             <Disclosure.Button className="flex w-full justify-between rounded-lg bg-accent/[0.4] px-4 py-2 text-left text-xl  hover:bg-accent/[0.4] focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <span>What does it offer?</span>
               <AiOutlineArrowDown className={open ? 'rotate-90 transform' : ''} />
             </Disclosure.Button>
             <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">A unique peer support platform that offers you a safe space to express your feelings, connect with others, and access to expert guidance for mental health and self-improvement.</Disclosure.Panel>
           </>
         )}
       </Disclosure>
       <Disclosure as="div" className="mt-2">
         {({ open }) => (
           /* Use the `open` state to conditionally change the direction of an icon. */
           <>
             <Disclosure.Button className="flex w-full justify-between rounded-lg bg-accent/[0.4] px-4 py-2 text-left text-xl  hover:bg-accent/[0.4] focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <span>Who are experts?</span>
               <AiOutlineArrowDown className={open ? 'rotate-90 transform' : ''} />
             </Disclosure.Button>
             <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">Experts consist of a diverse panel of experienced professionals, including therapists, counselling psychologists, life coaches, career coaches, relationship coaches, and sex educators. They have expertise in various areas addressing issues ranging from relationship problems, self-esteem, to anxiety and depression. They are here to provide you with the support you need on your mental health and self-improvement journey.</Disclosure.Panel>
           </>
         )}
       </Disclosure>
        <Disclosure as="div" className="mt-2">
         {({ open }) => (
           /* Use the `open` state to conditionally change the direction of an icon. */
           <>
             <Disclosure.Button className="flex w-full justify-between rounded-lg bg-accent/[0.4] px-4 py-2 text-left text-xl  hover:bg-accent/[0.4] focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <span>Can I remain anonymous while using it?</span>
               <AiOutlineArrowDown className={open ? 'rotate-90 transform' : ''} />
             </Disclosure.Button>
             <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
             Yes, you have the option to remain anonymous or use pseudonyms when sharing your thoughts and experiences on it. We understand the importance of providing a space where you can express yourself freely without the pressure of revealing your identity.
             </Disclosure.Panel>
           </>
         )}
       </Disclosure>
       </div>
       </div>
      <Footer />
      </ScrollPageTop>
   )
}

export default Faq