import {amount_format} from 'components/config/Common'
import React, {useState, useEffect} from "react";
import {APP_URL,  API_ENDPOINT, LIMIT , showRating , date_format} from 'components/config/Common'
import InfiniteScroll from "react-infinite-scroll-component";
import {Loading} from "components/custom-icons";


const ProductRatings = ({slug}) => {
    const [accessToken] = useState(localStorage.getItem('access_token'))
    const [productratings, setProductratings] = useState(null)
    const [totalRec, setTotalRec] = useState(0)
    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)

     useEffect(() => {
          getProductRatings()
       },[slug]);
     
     const getProductRatings = () => {
        let paginate = `page=${page}&limit=${LIMIT}`;
          fetch(`${API_ENDPOINT}/get_experts_reviewby_slug/${slug}?${paginate}`,{
             method: 'GET',
             headers : {        
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer '+accessToken, 
             }
          }).then(function (response) {
             return response.json();
          }).then( (result)=> {
             //console.log('data=====',result)
             if(result.status === 1){
                setProductratings(result.data);
                let nextPage = page + 1
                setPage(nextPage)

                if(result.data.length < LIMIT){
                   setHasMore(false)
                }else{
                   setHasMore(true)
                }
                setTotalRec(result?.length)
             }
          })
       }

       const fetchData = () => {
      console.log('page==',page)
        //this.getProducts(this.state.page)
      
         getProductRatings(page);
    }

      return(
         <div className="flex w-full flex-col md:flex-row">
        
          <div className="w-full">

         <article>
            <div name="grid"  className="px-4 xl:px-0 mt-2">
                        <div className="w-full pb-6">
                  {
                  productratings  ?
                  <InfiniteScroll
                            dataLength={productratings.length} //This is important field to render the next data
                            next={fetchData}
                            hasMore={hasMore}
                            loader={
                                <h4 className="h-20 mt-5"><Loading className="w-5 h-5 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-assent-600 inline" />Loading...</h4>
                            }
                     scrollThreshold={0.7}
                     style={{ overflowY: 'hidden' }}
                            >
                            <div layout className="grid grid-cols-1 gap-1 ">
                            {
                                productratings && productratings.map((item, key) => (
                            <>
                                 <div class="flex items-center mb-2 space-x-4">
                                     <img class="w-10 h-10 rounded-full" src={`${APP_URL}/img/48X48_.jpeg`} alt="" />
                                     <div class="space-y-1 font-medium dark:text-white ">
                                         <p className="capitalize">{item.user_name} 
                                            <div class="flex items-center mb-1">{showRating(item.rating)}</div>
                                        </p>
                                     </div>
                                 </div>
                               
                                 <footer class="mb-1 text-sm text-gray-500 dark:text-gray-400"><p>Reviewed on <time datetime="2017-03-03 19:00">{date_format(item?.created_at)}</time></p></footer>
                                 <p class="mb-2 text-gray-500 dark:text-gray-400">{item?.user_comment}</p>
        
                            </>
                                ))
                            }                     
                            </div>      
                  </InfiniteScroll>               
                  :
                    <></>
                  }
                        </div>
                    </div>

         </article>


          </div>
         </div>
      )
  
}

export default ProductRatings