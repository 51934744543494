import React from "react";
import {BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {Links} from './PathLink'

import Home from "../pages/Home";
import Logout from "../pages/common/Logout";
import Comingsoon from "../pages/common/Comingsoon";
import {Authentication} from "./Authentication";

//import DoctorRegister from '../pages/auth/DoctorRegister'
import Contact from "../pages/static-pages/contact";
import About from "../pages/static-pages/About";
import Terms from '../pages/static-pages/Terms'
import Privacy from '../pages/static-pages/Privacy'

import RegisterSuccess  from '../pages/auth/RegisterSuccess'
import DoctorLogin  from '../pages/auth/DoctorLogin'
import UserLogin from '../pages/auth/UserLogin';
import UserRegister from '../pages/auth/UserRegister';
import Profile from '../pages/auth/Profile';
import ProfileDoctor from "../pages/auth/ProfileDoctor";
import DoctorDetails from "../pages/doctors/DoctorDetails";
import DoctorsFront from "../pages/doctors/DoctorsFront";
import CallNow from "../pages/calling/CallNow";
import AddMoneyToWallet from "../pages/wallet/AddMoneyToWallet";
import MyWallet from "../pages/wallet/MyWallet";
import MyDoctorWallet from "../pages/wallet/MyDoctorWallet";
import AcceptCall from "../pages/calling/AcceptCall";
import MyCallSession from "../pages/calling/MyCallSession";
import SessionSuccess from "../pages/calling/SessionSuccess";


import DoctorRatings from "../pages/doctors/DoctorRatings";
//import Comingsoon from "../components/Comingsoon";
import PageNotFound from '../pages/common/PageNotFound';
import MyDashboard  from '../pages/dashboard/dashboard';
import DoctorDashboard from '../pages/dashboard/DoctorDashboard';
import BecomeAnExpert from "../pages/auth/BecomeAnExpert";
import MyExpertSession from "../pages/calling/MyExpertSession";
import ExpertAvalibilty from '../pages/doctors/ExpertAvalibilty';
import HowItWork from '../pages/static-pages/HowItWork';
import Faq from '../pages/static-pages/Faq';
import ShowTestimonials from '../pages/testimonial/ShowTestimonials';
// basename='/ctmri/'
// "homepage": "https://himlalpun.net/counselling/",
const RoutePath = () => {
    return (        
        <Router basename='/' >
            <Routes>
               {/*<Route path='/' element={<Comingsoon />} />*/}
                <Route path='/' element={<Home />} />
                <Route path={Links.BECOMEANEXPERT} element={<BecomeAnExpert />} />
                <Route path={Links.REGISTRATIONSUCCESS} element={<RegisterSuccess />} />
                <Route path={Links.DOCTOR_LOGIN} element={<DoctorLogin />} />
                <Route path={Links.USER_LOGIN} element={<UserLogin />} />
                <Route path={Links.PROFILE} element={<Authentication><Profile /></Authentication>} />
                <Route path={Links.PROFILE_DOCTOR} element={<Authentication><ProfileDoctor /></Authentication>} />
                <Route path={Links.USER_SIGNUP} element={<UserRegister />} />
                <Route path={Links.EXPERTS} element={<Authentication><DoctorsFront /></Authentication>} />
                <Route path={`${Links.EXPERT}/:slug`} element={<Authentication><DoctorDetails /></Authentication>} />
                <Route path={Links.CALLNOW} element={<CallNow />} />
                <Route path={Links.ACCEPTCALL} element={<AcceptCall />} />
                <Route path={Links.ADDMONEYTOWALLET} element={<Authentication><AddMoneyToWallet /></Authentication>} />
                <Route path={Links.MYWALLET} element={<Authentication><MyWallet /></Authentication>} />
                <Route path={Links.MYDOCTORWALLET} element={<Authentication><MyDoctorWallet /></Authentication>} />
                <Route path={Links.MYCALLSESSION} element={<Authentication><MyCallSession /></Authentication>} />
                <Route path={Links.EXPERTCALLSESSION} element={<Authentication><MyExpertSession /></Authentication>} />
                <Route path={Links.DOCTORMYRATINGS} element={<Authentication><DoctorRatings /></Authentication>} />
                <Route path={Links.MYDASHBOARD} element={<Authentication><MyDashboard /></Authentication>} />
                <Route path={Links.DOCTORDASHBOARD} element={<Authentication><DoctorDashboard /></Authentication>} />
                <Route path={Links.EXPERTAVALIBILITY} element={<Authentication><ExpertAvalibilty /></Authentication>} />
                <Route path={Links.LOGOUT} element={<Logout />} />
                <Route path={Links.CONTACT} element={<Contact />} />
                <Route path={Links.ABOUT} element={<About />} />
                <Route path={Links.TERMS} element={<Terms />} />
                <Route path={Links.PRIVACY} element={<Privacy />} />
                <Route path={Links.HOWITWORK} element={<HowItWork />} />
                <Route path={Links.FAQ} element={<Faq />} />
                <Route path={Links.TESTIMONIALS} element={<ShowTestimonials />} />
                <Route path={Links.SESSIONSUCCESS} element={<Authentication><SessionSuccess /></Authentication>} />
                <Route path="*" element={<PageNotFound text='This page could not be found' />} /> 
            </Routes>
        </Router>
    );
  };
  
  export default RoutePath;
  