import RCPagination, { PaginationProps } from 'rc-pagination';
import { ArrowNextIcon } from 'components/custom-icons/ArrowNextIcon';
import { ArrowPrevIcon } from 'components/custom-icons/ArrowPrevIcon';
import 'rc-pagination/assets/index.css';

const Pagination = (props) => {
  return (
    <RCPagination
      nextIcon={<ArrowNextIcon />}
      prevIcon={<ArrowPrevIcon />}
      {...props}
    />
  );
};

export default Pagination;
